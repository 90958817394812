import React, { useState } from 'react';
import { useIntl } from "react-intl";
import { Button, List, Modal, Select, Tooltip } from 'antd';
import { DeleteOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  useCreateSharingMutation,
  useDeleteSharingMutation,
  useUpdateSharingMutation
} from '../../store/sharedSlices/globalApi';
import api from '../../api/api';
import { AutocompleteSelect } from '../AutocompleteSelect/AutocompleteSelect';

const ShareButton = ({ shares, refetch, entity, isSharingLeads }) => {
  const { formatMessage: f } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateSharing] = useUpdateSharingMutation();
  const [deleteSharing] = useDeleteSharingMutation();
  const [createSharing] = useCreateSharingMutation();

  const onListChange = (v, item) => {
    setIsLoading(true);
    updateSharing({ id: item.id, scope: v })
      .finally(_ => {
        refetch()
        setIsLoading(false)
      })
  }

  const onListDelete = (item) => {
    setIsLoading(true);
    deleteSharing(item.id)
      .finally(_ => {
        refetch()
        setIsLoading(false)
      })
  }

  const onListCreate = (item) => {
    setIsLoading(true);
    createSharing(item)
      .finally(_ => {
        refetch()
        setIsLoading(false)
      })
  }

  const Footer = () => {
    const [isAdding, setIsAdding] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedScope, setSelectedScope] = useState('FULL');

    const fetchUsers = async (name) => {
      const idFilter =
        !isNaN(name) && name?.length
          ? `&filter.id=$or:$eq:${name}`
          : '';
      const res = await api.get(
        `/user/all?filter.nickname=$ilike:${name}${idFilter}&filter.isActive=$eq:true`
      );
      return res.data.data.map((c) => ({
        label: `#${c.id} ${c.nickname}`,
        value: c.id,
      }));
    };

    const initialFetchUsers = async () => {
      const res = await api.get(`/user/all?limit=10&filter.isActive=$eq:true`);
      return res.data.data.map((c) => ({
        label: `#${c.id} ${c.nickname}`,
        value: c.id,
      }));
    };


    return isAdding ?
      <div className="flex">
        <AutocompleteSelect
          value={selectedUser}
          placeholder={f({id: 'user'})}
          initialFetch={initialFetchUsers}
          fetch={fetchUsers}
          onChange={setSelectedUser}
        />
        <Select onChange={setSelectedScope} className="ml-3 w-40" defaultValue="FULL">
          <Select.Option value="ID_ONLY">{f({ id: 'shared_scope_id_only' })}</Select.Option>
          <Select.Option value="FULL">{f({ id: 'shared_scope_full' })}</Select.Option>
          {isSharingLeads && <Select.Option value="FULL_LEADS">{f({ id: 'shared_scope_full_leads' })}</Select.Option>}
        </Select>
        <Button
          className="ml-3"
          type="primary"
          onClick={() => {
            onListCreate({ user_id: selectedUser.value, ...entity, scope: selectedScope })
            setIsAdding(false);
          }}
        >
          {f({ id: 'save' })}
        </Button>
      </div> :
      <div><Button onClick={() => setIsAdding(true)}>{f({ id: 'share' })}</Button></div>
  }

  return (
    <>
      <Tooltip title={f({ id: 'share' })}><Button shape="round" type="primary" icon={<ShareAltOutlined />} onClick={() => setIsOpen(true)} /></Tooltip>
      <Modal
        closeIcon={null}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <List
          bordered
          loading={isLoading}
          dataSource={shares}
          footer={<Footer />}
          renderItem={(item) => (
            <List.Item className="flex justify-between">
              <div>{item.user.nickname}</div>
              <div>
                <Select className="w-40" onChange={value => onListChange(value, item)} defaultValue={item.scope}>
                  <Select.Option value="ID_ONLY">{f({ id: 'shared_scope_id_only' })}</Select.Option>
                  <Select.Option value="FULL">{f({ id: 'shared_scope_full' })}</Select.Option>
                  {isSharingLeads && <Select.Option value="FULL_LEADS">{f({ id: 'shared_scope_full_leads' })}</Select.Option>}
                </Select>
                <Button className="ml-2" shape="round" icon={<DeleteOutlined />} danger onClick={() => onListDelete(item)} />
              </div>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};
export default ShareButton;
