import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Tooltip
} from 'antd';
import styles from './TeamForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { teamsApi } from '../teamsApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../../api/api';
import { PlusOutlined } from '@ant-design/icons';
import {useIntl} from "react-intl";
import {PermissionalButtonDrawer, PermissionalLink} from "../../../shared/PermissionalLink/PermissionalLink";
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {LogsInEntity} from "../../../shared/LogsInEntity/LogsInEntity";

export const TeamForm = ({ team, isDisabled, onFinish, fetchTeam }) => {
  const [selectedTeam, setSelectedTeam] = useState();
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const { formatMessage: f } = useIntl();
  const [userTeams, setUserTeams] = useState([]);
  const [form] = Form.useForm();
  const [formUser] = Form.useForm();
  const [modalForm] = Form.useForm();
  const { user } = useSelector((state) => state.globalSlice);
  const [isActive, setIsActive] = useState(team ? team.isActive : true);
  const dispatch = useDispatch();
  const [selectedPermissions, setSelectedPermissions] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});
  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const fetchPermissions = async (name) => {
    const res = await api.get(`/permissions?filter.name=$ilike:${name}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchPermissions = async () => {
    const res = await api.get(`/permissions?limit10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchUsers = async (name) => {
    const query = userTeams.length
      ? `filter.id=$and:$not:$in:${userTeams.map((e) => e.user_id)}`
      : '';
    const res = await api.get(`/user?filter.nickname=$ilike:${name}&${query}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const initialFetchUsers = async () => {
    const query = userTeams.length
      ? `filter.id=$and:$not:$in:${userTeams.map((e) => e.user_id)}`
      : '';
    const res = await api.get(`/user?limit10&${query}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;
    const { formatMessage: f } = useIntl();
    if (inputType === 'permissions') {
      const permissions = userTeams.find(
        (ut) => ut?.id === editingKey
      )?.permissions;

      inputNode = (
        <AutocompleteSelect
          style={{
            width: '130px',
          }}
          placeholder={f({id: 'permissions_menu'})}
          initialFetch={initialFetchPermissions}
          defaultValue={{ label: permissions?.name, value: permissions?.id }}
          fetch={fetchPermissions}
          onChange={(e) => {
            e
              ? formUser.setFieldValue('permissions', e.value)
              : formUser.setFieldValue('permissions', null);
          }}
          allowClear
        />
      );
    }

    if (inputType === 'user') {
      const user = userTeams.find((ut) => ut?.id === editingKey)?.user;

      inputNode = (
        <AutocompleteSelect
          style={{
            width: '130px',
          }}
          placeholder={f({id: 'user'})}
          initialFetch={initialFetchUsers}
          fetch={fetchUsers}
          defaultValue={{ label: user?.nickname, value: user?.id }}
          onChange={(e) =>
            e
              ? formUser.setFieldValue('user', e.value)
              : formUser.setFieldValue('user', null)
          }
          allowClear
        />
      );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record?.id === editingKey;
  const edit = (record) => {
    formUser.setFieldsValue({
      user: record.user.id,
      permissions: record.permissions.id,
      ...record,
    });
    setEditingKey(record.id);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    modalForm.resetFields();
  };
  const cancel = () => {
    setEditingKey('');
  };

  const deleteUserTeam = async (record) => {
    api.delete(`/user-team/${editingKey}`).then(() => {
      fetchTeam()
    });
    setEditingKey('');
  };

  const save = async (record) => {
    api
      .put(`/user-team/${editingKey}`, {
        user_id: formUser.getFieldValue('user'),
        permissions_id:
          formUser.getFieldValue('permissions') || record.permissions.id,
      })
      .then(() => {
        fetchTeam()
      });
    setEditingKey('');
  };

  useEffect(() => {
    if (!team) form.setFieldValue('isActive', true);
  }, []);

  const fetchTeams = async (name) => {
    const query = team ? `&filter.id=$and:$not:$in:${team.id}` : '';
    const res = await api.get(`/team?filter.name=$ilike:${name}${query}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchTeams = async () => {
    const query = team ? `&filter.id=$and:$not:$in:${team.id}` : '';
    const res = await api.get(`/team?limit10${query}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  useEffect(() => {
    if (team) {
      form.setFieldsValue(team);
      setIsDisabledButton(team.isDisabled)
      form.setFieldValue(
        'createdAt',
        fromApiToClient(team.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(team.updatedAt, user.timezone)
      );
      form.setFieldValue('team', {
        label: `#${team.parentTeam?.id} ${team.parentTeam?.name}`,
        value: team.parentTeam?.id,
        id: team.parentTeam?.id,
      });
      setSelectedTeam({
        label: `#${team.parentTeam?.id} ${team.parentTeam?.name}`,
        value: team.parentTeam?.id,
        id: team.parentTeam?.id,
      });
      setIsActive(team.isActive);
      api.get(`/user-team/${team.id}`).then((res) => {
        setUserTeams(res.data);
        setIsUsersLoading(false);
      });
    }
  }, [team]);

  const columns = [
    {
      title: f({id: 'nickname'}),
      dataIndex: 'user',
      inputType: 'user',
      key: 'user',
      render: (user) => (
          <PermissionalLink
              label={user && user.isActive
                  ? `#${user.id} ${user.nickname}`
                  : `#${user.id} ${user.nickname} (${f({id: 'inactive'})})`}
              onClick={() => {
                setIsOpenDrawer(true)
                setDataDrawer({id: user?.id, type: 'users'})
              }}
              permissions={PERMISSIONS.USERS_READ}
              isDrawer={true}
          />
      ),
      editable: true,
    },
    {
      title: f({id: 'permissions_menu'}),
      dataIndex: 'permissions',
      inputType: 'permissions',
      key: 'permissions',
      render: (permissions, fullObj) => {
        return (
            <PermissionalLink
                label={permissions.isActive
                    ? `#${permissions.id} ${permissions.name}`
                    : `#${permissions.id} ${permissions.name} (${f({id: 'inactive'})})`}
                onClick={() => {
                  setIsOpenDrawer(true)
                  setDataDrawer({id: permissions.id, type:  'permissions'})
                }}
                permissions={PERMISSIONS.PERMISSIONS_EDIT}
                isDrawer={true} />
        );
      },
      editable: true,
    },
    {
      title: f({id: 'action'}),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              {f({id: 'save'})}
            </Typography.Link>
            <Popconfirm title="Ви впевнені?" onConfirm={cancel}>
              <a
                style={{
                  marginRight: 8,
                }}
              >
                {f({id: 'cancel'})}
              </a>
            </Popconfirm>
            <Popconfirm title="Ви впевнені?" onConfirm={deleteUserTeam}>
              <a>{f({id: "delete_button"})}</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
          >
            {f({id: 'edit_button'})}
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onUserAdd = () => {
    api
      .post('/user-team', {
        user_id: modalForm.getFieldValue('user').value,
        permissions_id: modalForm.getFieldValue('permissions').value,
        team_id: team.id,
      })
      .then(() => {
        fetchTeam()
      });
    handleModalCancel();
  };

  return (
    <>
      {hasPermissions(user, PERMISSIONS.TEAMS_EDIT) && (
            <div className="flex justify-between">
              <DeleteButton textDisabled={f({id: 'command_cannot_be_deleted'})} disabled={isDisabledButton} entity={team} onFinish={onFinish} />
              {team && <LogsInEntity drawerId={team.id} entity='Team'/>}
            </div>
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(teamsApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            createdAt: undefined,
            updatedAt: undefined,
            parent_team_id: form.getFieldValue('team').value,
            isActive,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>

        {!team?.isRoot && (
          <Form.Item
            name="team"
            label={f({id: 'inherited_from'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutocompleteSelect
              className={styles.input}
              value={selectedTeam}
              placeholder={f({id: 'inherited_from'})}
              initialFetch={initialFetchTeams}
              fetch={fetchTeams}
              onChange={setSelectedTeam}
            />
            {selectedTeam?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.TEAMS_EDIT} isShow={!!selectedTeam?.value}
                                       onClick={() => {
                                         setIsOpenDrawer(true)
                                         setDataDrawer({id: selectedTeam?.value, type: 'teams'})
                                       }}/>}
          </Form.Item>
        )}

        {team && (
          <Form.Item name="createdAt" label={f({id: 'created'})}>
            <Input
              disabled
              placeholder={f({id: 'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {team && (
          <Form.Item name="updatedAt" label={f({id: 'updated'})}>
            <Input
              disabled
              placeholder={f({id: 'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isDisabled}
            disabled={isDisabled}
            type="primary"
            htmlType="submit"
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
      {team && (
        <>
          <Divider orientation="center">{f({id: 'users_menu'})}</Divider>
          <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
          <div className="flex justify-end mb-3">
            <Button
              disabled={!team}
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <PlusOutlined />
            </Button>
          </div>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={isUsersLoading}
            dataSource={userTeams}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </>
      )}

      <Modal
        title={f({id: 'add_user_to_team'})}
        open={isModalOpen}
        footer={null}
        onCancel={handleModalCancel}
      >
        <Form
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 7, offset: 1 }}
          form={modalForm}
          onFinish={onUserAdd}
        >
          <Form.Item
            name="permissions"
            label={f({id: 'permissions'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutocompleteSelect
              value={selectedPermissions}
              placeholder={f({id: 'permissions'})}
              initialFetch={initialFetchPermissions}
              fetch={fetchPermissions}
              onChange={setSelectedPermissions}
            />
          </Form.Item>
          <Form.Item
            name="user"
            label={f({id: 'user'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutocompleteSelect
              value={selectedUser}
              placeholder={f({id: 'user'})}
              initialFetch={initialFetchUsers}
              fetch={fetchUsers}
              onChange={setSelectedUser}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 11 }}>
            <Button
              disabled={isDisabled}
              loading={isDisabled}
              type="primary"
              htmlType="submit"
            >
              {f({id: 'save'})}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
