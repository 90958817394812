import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
const initialState = {
  filters: {
    sentAtFrom: dayjs().startOf('week').toISOString(),
    sentAtTo: dayjs().endOf('week').toISOString(),
  },
};
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    removeDateFilter: (state, action) => {
      state.filters = Object.keys(state.filters).reduce((acc, curr) => {
        if (curr.includes('From') || curr.includes('To')) {
          return acc;
        }
        acc[curr] = state.filters[curr];
        return acc;
      }, {});
    },
    resetStateDashboard: () => {
      return initialState;
    },
  },
});

export const {
  updateFilters,
  setFilters,
  removeDateFilter,
  resetStateDashboard,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
