import Icon from '@ant-design/icons';

const RegwaySvg = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" fill="#ffff">
    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
    <g>
      <path d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M500,920C268,920,80,732,80,500C80,268,268,80,500,80c232,0,420,188,420,420C920,732,732,920,500,920z" />
      <path d="M710,395c0-96.7-78.3-175-175-175H430h-70v70v210v70v210h70V570h79.8l142,208l57.8-39.5l-120.8-177C659.1,538.9,710,472.9,710,395z M430,290h105c58,0,105,47,105,105c0,58-47,105-105,105H430V290z" />
    </g>
  </svg>
);

export const RegwayIcon = (props) => <Icon component={RegwaySvg} {...props} />;
