export const handleScrollPosition = () => {
  const scrollPosition = sessionStorage.getItem('scrollPosition');
  if (scrollPosition) {
    document.getElementById('main-d').scrollTo(0, parseInt(scrollPosition));
    sessionStorage.removeItem('scrollPosition');
  }
};

export const handleScrollClick = () => {
  const position = document.getElementById('main-d').scrollTop;
  sessionStorage.setItem('scrollPosition', position);
};
