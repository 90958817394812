import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import {BlacklistsForm} from "../BlacklistsForm/BlacklistsForm";
import {usePermissionsAllowed} from "../../../hooks/usePermissionsAllowed";
import {PERMISSIONS} from "../../../utils/permissions";
import {useIntl} from "react-intl";

export const EditBlacklist = ({ drawerId }) => {
  usePermissionsAllowed(PERMISSIONS.BLACKLISTS_EDIT);
  const { message } = App.useApp();
  const [blacklist, setUser] = useState();
  const { formatMessage: f } = useIntl();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    api.get(`/blacklist/${reqId}`).then((res) => {
      setUser({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedUser) => {
    setIsDisabled(true);
    delete updatedUser.updatedAt;
    api
      .put(`/blacklist/${reqId}`, {
        ...updatedUser,
      })
      .then((_) => {
        if(!drawerId) {
          navigate(-1);
        }
        message.success(f({ id: 'ip_is_update'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  return (
    <BlacklistsForm
      isDisabled={isDisabled}
      blacklist={blacklist}
      onFinish={onFinish}
    />
  );
};
