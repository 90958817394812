import {Form, Modal, QRCode, Input, message, Divider, Collapse} from "antd";
import React, { useState } from "react";
import api from "../../../../api/api";
import {useIntl} from "react-intl";
import { InputOTP } from 'antd-input-otp';

export const QrModalOtp = ({ isModalOpen, url, handleCancel, userId, enabled, userType }) => {
    const [isDisabled, setDisabled] = useState(false);
    const { formatMessage: f } = useIntl();
    const onCheckCode = (token) => {
        setDisabled(true)
        api.put(`${userType}/update-user-otp/${userId}`, { token: token.join(''), enabled }).then(({data}) => {
            if(data.validate) {
                enabled ? message.success(f({id: 'added_2FA'})) : message.success(f({id: 'removed_2FA'}));
                handleCancel({isSuccessValidate: true})
            } else {
                message.error(f({id: 'code_is_incorrect'}));
            }
        }).catch((e) => {
            message.error(e.response.data?.message);
        }).finally(() => {
            setDisabled(false)
        })
    }
    const items = [
        {
            key: '1',
            label: `1. ${f({id: 'install_the_app'})}`,
            children: <p style={{padding: '7px'}}>{f({id: 'otp_text_1'})}</p>
        },
        {
            key: '2',
            label: `2. ${f({id: 'scan_the_QR_code'})}`,
            children: <p style={{padding: '7px'}}>{f({id: 'otp_text_2'})}</p>
        },
        {
            key: '3',
            label: `3. ${f({id: 'confirmation'})}`,
            children: <p style={{padding: '7px'}}>{f({id: 'otp_text_3'})}</p>
        },
    ];
    return (
        <Modal
            zIndex={10}
            title={enabled ? f({id: 'add_2FA'}) : f({id: 'remove_2FA'})}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
        >
            <Form
                wrapperCol={{ span: 12, offset: 1 }}
                labelCol={{ span: 7, offset: 1 }}
                style={{display: "flex", flexDirection: 'column', alignItems: 'center'}}
            >
                {enabled && (<>
                <Form.Item>
                    <QRCode
                        errorLevel="L"
                        value={url}
                    />
                </Form.Item>
                </>)}
                <InputOTP disabled={isDisabled} autoSubmit={onCheckCode} inputType="numeric" />
            </Form>
            {enabled ? <Collapse items={items} className="mt-4" bordered={false} size="large" defaultActiveKey={['1']} /> : <h3 style={{textAlign: 'center'}}>{f({id: 'enter_code_from_app_to_delete_2FA'})}</h3> }
        </Modal>
    )
}