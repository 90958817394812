import { Spin } from 'antd';
import styles from './ScreenBlock.module.css';
export const ScreenBlock = ({ isOpen }) => {
  return (
    <div hidden={!isOpen}>
      <div className={styles.modalMask}></div>
      <div className={styles.modalWrap} tabIndex={-1}>
        <Spin size="large"></Spin>
        <span>Loading...</span>
      </div>
    </div>
  );
};
