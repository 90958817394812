import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const customersApi = createApi({
  reducerPath: 'customersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `customer?${url}${sort}`;
      },
    }),
    getCustomer: builder.query({
      query: (id) => `customer/${id}`,
    }),
    getSharedByCustomer: builder.query({
      query: (id) => `/sharing/entity/${id}?entity=customer_id`,
    }),
    shareCustomerBatch: builder.mutation({
      query: (patch) => ({
        url: `/sharing/batch?entity=customer_id`,
        method: 'PUT',
        body: patch,
      }),
    }),
    updateCustomer: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `customer/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
    updateResponsible: builder.mutation({
      query: (patch) => ({
        url: `customer/responsible`,
        method: 'PUT',
        body: patch,
      }),
    }),
  }),
  tagTypes: ['customer'],
});

export const {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  useLazyGetSharedByCustomerQuery,
  useShareCustomerBatchMutation,
  useUpdateResponsibleMutation,
  useLazyGetCustomersQuery,
} = customersApi;
