import React, { Component } from 'react';
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        console.error("ErrorBoundary caught an error", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage code="500" description="Sorry, something went wrong." error={this.state.error} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
