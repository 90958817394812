import { Button, Form, Input } from 'antd';
import styles from './NamecheapForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { namecheapApi } from '../namecheapApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const NamecheapForm = ({ namecheap, isDisabled, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(namecheap ? namecheap.isActive : true);
  const dispatch = useDispatch();
    const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  useEffect(() => {
    if (!namecheap) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (namecheap) {
      form.setFieldsValue(namecheap);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(namecheap.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(namecheap.updatedAt, user.timezone)
      );
      setIsActive(namecheap.isActive);
    }
  }, [namecheap]);

  return (
    <>
      {hasPermissions(user, PERMISSIONS.DOMAINS_EDIT) && (
        <DeleteButton entity={namecheap} onFinish={onFinish} />
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(namecheapApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            name: form.getFieldValue('name')?.trim(),
            apiUser: form.getFieldValue('apiUser')?.trim(),
            apiKey: form.getFieldValue('apiKey')?.trim(),
            userName: form.getFieldValue('userName')?.trim(),
            userId: namecheap ? namecheap?.userId : user?.id,
            createdAt: undefined,
            updatedAt: undefined,
            isActive,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>

         <Form.Item
            name="email"
            label="Email"
            rules={[
                {
                      required: true,
                 },
             ]}
          >
            <Input placeholder="Email" rootClassName={styles.input} />
          </Form.Item>
          <Form.Item
              name="password"
              label={f({id: 'password'})}
          >
              <Input placeholder={f({id: 'password'})} rootClassName={styles.input} />
          </Form.Item>
        <Form.Item
          name="apiUser"
          label="apiUser"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="apiUser" rootClassName={styles.input} />
        </Form.Item>

        <Form.Item
            name="apiKey"
            label="apiKey"
            rules={[
              {
                required: true,
              },
            ]}
        >
          <Input placeholder="apiKey" rootClassName={styles.input} />
        </Form.Item>

        <Form.Item
            name="userName"
            label="userName"
            rules={[
              {
                required: true,
              },
            ]}
        >
          <Input placeholder="userName" rootClassName={styles.input} />
        </Form.Item>

        {namecheap && (
          <Form.Item name="createdAt" label={f({id: 'created'})}>
            <Input
              disabled
              placeholder={f({id: 'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {namecheap && (
          <Form.Item name="updatedAt" label={f({id: 'updated'})}>
            <Input
              disabled
              placeholder={f({id: 'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            disabled={isDisabled}
            loading={isDisabled}
            type="primary"
            htmlType="submit"
          >
              {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
