import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const ButtonSearchFilter = ({ isFetching, onClickLazyQuery }) => {
  return (
    <Button
      type="primary"
      htmlType="submit"
      className="mb-2"
      onClick={() => onClickLazyQuery()}
      shape="circle"
      loading={isFetching}
      disabled={isFetching}
      icon={<SearchOutlined />}
    />
  );
};
