import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  pageSize: Number(JSON.parse(localStorage.getItem('pageSize'))?.lead) || 10,
  filters: {},
  currentPage: 1,
};

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setPageSize: (state, action) => {
      state.pageSize = action.payload.count;
      const storageItemsCount = JSON.parse(localStorage.getItem('pageSize'));
      if (action.payload.save) {
        localStorage.setItem(
          'pageSize',
          JSON.stringify({ ...storageItemsCount, lead: action.payload.count })
        );
      }
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.currentPage = 1;
    },
    removeInitialFilters: (state, action) => {
      state.initialFilters = null;
      state.filters = null;
      state.currentPage = 1;
    },
    updateFiltersLead: (state, action) => {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
      state.currentPage = 1;
    },
    removeDateFilterLead: (state, action) => {
      state.filters = Object.keys(state.filters).reduce((acc, curr) => {
        if (curr.includes('From') || curr.includes('To')) {
          return acc;
        }
        acc[curr] = state.filters[curr];
        return acc;
      }, {});
    },
    resetStateLeads: () => {
      return initialState;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPageSize,
  setFilters,
  updateFiltersLead,
  removeInitialFilters,
  removeDateFilterLead,
  resetStateLeads,
  setCurrentPage,
} = leadsSlice.actions;

export default leadsSlice.reducer;
