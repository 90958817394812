import {Button, message, Pagination, Row} from 'antd';
import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../providers/ThemeProvider';
import { ChangeResponsibleModal } from './modals/ChangeResponsibleModal';
import { useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import { ShareEntityModal } from './modals/ShareEntityModal';
import {ChangeDomainsServerModal} from "./modals/ChangeDomainsServerModal";
import {ChangeOrderStatusModal} from "./modals/ChangeOrderStatusModal";
import {DeleteOrder} from "./button/DeleteOrder";

export const onTableChange = (pagination, setCurrentPage, dispatch) => {
  if (!isNaN(pagination)) {
    dispatch(setCurrentPage(pagination));
  }
};
export const TableFooter = ({
  paginationProps,
  selectedRows,
  setSelectedRows,
  updateResponsible,
  shareEntity,changeServer,
  dispatch, pauseAction,
  deleteAction,
  refetchAction,
  deleteOrder,
  isSharingLeads,
  changeStatusOrder,
}) => {
  const { isDarkTheme } = useContext(ThemeContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isServerModalOpen, setIsServerModalOpen] = useState(false);
  const [isChangeStatusOrderModalOpen, setIsChangeStatusOrderModalOpen] = useState(false);
  const { user } = useSelector((state) => state.globalSlice);
  const { formatMessage: f } = useIntl();
  const handleModalCancel = () => {
    setIsModalOpen(false);
    setIsServerModalOpen(false);
    setIsShareModalOpen(false);
    setIsChangeStatusOrderModalOpen(false);
  };
  const onResendUpdateSuccess = () => {
    setIsModalOpen(false);
    setIsShareModalOpen(false);
    setIsServerModalOpen(false);
    setIsChangeStatusOrderModalOpen(false)
    setSelectedRows([]);
    refetchAction();
  };
  return (
    <Row
      style={{
        padding: '16px 16px',
        borderRadius: '0 0 8px 8px',
        background: isDarkTheme ? '#272b34' : '#ffffff',
      }}
      justify="space-between"
    >
      <Row>
        {!!selectedRows.length &&  (
          <Row>
            <div>
              {user?.isAdmin && updateResponsible && (
                <Button type="primary" onClick={() => setIsModalOpen(true)}>
                  {f({ id: 'change_responsible' })}
                </Button>
              )}
            </div>
            {shareEntity && <div className="ml-2">
                <Button type="primary" onClick={() => setIsShareModalOpen(true)}>
                  {f({ id: 'share' })}
                </Button>
            </div>}
            {
              deleteOrder && <DeleteOrder  onSuccess={onResendUpdateSuccess} deleteOrder={deleteOrder} rows={selectedRows} dispath={dispatch}/>
            }
            {changeStatusOrder && <div className="ml-2">
              <Button type="primary" onClick={() => setIsChangeStatusOrderModalOpen(true)}>
                {f({ id: 'change_status' })}
              </Button>
            </div>}
            {changeServer && user.canChangeServerDns && <div className="ml-2">
              <Button type="primary" onClick={() => setIsServerModalOpen(true)}>
                {f({ id: 'changeDns' })}
              </Button>
            </div>}
            {pauseAction && <div className="ml-2">
              <Button type="primary" onClick={() => {
                pauseAction(selectedRows)
              }}>
                {`${f({id: 'pause'})}/${f({ id: 'restore' })}`}
              </Button>
            </div>}
            {deleteAction && <div className="ml-2">
              <Button type="primary" onClick={() => {
                deleteAction(selectedRows)
              }}>
                {f({id: 'queue_deleted'})}
              </Button>
            </div>}
          </Row>
        )}
      </Row>

      <ChangeResponsibleModal
        isModalOpen={isModalOpen}
        rows={selectedRows}
        handleCancel={handleModalCancel}
        onSuccess={onResendUpdateSuccess}
        updateResponsible={updateResponsible}
        dispath={dispatch}
      />
      <ChangeOrderStatusModal
          isModalOpen={isChangeStatusOrderModalOpen}
          rows={selectedRows}
          handleCancel={handleModalCancel}
          onSuccess={onResendUpdateSuccess}
          changeOrderStatusModal={changeStatusOrder}
          dispath={dispatch} />
      <ChangeDomainsServerModal
          isModalOpen={isServerModalOpen}
          rows={selectedRows}
          handleCancel={handleModalCancel}
          onSuccess={onResendUpdateSuccess}
          changeServer={changeServer}
          dispath={dispatch}/>
      <ShareEntityModal
        isModalOpen={isShareModalOpen}
        rows={selectedRows}
        handleCancel={handleModalCancel}
        onSuccess={onResendUpdateSuccess}
        shareEntity={shareEntity}
        dispath={dispatch}
        isSharingLeads={isSharingLeads}
      />
      <Pagination {...paginationProps} />
    </Row>
  );
};
