import React from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import { useIntl } from "react-intl";

export const CountryBoxInput = ({ defaultValue, onChange, disabled }) => {
  const { formatMessage: f } = useIntl();
  const fetchCountryBox = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/country-box?filter.isActive=$eq:true&filter.name=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c?.id} ${c?.name}`,
      value: c?.id,
    }));
  };

  const initialFetchCountryBox = async () => {
    const res = await api.get(`/country-box?filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c?.id} ${c?.name}`,
      value: c?.id,
    }));
  };

  return (
    <AutocompleteSelect
      style={{
        width: '130px',
      }}
      placeholder={f({ id: 'country_box_menu' })}
      initialFetch={initialFetchCountryBox}
      fetch={fetchCountryBox}
      onChange={(e) => onChange(e ? { id: e.value } : null)}
      defaultValue={{
        value: defaultValue?.id,
        label: `#${defaultValue?.id} ${defaultValue?.name}`,
      }}
      disabled={disabled}
      allowClear
    />
  );
};
