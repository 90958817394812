import React, {useState} from 'react';
import { Input } from 'antd';
import isIP from "validator/es/lib/isIP";


export const IpInput = ({ defaultValue, onChange }) => {
    const [isError, setIsError] = useState(false);
    const validateIp = (value) => {
        if(isIP(value)) {
            onChange(value.trim())
            setIsError(false)
        } else {
            setIsError(true)
        }
    }
    return (
        <Input  placeholder="IP" defaultValue={defaultValue}
               onChange={(e) =>  validateIp(e.target.value) } status={isError ? "error" : ""} />
    );
};
