import { Button, Popconfirm } from 'antd';
import React, { useContext } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styles from './OneColumn.module.css';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../providers/ThemeProvider';
import {useIntl} from "react-intl";

export const OneColumn = ({
  columnId,
  column,
  onDelete = null,
  child = false,
}) => {
  const { formatMessage: f } = useIntl();
  const { isDarkTheme } = useContext(ThemeContext);
  const navigate = useNavigate();

  return (
    column && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        key={columnId}
        className={styles.oneColumn}
      >
          <div className={styles.columnTitle}>
              <h2 style={{color: isDarkTheme ? 'white' : 'black', marginRight: '6px'}}>
                  #{columnId}
              </h2>
              {(column.isDefault || child) && (
                  <h3 style={{color: isDarkTheme ? 'white' : 'black'}}>
                      {column.name}
                  </h3>
              )}
              {!column.isDefault && !child && (
                  <Link to={`/call-status-categories/children/${columnId}`}>
                      <h2 style={{color: isDarkTheme ? 'white' : 'black'}}>
                          {column.name}{' '}
                          {column.isChildrenCategoriesExist && <PlusCircleOutlined/>}
                      </h2>
                  </Link>
              )}
              {!column.isDefault && (
                  <Button
                      className={styles.editBtn}
                      type="text"
                      onClick={() => navigate(`/call-status-categories/${columnId}`)}
                  >
                      <EditOutlined/>
                  </Button>
              )}
              {!column.isDefault && onDelete && (
                  <div className="flex justify-between">
                      <div
                          className="flex items-center"
                          style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                          }}
                      >
                          <Popconfirm
                              title={`${f({id: 'delete_category'})} ?`}
                              description={f({id: 'sure_delete_category'})}
                              onConfirm={() => onDelete(columnId)}
                              okText={f({id: 'yes'})}
                              cancelText={f({id: 'no'})}
                          >
                              <Button
                                  className={styles.delBtn}
                                  type="text"
                                  onClick={(e) => {
                                      e.stopPropagation();
                                  }}
                              >
                                  <DeleteOutlined/>
                              </Button>
                          </Popconfirm>
                      </div>
                  </div>
              )}
          </div>
          <div
              className={styles.oneColumn}
              style={{
                  margin: 8,
                  display: 'table',
          }}
        >
          <Droppable droppableId={columnId} key={columnId}>
            {(provided, snapshot) => {
              return (
                <div
                  className={styles.oneColumn}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: snapshot.isDraggingOver
                      ? 'lightblue'
                      : column.color,
                    padding: 4,
                    width: 200,
                    minHeight: 500,
                    border: column.isDefault ? 'dashed #b9b9b9' : '0',
                    borderRadius: 20,
                    overflowX: column.isDefault ? 'auto' : '',
                    overflowY: column.isDefault ? 'scroll' : '',
                    maxHeight: column.isDefault ? '100vh' : '',
                  }}
                >
                  {column.items.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                userSelect: 'none',
                                padding: 6,
                                margin: '8px 10px',
                                background: snapshot.isDragging
                                  ? 'rgba(26, 26, 26, 0.71)'
                                  : 'rgba(26, 26, 26, 0.51)',
                                color: 'white',
                                ...provided.draggableProps.style,
                                opacity: 1,
                                borderRadius: 18,
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: 13,
                              }}
                            >
                             {item.callStatusName}
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </div>
      </div>
    )
  );
};
