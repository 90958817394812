import React from 'react';
import { Collapse } from 'antd';
import { PermissionalLink } from '../PermissionalLink/PermissionalLink';

const { Panel } = Collapse;

export const ExtendableTextTableCell = ({ data }) => {
  if (data) {
    if (data.length <= 30 || Number(data)) {
      return <>{data}</>;
    }
    let sliced = data.slice(0, 15);
    if (sliced.length < data.length) {
      sliced += '...';
    }
    return (
      <Collapse size="small" ghost>
        <Panel header={`${sliced}`} key="1">
          <div style={{ maxWidth: '250px' }}>{data}</div>
        </Panel>
      </Collapse>
    );
  }
  return '';
};

export const ExtendableTableCell = ({ data }) => {
  if (data.length <= 2) {
    return data.join(', ');
  }

  return (
    <Collapse size="small" ghost>
      <Panel header={`${data[0]}, ${data[1]}`} key="1">
        <p>{data.join(', ')}</p>
      </Panel>
    </Collapse>
  );
};

export const ExtendableLinkTableCell = ({ onClick, data, count = 2, isDrawer }) => {
  if (data.length <= count) {
    return data.map((d, index) => (
      <PermissionalLink
        onClick={() => onClick(d.id)}
        key={index}
        label={`${d.label}${index !== data.length - 1 ? ', ' : ''}`}
        url={d.url}
        permissions={d.permissions}
        isDrawer={isDrawer}
      />
    ));
  }

  return (
    <Collapse size="small" ghost>
      <Panel header={`${data[0].label}, ${data[1].label}`} key="1">
        <p>
          {data.map((d, index) => (
            <PermissionalLink
              onClick={() => onClick(d.id)}
              label={`${d.label}${index !== data.length - 1 ? ', ' : ''}`}
              url={d.url}
              permissions={d.permissions}
              isDrawer={isDrawer}
            />
          ))}
        </p>
      </Panel>
    </Collapse>
  );
};

export const ExtendableTableCellText = ({ data, count = 2 }) => {
  let info = `${JSON.parse(data).email}`;
  return (
    <Collapse size="small" ghost>
      <Panel header={`info lead: ${info}`} key="1">
        <p>{data}</p>
      </Panel>
    </Collapse>
  );
};
