import { Select } from 'antd';
import CountryList from 'country-list-with-dial-code-and-flag';
import {FormattedMessage} from "react-intl";
import {LOCALES} from "../../i18n/locales";

const { Option } = Select;

export const LangMainSelect = ({ values, onChange, mode, isLangMode, ...props }) => {
    const arrLang = [];
    for (const lang in LOCALES) {
        arrLang.push([CountryList.findByCountryCode(LOCALES[lang].country), LOCALES[lang].language])
    }

    return (
        <Select
            placeholder={<FormattedMessage id="country" />}
            onChange={onChange}
            optionLabelProp="label"
            defaultValue={localStorage.getItem('locale') || 'UA'}
            {...props}
        >
            {arrLang.map((c, index) => {
               return (
                <Option key={index} value={c[0][0]?.code} label={c[1]}>
                    <div>
                        <span role="img" aria-label={c[0][0]?.name}>
                          {c[0][0]?.flag}&nbsp;
                        </span>
                        {c[1]}
                    </div>
                </Option>
            )
            })}
        </Select>
    );
};
