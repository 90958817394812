import {Button, message, Popconfirm, Tooltip} from 'antd';
import { DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons';
import React, {useState} from 'react';
import { useIntl } from "react-intl";

export const DeleteOrder = ({ deleteOrder,rows, onSuccess }) => {
    const { formatMessage: f } = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const onAction = (stan) => {
        setIsLoading(true);
        deleteOrder(
            {isActive: stan,ids:rows.map((l) => l.id)}
        )
            .then(() => {
                message.success(f({ id: 'deleted' }));
                onSuccess()
            })
            .catch((e) => message.error(e.response.data?.message))
            .finally(() => setIsLoading(false));
    };
    return (
        <>

                <div className='ml-2'>
                    <Popconfirm
                        okText="ОК"
                        cancelText={f({id: 'cancel'})}
                        title={`${f({id: 'delete_button'})} ?`}
                        onConfirm={() => onAction(false)}
                    >
                        <Tooltip placement="bottom" title={ f({id: 'delete_button'})}>
                            <Button style={{minWidth: '140px'}} type="primary" danger>
                                {f({id: 'delete_button'})}
                                <DeleteOutlined className="text-base"/>
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </div>

        </>
    );
};

