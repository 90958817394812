import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { CallStatusCategoryForm } from '../CallStatusCategoryForm/CallStatusCategoryForm';
import { useEffect, useState } from 'react';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const EditCallStatusCategory = () => {
  const { formatMessage: f } = useIntl();
  usePermissionsAllowed(PERMISSIONS.CALL_STATUSES_EDIT);
  const [callStatusCategory, setCallStatusCategory] = useState();
  const { parentId } = useParams();
  const { message } = App.useApp();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useDidUpdateEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    setIsLoading(true);
    api.get(`/call-status-category/${id}`).then((res) => {
      setCallStatusCategory(res.data);
      setIsLoading(false);
    });
  };

  const onFinish = (callStatusCategory) => {
    api
      .put(`/call-status-category/${id}`, {
        ...callStatusCategory,
      })
      .then((res) => {
        message.success(f({ id: 'category_call_status_updated' }));
        callStatusCategory.parentCategoryId
          ? navigate(
              `/call-status-categories/children/${callStatusCategory.parentCategoryId}`
            )
          : navigate(`/call-status-categories`);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return (
    <CallStatusCategoryForm
      onFinish={onFinish}
      callStatusCategory={callStatusCategory}
      onRefresh={getData}
      parentId={parentId}
    />
  );
};
