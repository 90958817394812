import {
  hasPermissions,
} from '../../hooks/usePermissionsAllowed';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {Button, Tooltip} from "antd";
import {LinkOutlined, RightCircleOutlined} from "@ant-design/icons";
import React from "react";
import {useIntl} from "react-intl";

export const PermissionalLink = ({ url, permissions, label, onClick, isDrawer }) => {
  const { user } = useSelector((state) => state.globalSlice);
  if (permissions && hasPermissions(user, permissions)) {
    if(isDrawer) {
      return (
          <div className="linkIsDrawer" onClick={() => onClick()}>
            {label}
          </div>
      );
    }
    return (
        <Link onClick={onClick} to={url}>
          {label}
        </Link>
    );
  }
  return label;
};

export const PermissionalButtonDrawer = ({ permissions, onClick, isShow }) => {
  const { user } = useSelector((state) => state.globalSlice);
    const { formatMessage: f } = useIntl();
  if (permissions && hasPermissions(user, permissions) && isShow) {
      return (
          <Tooltip title={f({id: 'open'})} className="ml-1">
            <Button type="primary" onClick={() => onClick()} icon={<LinkOutlined />} />
          </Tooltip>
      );
  }
  return ''
};
