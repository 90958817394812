import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../../api/prepareHeaders';

export const sessionsApi = createApi({
  reducerPath: 'sessionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getSession: builder.query({
      query: () => {
        return `session`;
      },
    }),
    getAllSessions: builder.query({
      query: () => {
        return `session/all`;
      },
    }),
    deleteSession: builder.mutation({
      query: () => ({
        url: `session`,
        method: 'DELETE',
      }),
    }),
    deleteSpecificSession: builder.mutation({
      query: (id) => ({
        url: `session/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  tagTypes: ['session'],
});

export const {
  useGetSessionQuery,
  useDeleteSessionMutation,
  useGetAllSessionsQuery,
  useDeleteSpecificSessionMutation,
} = sessionsApi;
