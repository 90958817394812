import React from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import { useSelector } from 'react-redux';
import { useIntl } from "react-intl";

export const IntegrationInput = ({ defaultValue, onChange }) => {
  const customer = useSelector((state) => state.currentEditItem.item.customer);
  const { formatMessage: f } = useIntl();


  const fetchIntegrations = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/integration/label?filter.name=$ilike:${name}&filter.customer.id=$eq:${customer?.id}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchIntegrations = async () => {
    const url = customer
      ? `/integration/label?limit=10&filter.customer.id=$eq:${customer?.id}&filter.isActive=$eq:true`
      : `/integration/label?limit=10`;
    const res = await api.get(url);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  return (
    <AutocompleteSelect
      style={{
        width: '130px',
      }}
      placeholder={f({ id: 'integration' })}
      initialFetch={initialFetchIntegrations}
      fetch={fetchIntegrations}
      onChange={(e) => onChange({ id: e.value })}
      defaultValue={{
        value: defaultValue?.id,
        label: `#${defaultValue?.id} ${defaultValue?.name}`,
      }}
    />
  );
};
