import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

export const DateInput = ({ defaultValue, onChange }) => {
  const { user } = useSelector((state) => state.globalSlice);

  return (
    <DatePicker
      style={{ resize: 'none', width: '100px' }}
      defaultValue={
        defaultValue ? dayjs(defaultValue)?.tz(user.timezone) : null
      }
      onChange={(e) => onChange(e?.toISOString())}
    />
  );
};
