export const sendLeadPattern = () => {
  return `
  /*
  The function receives a lead with the following fields
  {
    "phone": "string",
    "name": "string",
    "lastName": "string",
    "ip": "string",
    "country": "string",
    "boxId": number,
    "userId": number,
    "email": "string",
    "userAgent": "string",
    "sub1": "string",
    "sub2": "string",
    "sub3": "string",
    "sub4": "string",
    "sub5": "string",
    "sub6": "string",
    "sub7": "string",
    "sub8": "string"
  }
  You need to describe the sendLead function, which takes this object, sends the lead to the customer, and returns a Promise with the following object:
  {
    "externalId": "string",
    "autologinLink": "string"
  }
  
  To have the request and response details inside lead, store them in originResp and originReq fields.
  
  In case of an error or absence of externalId or autologinLink, you need to return an object with the field 'error' and the error description:
  return { error: "string" }
  
  If a duplicate needs to be handled, you should return an object with the field 'isDuplicate' and the error description:
  return { isDuplicate: true }
  
  If you need to send a request through brand proxy - send the initial request to '{brand_domain}/send-lead'
  and put the target details in the following object of the request body:
  const bodyToSend = {
    ...leadData  
    optionUsCrm: {
      domain: '{target_domain_url}',
      method: 'POST',
      sendType: 'application/json',
    }
  }  
  fetch('{brand_domain}/send-lead}', {
    body: JSON.stringify(bodyToSend)
  })
 
  */
  
async (lead) => {
  ///
  return {
    externalId: "string",
    autologinLink: "string",
    originResp: "string",
    originReq: "string"
  }
}
`;
};

export const checkStatusPattern = () => {
  return `
  /*
  The function takes an array of strings called externalIds,
  which are unique identifiers in the customer's system, by which we can check the lead status
  
  You need to describe the checkStatus function, 
  which takes an array of externalIds and returns a Promise with an array of objects of the following type:
  {
    externalId: "string",
    callStatus: "string",
    isDeposit: boolean,
  }
  
  If you need to send a request through brand proxy - send the initial request to '{brand_domain}/send-lead'
  and put the target details in the following object of the request body:
  const bodyToSend = {
    ...leadData  
    optionUsCrm: {
      domain: '{target_domain_url}',
      method: 'POST',
      sendType: 'application/json',
    }
  }  
  fetch('{brand_domain}/send-lead}', {
    body: JSON.stringify(bodyToSend)
  })
  */
  
async (externalIds) => {
  ///
  return [
    {
       externalId: "string",
       callStatus: "string",
       isDeposit: boolean,
     }
  ]
}
`;
};
