import React, { useContext, useEffect, useState } from 'react';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { fromApiToClient } from '../../../utils/dateFilters';
import { AppTable } from '../../../shared/Table/AppTable';
import { setCurrentPage } from '../../../store/sharedSlices/defaultPageSlice';
import { setPageSize } from '../templateIntegrationsSlice';
import { TemplateIntegrationsFilters } from './TemplateIntegrationsFilters';
import { useDispatch, useSelector } from 'react-redux';
import {
  templateIntegrationsApi,
} from '../templateIntegrationsApi';
import {
  handleScrollPosition,
} from '../../../utils/scrollPositionMethods';
import { PERMISSIONS } from '../../../utils/permissions';
import {
  onTableChange,
  TableFooter,
} from '../../../shared/TableFooter/TableFooter';
import { LoadMore } from '../../../shared/TableFooter/LoadMore';
import {useIntl} from "react-intl";
import {PermissionalLink} from "../../../shared/PermissionalLink/PermissionalLink";
import {useLazyGetTemplateIntegrationsQuery} from "../templateIntegrationsApi";

export const TemplateIntegrations = () => {
  usePermissionsAllowed(PERMISSIONS.INTEGRATIONS_READ);
  const { user } = useSelector((state) => state.globalSlice);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [dataTable, setDataTable] = useState({ data: [] });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});
  const [isFetching, setIsFetching] = useState(true);
  const { filters, currentPage, sorter, isInitialLoad } = useSelector(
    (state) => state.defaultPage
  );
  const { formatMessage: f } = useIntl();
  const { pageSize } = useSelector((state) => state.integrations);
  const [trigger] = useLazyGetTemplateIntegrationsQuery();

  const onClickLazyQuery = () => {
    setIsFetching(true);
    trigger({ filters, currentPage, pageSize, sorter }).then((e) => {
      setDataTable(e?.data || []);
      setIsFetching(false);
    });
  };
  useEffect(() => {
    if (dataTable?.data?.length) handleScrollPosition();
  }, [dataTable]);
  useEffect(() => {
    if (isInitialLoad) return;
    onClickLazyQuery();
  }, [currentPage, pageSize, sorter, isInitialLoad]);
  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: f({id:'title'}),
          dataIndex: 'name',
          key: 'name',
          render: (name, obj) => (
              <PermissionalLink
                  label={obj && `#${obj.id} ${name}`}
                  onClick={() => {
                    setIsOpenDrawer(true)
                    setDataDrawer({id: obj.id, type:  'template-integrations'})
                  }}
                  permissions={PERMISSIONS.INTEGRATIONS_EDIT}
                  isDrawer={true} />
          ),
        },
        {
          title: f({id: 'state'}),
          dataIndex: 'isActive',
          key: 'isActive',
          render: (isActive) => (isActive ? f({id: 'active'}) : f({id: 'inactive'}) ),
        },
        {
          title: f({id: 'created'}),
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, user.timezone),
        },
        {
          title: f({id: 'updated'}),
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (date) => fromApiToClient(date, user.timezone),
        },
      ]);
    }
  }, [user]);
  const rowSelection = {
    selectedRowKeys: selectedRows.map((l) => l.id),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const onShowSizeChange = (current, newPageSize) => {
    dispatch(setPageSize(newPageSize));
  };
  return (
    <div>
      <AppTable
        filtersComponent={
          <TemplateIntegrationsFilters
            isFetching={isFetching}
            onClickLazyQuery={onClickLazyQuery}
          />
        }
        columns={columns}
        loading={isFetching}
        onChange={(e) => onTableChange(e, setCurrentPage, dispatch)}
        onColumnsChange={setColumns}
        refetchAction={() => {
          templateIntegrationsApi.util.resetApiState();
          onClickLazyQuery();
        }}
        data={dataTable?.data}
        dataPrefix="template-integrations"
        showAddButton
        createUrl={'/template-integrations/create'}
        pagination={false}
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        dataDrawer={dataDrawer}
        footer={() => {
          return (
            <div>
              <TableFooter
                paginationProps={{
                  total: dataTable?.meta?.totalItems,
                  pageSizeOptions: [10, 50, 100],
                  current: currentPage,
                  pageSize,
                  onChange: (e) => onTableChange(e, setCurrentPage, dispatch),
                  onShowSizeChange,
                  showSizeChanger: true,
                  showTotal: (total) =>
                  {
                    if(selectedRows.length) {
                      return f({ id: 'total_record_in_selected'}, { total: total, selected: selectedRows.length })
                    } else {
                      return f({ id: 'total_record'}, { total: total })
                    }
                  }
                }}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                dispatch={dispatch}
                refetchAction={() => {
                  onClickLazyQuery();
                }}
              />
              <LoadMore
                dispatch={dispatch}
                pageSize={pageSize}
                isFetching={isFetching}
                setPageSize={setPageSize}
              />
            </div>
          );
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </div>
  );
};
