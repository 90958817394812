import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';

export const kpiApi = createApi({
  reducerPath: 'kpiApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getKpis: builder.query({
      query: () => `kpi`,
    }),
    getCategories: builder.query({
      query: () => `call-status-category/all`,
    }),
    getKpi: builder.query({
      query: (id) => `kpi/${id}`,
    }),
  }),
  tagTypes: ['kpi'],
});

export const { useLazyGetKpisQuery, useLazyGetCategoriesQuery } = kpiApi;
