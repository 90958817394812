import React from 'react';
import { useSelector } from 'react-redux';
import { CountryBoxInput } from '../../../../shared/EditableCell/inputFields/CountryBoxInput';

export const CountryBoxOrderInput = ({ defaultValue, onChange }) => {
  const countries = useSelector(
    (state) => state.currentEditItem.item.countries
  );

  return (
    <CountryBoxInput
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={JSON.parse(countries)?.length}
    />
  );
};
