import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { permissionsPagesBuyer } from '../utils/permissionsBuyer';

export const usePermissionsAllowed = (allowedRole) => {
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const { user } = useSelector((state) => state.globalSlice);

  useEffect(() => {
    if (
      user &&
      ((user?.type !== 'buyer' && !hasPermissions(user, allowedRole)) ||
        (user?.type === 'buyer' && !allowedRoutesForBuyer.includes(pathname)))
    ) {
      navigate('/no-permissions');
    }
  }, []);

  return user?.isAdmin;
};

export const hasPermissions = (user, allowedRole) => {
  if (!allowedRole?.length || typeof allowedRole === 'object') return false;
  const entity = allowedRole.split(':')[0];
  const action = allowedRole.split(':')[1];
  const permissionsPagesOfBuyer = permissionsPagesBuyer();
  return (
    user?.isAdmin ||
    user?.permissions?.entities?.[entity]?.[action] ||
    (user?.type === 'buyer' && permissionsPagesOfBuyer.includes(entity))
  );
};

const allowedRoutesForBuyer = ['/dashboard', '/leads', '/report'];
