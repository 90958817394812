import React from 'react';
import { CountrySelect } from '../../CountryMultiselect/CountrySelect';

export const CountriesInput = ({ defaultValue, onChange, disabled }) => {
  return (
    <CountrySelect
      style={{
        width: '130px',
      }}
      mode="multiple"
      onChange={(e) => onChange(JSON.stringify(e))}
      defaultValue={JSON.parse(defaultValue)}
      disabled={disabled}
    />
  );
};
