import { App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import api from '../../../api/api';
import { useState } from 'react';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import {TemplateIntegrationsDetails} from "../TemplateIntegrationDetails/TemplateIntegrationsDetails";

export const CreateTemplateIntegration = () => {
  const { formatMessage: f } = useIntl();
  const { message } = App.useApp();
  const navigate = useNavigate();
  usePermissionsAllowed(PERMISSIONS.INTEGRATIONS_EDIT);
  const [isDisabled, setIsDisabled] = useState(false);
  const onFinish = (integration) => {
    setIsDisabled(true);
    api
      .post('/template-integrations', {
        ...integration,
      })
      .then((res) => {
        message.success(f({id: 'integration_added'}));
        navigate(`/template-integrations`);
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  return <TemplateIntegrationsDetails isDisabled={isDisabled} onFinish={onFinish} />;
};
