import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const boxesApi = createApi({
  reducerPath: 'boxesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getBoxes: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `box?${url}${sort}`;
      },
    }),
    getBox: builder.query({
      query: (id) => `box/${id}`,
    }),
    updateBox: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `box/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
    getBoxOrder: builder.query({
      query: (id) => `/box-order/${id}`,
    }),
    updateOrderBox: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/box-order/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
    createOrderBox: builder.mutation({
      query: (post) => ({
        url: `/box-order`,
        method: 'POST',
        body: post,
      }),
    }),
    deleteOrderBox: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/box-order/${id}`,
        method: 'DELETE',
      }),
    }),
    updateResponsible: builder.mutation({
      query: (patch) => ({
        url: `/box/responsible`,
        method: 'PUT',
        body: patch,
      }),
    }),
  }),
  tagTypes: ['boxes'],
});

export const {
  useGetBoxesQuery,
  useGetBoxQuery,
  useUpdateBoxMutation,
  useLazyGetBoxOrderQuery,
  useUpdateOrderBoxMutation,
  useCreateOrderBoxMutation,
  useDeleteOrderBoxMutation,
  useGetOrderBoxQuery,
  useLazyGetBoxesQuery,
  useUpdateResponsibleMutation,
} = boxesApi;
