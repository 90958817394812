import { Button, Form, message, Modal } from 'antd';
import React, { useState } from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import {FormattedMessage, useIntl} from "react-intl";

export const ChangeResponsibleModal = ({
  rows,
  isModalOpen,
  handleCancel,
  onSuccess,
  updateResponsible,
  dispatch,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState();
  const { formatMessage: f } = useIntl();
  const onStatusUpdate = () => {
    setIsLoading(true);
    dispatch(
      updateResponsible(
        rows.map((l) => ({
          id: l.id,
          userId: selectedUsers.value,
        }))
      )
        .then(() => {
          message.success(f({ id: 'responsible_changed' }));
          onSuccess();
        })
        .catch((e) => message.error(e.response.data?.message))
        .finally(() => setIsLoading(false))
    );
  };
  const fetchUsers = async (nickname) => {
    const idFilter =
      !isNaN(nickname) && nickname?.length
        ? `&filter.id=$or:$eq:${nickname}`
        : '';
    const res = await api.get(
      `/user?filter.nickname=$ilike:${nickname}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname} ${c.isActive ? '' : `(${f({ id: 'inactive' })})`}`,
      value: c.id,
      active: c.isActive,
    }));
  };

  const initialFetchUsers = async () => {
    const res = await api.get(`/user?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname} ${c.isActive ? '' : `(${f({ id: 'inactive' })})`}`,
      value: c.id,
      active: c.isActive,
    }));
  };
  return (
    <Modal
      title={f({ id: 'change_responsible' })}
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        form={form}
        onFinish={onStatusUpdate}
      >
        <Form.Item
          name="user"
          label={f({ id: 'user' })}
          style={{ marginTop: 15 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AutocompleteSelect
            value={selectedUsers}
            placeholder={f({ id: 'user' })}
            initialFetch={initialFetchUsers}
            fetch={fetchUsers}
            onChange={setSelectedUsers}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isLoading}
            disabled={isLoading}
            type="primary"
            htmlType="submit"
          >
            {f({ id: 'change' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
