import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { GroupForm } from '../GroupForm/GroupForm';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const EditGroup = ({drawerId}) => {
  const { message } = App.useApp();
  const [group, setGroup] = useState();
  const { id } = useParams();
  usePermissionsAllowed(PERMISSIONS.OFFERS_EDIT);
  const { formatMessage: f } = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    api.get(`/group-offer/${reqId}`).then((res) => {
      setGroup({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updateGroup) => {
    setIsDisabled(true);
    delete updateGroup.updatedAt;
    api
      .put(`/group-offer/${reqId}`, {
        ...updateGroup,
      })
      .then((_) => {
        if(!drawerId) {
          navigate(-1);
        }
        message.success(f({id: 'group_updated'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  return (
    <GroupForm isDisabled={isDisabled} group={group} onFinish={onFinish} />
  );
};
