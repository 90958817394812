import {Tag, Tooltip} from 'antd';
import { translateStatus } from '../../utils/translateStatus';
import React from 'react';
import {FormattedMessage} from "react-intl";

export const StatusTag = ({ status, style, f, isTrafficType }) => {
  const statuses = {
    error: 'red',
    resend_error: 'red',
    error_duplicate: 'red',
    error_timeout: 'red',
    rejected: 'orange',
    notDistributed: 'geekblue',
    pending: 'yellow',
    approved: 'green',
    unsigned: 'purple',
    hold: '#08979c',
    test: 'pink',
    live: 'green',
    inject: 'yellow',
    inject_file: 'orange',
    inject_form: 'gold',
    inject_box: 'gold',
    resend: 'geekblue',
    resend_error: 'red',
  };
  return (
      <Tooltip title={<FormattedMessage id={`${status}_tooltip`} />}>
        <Tag style={style} color={statuses[status]}>
          {translateStatus(status, f)}
        </Tag>
      </Tooltip>
  )
};
