import React from 'react';
import { Select } from 'antd';

export const PaymentFormInput = ({ defaultValue, onChange }) => {
  return (
    <Select
      options={[
        { value: 'PREPAY', label: 'PREPAY' },
        { value: 'POSTPAY', label: 'POSTPAY' },
      ]}
      onChange={(e) => onChange(e)}
      defaultValue={{ value: defaultValue, label: defaultValue }}
    />
  );
};
