import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const teamsApi = createApi({
  reducerPath: 'teamsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getTeams: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `team?${url}${sort}`;
      },
    }),
    getTeam: builder.query({
      query: (id) => `teams/${id}`,
    }),
  }),
  tagTypes: ['teams'],
});

export const { useGetTeamsQuery, useGetTeamQuery, useLazyGetTeamsQuery } =
  teamsApi;
