import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { ServersForm } from '../ServersForm/ServersForm';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const EditServers = ({drawerId}) => {
  const { message } = App.useApp();
  const [server, setServer] = useState();
  const { id } = useParams();
  usePermissionsAllowed(PERMISSIONS.DOMAINS_EDIT);
  const { formatMessage: f } = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    api.get(`/domain-purchase/server/${reqId}`).then((res) => {
      setServer({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = async (updatedServer) => {
    setIsDisabled(true);
    delete updatedServer.updatedAt;
    delete updatedServer.createdAt;
    let error = false;
    if (updatedServer?.keitaroKey) {
      let res;
      try {
        res = await api.post('/domain-purchase/server/keitaro', {
          data: {
            ip: updatedServer?.ip,
            key: updatedServer?.keitaroKey,
          },
        });
        const d = await res;
        if (d?.data?.error) {
          error = true;
          message.error(d?.data?.error);
        }
      } catch (e) {
        error = true;
        message.error(await e?.response?.data.message);
      }
    } else if (updatedServer?.login && updatedServer?.password) {
      let res;
      try {
        res = await api.post('/domain-purchase/server/fastpanel', {
          ip: updatedServer?.ip,
          login: updatedServer?.login,
          password: updatedServer?.password,
        });
        const d = await res;
        if (!d.data) {
          error = true;
          message.error(f({id: 'error_login'}));
        }
      } catch (e) {
        error = true;
        console.log(e);
        message.error(e?.response?.data.message);
      }
    }
    if (!error) {
      api
        .put(`/domain-purchase/server/${reqId}`, {
          ...updatedServer,
        })
        .then((_) => {
          if(!drawerId) {
            navigate(-1);
          }
          message.success(f({id: 'server_updated'}));
        })
        .catch((e) => {
          message.error(e.response.data?.message);
        })
        .finally(() => {
          setIsDisabled(false);
        });
    } else {
      setIsDisabled(false);
    }
  };

  if (isLoading) return 'loading';

  return (
    <ServersForm isDisabled={isDisabled} onFinish={onFinish} server={server} />
  );
};
