import Icon from '@ant-design/icons';

const NightSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
  >
    <g id="surface1">
      <path d="M 11.71875 2.652344 C 11.664062 2.476562 11.511719 2.347656 11.324219 2.320312 L 10.03125 2.136719 L 9.453125 0.988281 C 9.371094 0.824219 9.203125 0.722656 9.019531 0.722656 C 8.839844 0.722656 8.667969 0.824219 8.585938 0.988281 L 8.011719 2.136719 L 6.71875 2.320312 C 6.535156 2.347656 6.382812 2.476562 6.324219 2.652344 C 6.265625 2.828125 6.316406 3.019531 6.449219 3.148438 L 7.378906 4.039062 L 7.160156 5.289062 C 7.128906 5.46875 7.203125 5.65625 7.351562 5.761719 C 7.496094 5.875 7.695312 5.886719 7.859375 5.804688 L 9.019531 5.207031 L 10.179688 5.804688 C 10.25 5.839844 10.324219 5.855469 10.402344 5.855469 C 10.503906 5.855469 10.601562 5.824219 10.6875 5.761719 C 10.835938 5.65625 10.910156 5.46875 10.878906 5.289062 L 10.65625 4.039062 L 11.585938 3.148438 C 11.726562 3.019531 11.773438 2.828125 11.71875 2.652344 Z M 9.804688 3.515625 C 9.6875 3.628906 9.636719 3.789062 9.664062 3.949219 L 9.757812 4.496094 L 9.242188 4.230469 C 9.105469 4.160156 8.9375 4.160156 8.800781 4.230469 L 8.285156 4.496094 L 8.382812 3.949219 C 8.410156 3.789062 8.355469 3.628906 8.238281 3.515625 L 7.84375 3.136719 L 8.398438 3.054688 C 8.558594 3.03125 8.691406 2.9375 8.761719 2.792969 L 9.019531 2.28125 L 9.277344 2.792969 C 9.347656 2.933594 9.484375 3.03125 9.640625 3.054688 L 10.199219 3.136719 Z M 9.804688 3.515625 " />
      <path d="M 10.359375 8.460938 C 10.011719 8.539062 9.648438 8.582031 9.289062 8.582031 C 6.621094 8.582031 4.449219 6.410156 4.449219 3.746094 C 4.449219 2.765625 4.742188 1.824219 5.292969 1.019531 C 5.40625 0.855469 5.40625 0.636719 5.292969 0.472656 C 5.179688 0.304688 4.976562 0.226562 4.78125 0.269531 C 3.515625 0.554688 2.367188 1.269531 1.546875 2.285156 C 0.71875 3.3125 0.257812 4.609375 0.257812 5.933594 C 0.257812 7.484375 0.863281 8.945312 1.960938 10.039062 C 3.054688 11.136719 4.515625 11.742188 6.066406 11.742188 C 7.988281 11.742188 9.78125 10.796875 10.867188 9.207031 C 10.980469 9.042969 10.980469 8.828125 10.867188 8.660156 C 10.753906 8.496094 10.554688 8.417969 10.359375 8.460938 Z M 6.066406 10.769531 C 3.402344 10.769531 1.230469 8.597656 1.230469 5.933594 C 1.230469 4.101562 2.292969 2.441406 3.875 1.625 C 3.613281 2.296875 3.476562 3.011719 3.476562 3.746094 C 3.476562 5.296875 4.082031 6.753906 5.175781 7.851562 C 6.273438 8.945312 7.726562 9.550781 9.277344 9.554688 C 8.402344 10.328125 7.265625 10.769531 6.066406 10.769531 Z M 6.066406 10.769531 " />
    </g>
  </svg>
);

export const NightIcon = (props) => <Icon component={NightSvg} {...props} />;
