export const isLoginAsAble = (user, page, record) => {
    if (page === 'users'  && user.isAdmin)
        return !record.isAdmin 
            ? { page: true, record: true } 
            : { page: true, record: false };
    if (page === 'buyers' && (user.isAdmin || user.permissions?.entities?.buyers?.edit))
        return record.isAllowedLogin 
            ? { page: true, record: true } 
            : { page: true, record: false };
    return { page: false, record: false };
};
