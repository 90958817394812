import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const cloudflareApi = createApi({
  reducerPath: 'cloudflareApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCloudflares: builder.query({
      query: ({ filters, currentPage, pageSize, sorter, role, userId }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        let userFilter;
        if (role === 'MEDIA_BUYER') {
          userFilter = `&filter.buyerId=${userId}`;
        } else {
          userFilter =
            role === 'SUPER_ADMIN' || 'ANALYST'
              ? ''
              : `&filter.userId=${userId}`;
        }
        return `domain-purchase/cloudflare?${url}${sort}${userFilter}`;
      },
    }),
    getCloudflare: builder.query({
      query: (id) => `domain-purchase/cloudflare/${id}`,
    }),
    updateResponsible: builder.mutation({
      query: (patch) => ({
        url: `domain-purchase/cloudflare/responsible`,
        method: 'PUT',
        body: patch,
      }),
    }),
  }),
  tagTypes: ['clouflareApi'],
});

export const {
  useGetCloudflaresQuery,
  useGetCloudflareQuery,
  useLazyGetCloudflaresQuery,
  useUpdateResponsibleMutation,
} = cloudflareApi;
