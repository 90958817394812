import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `user?${url}${sort}`;
      },
    }),
    getUser: builder.query({
      query: (id) => `user/${id}`,
    }),
    updateUser: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `user/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
  }),
  tagTypes: ['user'],
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useLazyGetUsersQuery,
} = usersApi;
