import dayjs from 'dayjs';
export const fromApiToClient = (apiDate, timezone = 'Europe/Kyiv') => {
  const dateW = dayjs.utc(apiDate);
  const kievDate = dateW.tz(timezone);
  if (!apiDate) return '';
  const day = kievDate.format('DD');
  const month = kievDate.format('MM');
  const year = kievDate.format('YYYY');
  const hour = kievDate.format('HH');
  const minute = kievDate.format('mm');

  return `${day}:${month}:${year} ${hour}:${minute}`;
};

export const fromApiToClientDate = (apiDate) => {
  if (!apiDate) return '';

  const date = new Date(dayjs.tz(apiDate, 'UTC').toISOString());
  return `${date.getUTCDate().toString().padStart(2, '0')}.${(
    date.getUTCMonth() + 1
  )
    .toString()
    .padStart(2, '0')}.${date.getUTCFullYear()}`;
};

export const fromApiToClientMonth = (apiDate) => {
  if (!apiDate) return '';

  const date = new Date(dayjs.tz(apiDate, 'UTC').toISOString());
  return `${(date.getUTCMonth() + 1).toString()}.${date.getUTCFullYear()}`;
};

export const fromApiToClientTimePlusShift = (
  apiDate,
  timezone = 'Europe/Kyiv',
  shift = 30
) => {
  const dateW = dayjs.utc(apiDate).add(shift, 'minute');
  const kievDate = dateW.tz(timezone);
  if (!apiDate) return '';
  const hour = kievDate.format('HH');
  const minute = kievDate.format('mm');

  return `${hour}:${minute}`;
};

export const fromApiToClientDateTimePlusShift = (
  apiDate,
  timezone = 'Europe/Kyiv',
  shift = 30
) => {
  const dateW = dayjs.utc(apiDate).add(shift, 'minute');
  const kievDate = dateW.tz(timezone);
  if (!apiDate) return '';
  const day = kievDate.format('DD');
  const month = kievDate.format('MM');
  const year = kievDate.format('YYYY');
  const hour = kievDate.format('HH');
  const minute = kievDate.format('mm');

  return `${day}:${month}:${year} ${hour}:${minute}`;
};

export const checkIsTimeInInterval = (apiDate, interval = 30, intervalMin) => {
  if (!apiDate) return false;
  const dateW = dayjs.utc(apiDate);
  const dateN = dayjs();
  const diff = dateN.diff(dateW, 'minute');
  const res =
    !intervalMin || intervalMin === undefined
      ? diff < interval
      : interval - intervalMin < diff && diff < interval;
  return res;
};
