import React from 'react';
import { Checkbox } from 'antd';

export const CheckboxInput = ({ defaultValue, onChange }) => {
  return (
    <Checkbox
      onClick={(e) => onChange(e.target.checked)}
      defaultChecked={defaultValue}
    />
  );
};
