import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api';
import jwt_decode from 'jwt-decode';
import {setSettings, setUser} from '../store/sharedSlices/globalSlice';
import { useDispatch } from 'react-redux';
import {Loader} from "../shared/Loader/Loader";
export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingSettings, setLoadingSettings] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  if (!token) {
    window.location.replace('/login');
  }
  const decoded = jwt_decode(token);

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      navigate('/login');
    }
  }, [location, navigate]);

  useEffect(() => {
    api.get('crm-setting').then(({data}) => {
      dispatch(setSettings(data));
      setLoadingSettings(false)
    });
    if (decoded.type === 'user') {
      api.get(`/user/${decoded.sub}`).then((res) => {
        dispatch(setUser(res.data));
        setLoadingUser(false)
        if (!res.data.isActive) navigate('/login');
      });
    } else if (decoded.type === 'buyer') {
      api.get(`/buyer/${decoded.sub}`).then((res) => {
        dispatch(setUser(res.data));
        setLoadingUser(false)
        if (!res.data.isActive) navigate('/login');
      });
    }
  }, []);
  return (loadingSettings || loadingUser) ? <Loader isFullScreen/> : children;
}
