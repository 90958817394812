import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const groupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `group-offer?${url}${sort}`;
      },
    }),
    getGroup: builder.query({
      query: (id) => `group-offer/${id}`,
    }),
  }),
  tagTypes: ['group-offer'],
});

export const { useGetGroupsQuery, useGetGroupQuery, useLazyGetGroupsQuery } =
  groupsApi;
