import { App, Button, Form, Input, Modal } from 'antd';
import React from 'react';
import api from '../../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import {useIntl} from "react-intl";

export const CloneCallStatusGroupModal = ({ isOpen, onCancel, idGroup }) => {
  const { formatMessage: f } = useIntl();
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const { message } = App.useApp();

  const onApiCall = () => {
    api
      .post(`/call-status-group/copy/${idGroup || id}`, {
        name: form.getFieldValue('name'),
      })
      .then((res) => {
        message.success(f({id: 'status_group_cloned'}));
        navigate(`/call-status-groups/${res.data}`);
        onCancel();
      });
  };

  return (
    <Modal
      title={f({id: 'copy_group_call_statuses'})}
      open={isOpen}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        form={form}
        onFinish={onApiCall}
      >
        <Form.Item
          name="name"
          label={f({id:'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id:'title'})} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
