import React from 'react';
import { Select } from 'antd';
import { roles } from '../../../utils/roles';

export const RoleInput = ({ defaultValue, onChange }) => {
  const rolesCheckboxes = roles.map((r) => ({
    label: r,
    value: r,
    key: r,
  }));

  return (
    <Select
      options={rolesCheckboxes}
      onChange={(e) => onChange(e)}
      defaultValue={{ value: defaultValue, label: defaultValue }}
    />
  );
};
