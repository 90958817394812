import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App, Divider, Tabs } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { BoxForm } from '../BoxForm/BoxForm';
import { BoxOrders } from '../BoxOrders/BoxOrders';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import {
  useCreateOrderBoxMutation,
  useDeleteOrderBoxMutation,
  useLazyGetBoxOrderQuery,
  useUpdateOrderBoxMutation
} from '../boxesApi';

export const EditBox = ({ drawerId }) => {
  const { message } = App.useApp();
  const [box, setBox] = useState();
  const { formatMessage: f } = useIntl();
  const { id } = useParams();
  usePermissionsAllowed(PERMISSIONS.BOXES_READ);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [updateOrderBox] = useUpdateOrderBoxMutation();
  const [createOrderBox] = useCreateOrderBoxMutation();
  const [deleteOrderBox] = useDeleteOrderBoxMutation();
  const [triggerOrderBox] = useLazyGetBoxOrderQuery();
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    api
      .get(`/box/${reqId}`)
      .then((res) => {
        setBox({
          ...res.data,
        });
        setIsLoading(false);
      })
      .finally((e) => {
        console.log(e);
      });
  }, []);

  const onFinish = (updatedBox) => {
    setIsDisabled(true);
    delete updatedBox.updatedAt;
    api
      .put(`/box/${reqId}`, {
        ...updatedBox,
      })
      .then((_) => {
        if(!drawerId) {
          navigate(-1);
        }
        message.success(f({ id: 'box_updated' }));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading || JSON.stringify(box) === '{}') return 'loading';
  return (
    <div>
      <BoxForm onFinish={onFinish} isDisabled={isDisabled} box={box} />
      <Divider orientation="center">{f({ id: 'orders' })}</Divider>
      <BoxOrders
        boxId={reqId}
        updateOrderBox={updateOrderBox}
        createOrderBox={createOrderBox}
        deleteOrderBox={deleteOrderBox}
        fetchBoxOrders={triggerOrderBox}
      />
    </div>
  );
};
