import { Button, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../providers/ThemeProvider';
import {FormattedMessage, useIntl} from "react-intl";

export const LoadMore = ({ dispatch, pageSize, isFetching, setPageSize }) => {
  const [isLoadMore, setIsLoadMore] = useState(false);
  useEffect(() => {
    setIsLoadMore(false);
  }, [isFetching]);
  const handlClickMoreLoad = () => {
    setIsLoadMore(true);
    dispatch(setPageSize(pageSize + 20));
  };
  const { isDarkTheme } = useContext(ThemeContext);
  const { formatMessage: f } = useIntl();
  return (
    <Row
      style={{
        display: 'flex',
        position: 'absolute',
        left: '45%',
        justifyContent: 'center',
        padding: '6px 6px',
      }}
    >
      <Button
        type="primary"
        ghost
        disabled={isLoadMore}
        onClick={handlClickMoreLoad}
        loading={isLoadMore}
        style={{
          alignItems: 'center',
          color: isDarkTheme ? '#ffffff' : '#272b34',
          cursor: 'pointer',
          textAlign: 'center',
        }}
      >
          {f({ id: 'load_more' })} <DownOutlined style={{ fontSize: '15px' }} />
      </Button>
    </Row>
  );
};
