import {
    useLazyGetEntityLogByIdQuery,
} from "../../pages/EntityLogs/entityLogsApi";
import React, { useEffect, useState } from "react";
import { usePermissionsAllowed } from "../../hooks/usePermissionsAllowed";
import { PERMISSIONS } from "../../utils/permissions";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { fromApiToClient } from "../../utils/dateFilters";
import { useParams } from "react-router-dom";
import { Button, Drawer, Tooltip , Table} from "antd";
import { DatabaseOutlined } from "@ant-design/icons";
import {LogsDetailsModal} from "../LogsDetailsModal/LogsDetailsModal";

export const LogsInEntity = ({ drawerId, entity }) => {
    const { formatMessage: f } = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const [openDrawerLog, setOpenDrawerLog] = useState(false);
    const [selectedData, setSelectedData] = useState('{}');
    const [isFetching, setIsFetching] = useState(true);
    const { id } = useParams();
    const reqId = drawerId ? drawerId : id;
    const [dataTable, setDataTable] = useState();
    const { user } = useSelector((state) => state.globalSlice);
    const [columns, setColumns] = useState([]);
    const [trigger] = useLazyGetEntityLogByIdQuery();
    const onClickLazyQuery = () => {
        setIsFetching(true);
        trigger({ id: reqId, entity }).then((e) => {
            setDataTable(e?.data);
            setIsFetching(false);
        });
    };

    const showDrawer = () => {
        onClickLazyQuery();
        setOpenDrawerLog(true);
    };

    const onClose = () => {
        setOpenDrawerLog(false);
    };

    useEffect(() => {
        if (user) {
            setColumns([
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: f({ id: 'table' }),
                    dataIndex: 'entity',
                    key: 'entity',
                    render:(entity)=> f({id:entity}),
                },
                {
                    title: f({ id: 'action' }),
                    dataIndex: 'action',
                    key: 'action',
                    render:(action)=> f({id:action}),
                },
                {
                    title: f({ id: 'user' }),
                    dataIndex: 'user',
                    key: 'user',
                    render: (user) => user?.nickname,
                },
                {
                    title: f({ id: 'created' }),
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (date) => fromApiToClient(date, user.timezone),
                },
                {
                    title: f({ id: 'data' }),
                    dataIndex: 'data',
                    key: 'data',
                    render: (d) => (
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                setIsOpen(true);
                                setSelectedData(d);
                            }}
                        >
                            {d}
                        </div>
                    ),
                },
            ]);
        }
    }, [user]);
    return (
        <div>
            {user.isAdmin && (
                <Tooltip title={f({ id: 'entity_logs_menu' })}>
                    <Button shape="round" className="mr-6" type="primary" icon={<DatabaseOutlined />} onClick={showDrawer} />
                </Tooltip>
            )}
            <Drawer
                title={`#${reqId} Log`}
                placement="bottom"
                closable={false}
                size="large"
                push={false}
                onClose={onClose}
                open={openDrawerLog}
                getContainer={false}
            >
                <LogsDetailsModal
                    data={selectedData}
                    isModalOpen={isOpen}
                    handleCancel={() => setIsOpen(false)}
                />
                <Table
                    loading={isFetching}
                    columns={columns}
                    dataSource={dataTable || []}
                    dataPrefix="logs"
                />
            </Drawer>
        </div>
    );
};
