import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {setFilters, setIsInitialLoad,} from '../../../store/sharedSlices/defaultPageSlice';
import styles from '../../../shared/Table/AppTable.module.css';
import {Form} from 'antd';
import {FiltersProvider} from '../../../providers/FIltersProvider';
import {IdFilters} from '../../../shared/filters/IdFilters';
import {InputStrFilters} from '../../../shared/filters/inputStrFilters';
import {ButtonSearchFilter} from '../../../shared/filters/ButtonSearchFilter';
import {DeletedFilters} from '../../../shared/filters/DeletedFilters';
import {useIntl} from "react-intl";

export const TemplateIntegrationsFilters = ({ onClickLazyQuery, isFetching }) => {
  const [searchParams] = useSearchParams();
  const { formatMessage: f } = useIntl();
  let url = searchParams.get('filters');
  const querystr = JSON.parse(url) ? JSON.parse(url) : null;
  const { user } = useSelector((state) => state.globalSlice);
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (querystr) {
      let object = {};
      for (let i in querystr) {
        object[querystr[i][1]] = querystr[i][2];
      }
      dispatch(
        setFilters({
          ...object,
        })
      );
    }
    dispatch(setIsInitialLoad(false));
  }, []);

  return (
    <Form layout="inline" className={styles.filters}>
      <FiltersProvider>
        <IdFilters />
        <InputStrFilters label={f({id: 'title'})} name="name" type="name" />
        <DeletedFilters label={f({id: 'deleted'})} name="isActive" type="isActive" />
        <ButtonSearchFilter
          onClickLazyQuery={onClickLazyQuery}
          isFetching={isFetching}
        />
      </FiltersProvider>
    </Form>
  );
};
