import React from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import { useIntl } from "react-intl";

export const UsersInput = ({ defaultValue, onChange }) => {
  const { formatMessage: f } = useIntl();
  const fetchUsers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?filter.nickname=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };
  const initialFetchUsers = async () => {
    const res = await api.get(`/user?limit=10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  return (
    <AutocompleteSelect
      style={{
        width: '130px',
      }}
      placeholder={f({ id: 'user' })}
      initialFetch={initialFetchUsers}
      fetch={fetchUsers}
      onChange={(e) => onChange({ id: e.value })}
      defaultValue={{
        value: defaultValue.id,
        label: `#${defaultValue.id} ${defaultValue.nickname}`,
      }}
    />
  );
};
