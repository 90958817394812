import React, { useEffect, useState } from 'react';
import api from '../../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  DatePicker,
  message, Switch,
} from 'antd';
import styles from './LeadDetails.module.css';
import {
  hasPermissions,
  usePermissionsAllowed,
} from '../../../hooks/usePermissionsAllowed';
import {
  translateStatus,
} from '../../../utils/translateStatus';
import { StatusTag } from '../../../shared/StatusTag/StatusTag';
import { FormFieldRestricter } from '../../../shared/FormFieldRestricter/FormFieldRestricter';
import {
  fromApiToClient
} from '../../../utils/dateFilters';
import { isJsonValid } from '../../../utils/isJsonValid';
import { PERMISSIONS } from '../../../utils/permissions';
import { useSelector } from 'react-redux';
import { permissionsColumnsBuyer } from '../../../utils/permissionsBuyer';
import {CheckOutlined, EditOutlined, MinusOutlined} from '@ant-design/icons';
import {useIntl} from "react-intl";
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {PermissionalButtonDrawer} from "../../../shared/PermissionalLink/PermissionalLink";
import dayjs from "dayjs";
import {ChangeCallStatusAndDepositModal} from "../Leads/modals/ChangeCallStatusAndDepositModal";

const { TextArea } = Input;

export const LeadDetails = ({ drawerId }) => {
  const [lead, setLead] = useState();
  const { formatMessage: f } = useIntl();
  const [isStatusLocked, setIsStatusLocked] = useState(false);
  const [isGeneralStatusLocked, setIsGeneralStatusLocked] = useState(false);
  const [isCallStatusLocked, setIsCallStatusLocked] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);
  const [status, setStatus] = useState({ label: '', value: '' });
  const [generalStatus, setGeneralStatus] = useState({ label: '', value: '' });
  const [isDisabled, setIsDisabled] = useState(false);
  const [isValidHistory, setIsValidHistory] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [switchUseWebStatus, setSwitchUseWebStatus] = useState(false);
  const [webStatus, setWebStatus] = useState('');
  const [switchUseWebCallStatus, setSwitchUseWebCallStatus] = useState(false);
  const [switchUseWebIsDeposit, setSwitchUseWebIsDeposit] = useState(false);
  const [webIsDeposit, setWebIsDeposit] = useState(false);
  const [webDepositDate, setWebDepositDate] = useState();

  const [generalIsDeposit, setGeneralIsDeposit] = useState(false);
  const [isShowFakeInfo, setIsShowFakeInfo] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedSent, setSelectedSent] = useState();
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});
  const [allDataLead, setAllDataLead] = useState()
  const [allStatus, setAllStatus] = useState([
    {
      value: 'pending',
      label: (
        <StatusTag
          status="pending"
          style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
        />
      ),
    },
    {
      value: 'approved',
      label: (
        <StatusTag
          status="approved"
          style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
        />
      ),
    },
    {
      value: 'rejected',
      label: (
        <StatusTag
          status="rejected"
          style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
        />
      ),
    },
    {
      value: 'test',
      label: (
        <StatusTag
          status="test"
          style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
        />
      ),
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  usePermissionsAllowed(PERMISSIONS.LEADS_EDIT);

  const { user } = useSelector((state) => state.globalSlice);

  const fetchLead = () => {
    api.get(`/lead/${drawerId ? drawerId : id}`).then((res) => {
      setAllDataLead(res.data)
      form.setFieldValue('unsignedDetails', res.data.unsignedDetails);
      form.setFieldValue('responseDetail', res.data.responseDetail);
      form.setFieldValue('requestDetail', res.data.requestDetail);
      setLoading(false);
      setLead(res.data);
      setIsStatusLocked(res.data.isStatusLocked);
      setIsGeneralStatusLocked(res.data.isGeneralStatusLocked);
      form.setFieldValue('name', res.data.name);
      form.setFieldValue('lastName', res.data.lastName);
      form.setFieldValue('phone', res.data.phone);
      form.setFieldValue('email', res.data.email);
      form.setFieldValue('country', res.data.country);
      form.setFieldValue('lang', res.data.lang);
      form.setFieldValue(
          'createdAt',
          fromApiToClient(res.data.createdAt, user?.timezone)
      );
      form.setFieldValue(
          'updatedAt',
          fromApiToClient(res.data.updatedAt, user?.timezone)
      );
      form.setFieldValue('userAgent', res.data.userAgent);
      form.setFieldValue('status', res.data.status);
      form.setFieldValue('sub1', res.data.sub1);
      form.setFieldValue('sub2', res.data.sub2);
      form.setFieldValue('sub3', res.data.sub3);
      form.setFieldValue('sub4', res.data.sub4);
      form.setFieldValue('sub5', res.data.sub5);
      form.setFieldValue('sub6', res.data.sub6);
      form.setFieldValue('sub7', res.data.sub7);
      form.setFieldValue('sub8', res.data.sub8);
      form.setFieldValue('ip', res.data.ip);
      form.setFieldValue('id', res.data.id);
      setSelectedSent(res.data.sentAt)
      form.setFieldValue(
          'order',
          res.data?.order?.id ?  `#${res.data?.order?.id} ${res.data?.order?.name}` : ''
      );
      form.setFieldValue(
          'buyer',
          res.data?.buyer?.id ? `#${res.data?.buyer?.id} ${res.data?.buyer?.name}` : ''
      );
      form.setFieldValue(
          'user',
          res.data?.user?.id ? `#${res.data?.user?.id} ${res.data?.user?.nickname}` : ''
      );
      form.setFieldValue('box', res.data?.box?.id ? `#${res.data?.box?.id} ${res.data?.box?.name}` : '');
      form.setFieldValue('isStatusLocked', res.data.isStatusLocked);
      form.setFieldValue('status', res.data?.status);
      form.setFieldValue('generalStatus', res.data?.generalStatus);
      form.setFieldValue('sellPrice', res.data.sellPrice);
      form.setFieldValue('buyPrice', res.data.buyPrice);
      form.setFieldValue('externalId', res?.data?.externalId);
      form.setFieldValue('callStatus', res?.data?.callStatus);
      let callStatusHistory = '';
      if (!user?.isAdmin) {
        for (const h of JSON.parse(res.data.callStatusHistory)) {
          callStatusHistory = callStatusHistory.concat(
              `${fromApiToClient(h.dateTime)} | ${h.callStatus} \n`
          );
        }
        form.setFieldValue('callStatusHistory', callStatusHistory);
      } else {
        form.setFieldValue('callStatusHistory', res?.data?.callStatusHistory);
      }
      form.setFieldValue('previousCallStatus', res?.data?.previousCallStatus);
      setIsStatusLocked(res.data.isStatusLocked);
      setIsGeneralStatusLocked(res.data.isGeneralStatusLocked);
      setIsCallStatusLocked(res.data.isCallStatusLocked);
      setIsAnswer(res.data.isAnswer)
      setStatus({
        label: (
            <StatusTag
                status={translateStatus(res.data.status)}
                style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
            />
        ),
        value: res.data.status,
      });
      setGeneralStatus({
        label: (
            <StatusTag
                status={translateStatus(res.data.generalStatus)}
                style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
            />
        ),
        value: res.data.generalStatus,
      });
      setWebStatus({
        label: (
            <StatusTag
                status={translateStatus(res.data.webStatus)}
                style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
            />
        ),
        value: res.data.webStatus,
      })
      form.setFieldValue('webStatus', res.data.webStatus);
      form.setFieldValue('webCallStatus', res.data.webCallStatus);
      form.setFieldValue('webIsDeposit', res.data.webIsDeposit);
      form.setFieldValue('isDeposit', res.data.isDeposit);
      form.setFieldValue('webCallStatusHistory', res.data.webCallStatusHistory);
      setGeneralIsDeposit(res.data.generalIsDeposit);
      form.setFieldValue('generalIsDeposit', res.data.generalIsDeposit);
      setIsShowFakeInfo(checkIsShowFakeInfo(user, res.data));

      setSwitchUseWebStatus(res.data.useWebStatus);
      setSwitchUseWebCallStatus(res.data.useWebCallStatus);
      setSwitchUseWebIsDeposit(res.data.useWebIsDeposit);
      setWebIsDeposit(res.data.webIsDeposit)
      setWebDepositDate(res.data.webDepositDate)
    });
  }
  useEffect(() => {
    fetchLead()
  }, [user]);

  const onFinish = () => {
    if (!isValidHistory) return;
    setIsDisabled(true);
    api
      .put(`/lead/${drawerId ? drawerId : id}`, {
        name: form.getFieldValue('name'),
        lastName: form.getFieldValue('lastName'),
        email: form.getFieldValue('email'),
        country: form.getFieldValue('country'),
        lang: form.getFieldValue('lang'),
        userAgent: form.getFieldValue('userAgent'),
        ip: form.getFieldValue('ip'),
        phone: form.getFieldValue('phone'),
        sub1: form.getFieldValue('sub1'),
        sub2: form.getFieldValue('sub2'),
        sub3: form.getFieldValue('sub3'),
        sub4: form.getFieldValue('sub4'),
        sub5: form.getFieldValue('sub5'),
        sub6: form.getFieldValue('sub6'),
        sub7: form.getFieldValue('sub7'),
        sub8: form.getFieldValue('sub8'),
        externalId: form.getFieldValue('externalId'),
        sellPrice: +form.getFieldValue('sellPrice'),
        buyPrice: +form.getFieldValue('buyPrice'),
        callStatus: form.getFieldValue('callStatus'),
        callStatusHistory: form.getFieldValue('callStatusHistory'),
        previousCallStatus: form.getFieldValue('previousCallStatus'),
        sentAt: selectedSent,
        webStatus: webStatus?.value || webStatus,
        useWebStatus: switchUseWebStatus,
        webCallStatus: form.getFieldValue('webCallStatus'),
        useWebCallStatus: switchUseWebCallStatus,
        useWebIsDeposit: switchUseWebIsDeposit,
        webIsDeposit: webIsDeposit,
        webDepositDate: webDepositDate,
        webCallStatusHistory: form.getFieldValue('webCallStatusHistory'),
        isCallStatusLocked,
        isStatusLocked,
        isGeneralStatusLocked,
        status: status.value || status,
        generalStatus: generalStatus.value || generalStatus,
        generalIsDeposit,
        isAnswer: isAnswer
      })
      .then(() => {
        if(!drawerId) {
          navigate(-1);
        } else {
          message.success(f({ id: 'lead_updated'}));
        }
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  const onLockClick = (e) => {
    setIsStatusLocked(e.target.checked);
  };

  const onLockGeneralStatusClick = (e) => {
    setIsGeneralStatusLocked(e.target.checked);
  };
  const onLockCallClick = (e) => {
    setIsCallStatusLocked(e.target.checked);
  };
  const onAnswerClick = (e) => {
    setIsAnswer(e.target.checked);
  };
  const onStatusChange = (value) => {
    setStatus(value);
    setIsStatusLocked(true);
  };
  const onGeneralStatusChange = (value) => {
    setIsGeneralStatusLocked(true)
    setGeneralStatus(value);
  };

  if (loading || !user) return <Spin size="large" />;
  const isVisAdminDep = user?.isAdminDepVisible || user?.isAdmin
  const isVisFakeInfo = user?.isCanSetFakeInfo || user?.isAdmin
  if (user?.isAdmin) {
    allStatus.push(
      {
        value: 'error',
        label: (
          <StatusTag
            status="error"
            style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
          />
        ),
      },
        {
          value: 'error_timeout',
          label: (
              <StatusTag
                  status="error_timeout"
                  style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
              />
          ),
        },
        {
          value: 'error_duplicate',
          label: (
              <StatusTag
                  status="error_duplicate"
                  style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
              />
          ),
        },
      {
        value: 'unsigned',
        label: (
          <StatusTag
            status="unsigned"
            style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
          />
        ),
      },
        {
          value: 'hold',
          label: (
              <StatusTag
                  status="hold"
                  style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
              />
          ),
        },
      {
        value: 'notDistributed',
        label: (
          <StatusTag
            status="notDistributed"
            style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
          />
        ),
      }
    );
  }
  return (
    <div>
      <Form
        layout=""
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 12,
        }}
      >
        <Row className={styles.formContainer}>
          <Col style={{ width: '50%' }}>
            <FormFieldRestricter
              allowedFields={user?.permissions?.columns?.leads}
              isBuyer={true}
            >
              <Form.Item
                label="ID"
                name="id"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label={f({id: 'name'})}
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label={f({id: 'last_name'})}
                name="lastName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label={f({id: 'phone'})}
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label={f({id: 'country_one'})}
                name="country"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>

              <Form.Item
                label={f({id: 'language'})}
                name="lang"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label="IP"
                name="ip"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label="User agent" name="userAgent">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label="Sub 1" name="sub1">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label="Sub 2" name="sub2">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label="Sub 3" name="sub3">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label="Sub 4" name="sub4">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label="Sub 5" name="sub5">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label="Sub 6" name="sub6">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label="Sub 7" name="sub7">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label="Sub 8" name="sub8">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label={f({id: 'created'})}
                name="createdAt"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label={f({id: 'updated'})}
                name="updatedAt"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled className={styles.formItem} />
              </Form.Item>
              <Form.Item
                  label={f({id: 'sent'})}
                  name="sent"
              >
                <DatePicker
                    disabled={!user.isAdmin}
                    defaultValue={selectedSent ? dayjs(selectedSent, 'YYYY-MM-DD HH:mm') : null}
                    showTime
                    onChange={date => {
                      setSelectedSent(date)
                    }}
                />
              </Form.Item>
            </FormFieldRestricter>
          </Col>
          <Col style={{ width: '50%' }}>
            <FormFieldRestricter
              allowedFields={user.permissions.columns.leads}
            >
              <Form.Item name="order" label={f({id: 'order'})}>
                <Input disabled style={{width: '70%'}} value={allDataLead?.order?.id ?  `#${allDataLead?.order?.id} ${allDataLead?.order?.name}` : ''} className={styles.formItem} />
                {allDataLead?.order?.id && <PermissionalButtonDrawer permissions={PERMISSIONS.ORDERS_EDIT} isShow={!!allDataLead?.order?.id}
                                           onClick={() => {
                                             setIsOpenDrawer(true)
                                             setDataDrawer({id: allDataLead?.order?.id, type: 'orders'})
                                           }}/>}
              </Form.Item>
              <Form.Item name="buyer" label={f({id: 'executor'})}>
                <Input disabled style={{width: '70%'}} value={allDataLead?.buyer?.id ?  `#${allDataLead?.buyer?.id} ${allDataLead?.buyer?.nickname}` : ''} className={styles.formItem} />
                {allDataLead?.buyer?.id && <PermissionalButtonDrawer permissions={PERMISSIONS.BUYERS_EDIT} isShow={!!allDataLead?.buyer?.id}
                                           onClick={() => {
                                             setIsOpenDrawer(true)
                                             setDataDrawer({id: allDataLead?.buyer?.id, type: 'webmasters'})
                                           }}/>}
              </Form.Item>
              <Form.Item name="user" label={f({id: 'responsible'})}>
                <Input disabled style={{width: '70%'}} value={allDataLead?.user?.id ?  `#${allDataLead?.user?.id} ${allDataLead?.buyer?.nickname}` : ''} className={styles.formItem} />
                {allDataLead?.user?.id && <PermissionalButtonDrawer permissions={PERMISSIONS.USERS_EDIT} isShow={!!allDataLead?.user?.id}
                                           onClick={() => {
                                             setIsOpenDrawer(true)
                                             setDataDrawer({id: allDataLead?.user?.id, type: 'users'})
                                           }}/>}
              </Form.Item>
              <Form.Item name="box" label={f({id: 'box'})}>
                <Input disabled style={{width: '70%'}} value={allDataLead?.box?.id ?  `#${allDataLead?.box?.id} ${allDataLead?.box?.name}` : ''} className={styles.formItem} />
                {allDataLead?.user?.id && <PermissionalButtonDrawer permissions={PERMISSIONS.BOXES_EDIT} isShow={!!allDataLead?.user?.id}
                                           onClick={() => {
                                             setIsOpenDrawer(true)
                                             setDataDrawer({id: allDataLead?.box?.id, type: 'boxes'})
                                           }}/>}
              </Form.Item>

              <Form.Item label={f({id: 'external_id'})} name="externalId">
                <Input disabled={isDisabledInput(!user?.isCanEditLead, !user.isAdmin)} className={styles.formItem} />
              </Form.Item>
              <Form.Item label={f({id: 'sell_price'})} name="sellPrice">
                <Input
                  type="number"
                  disabled={!hasPermissions(user, PERMISSIONS.LEADS_EDIT)}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label={f({id: 'buy_price'})} name="buyPrice">
                <Input
                  type="number"
                  disabled={!hasPermissions(user, PERMISSIONS.LEADS_EDIT)}
                  className={styles.formItem}
                />
              </Form.Item>
              { (isVisAdminDep || !user?.isClientAllowedToSetFakeInfo) &&  <Form.Item label={f({id: 'status'})} name="status">
                <Select
                    disabled={!user?.isStatusChangeAllowed && !user?.isAdmin}
                    onChange={onStatusChange}
                    options={allStatus}
                    className={styles.formItem}
                />
              </Form.Item>}
              {user?.isClientAllowedToSetFakeInfo && (<Form.Item label={f({id: isVisAdminDep ? 'general_status' : 'status'})} name="generalStatus">
                <Select
                    disabled={!user?.isStatusChangeAllowed && !user?.isAdmin}
                    onChange={onGeneralStatusChange}
                    options={allStatus}
                    className={styles.formItem}
                />
              </Form.Item>)}
              <Form.Item label={f({id: 'call_status'})} name="callStatus">
                <Input
                  disabled={true}
                  onChange={() => {
                    if (!isCallStatusLocked) setIsCallStatusLocked(true);
                  }}
                  className={styles.formItem}
                />
              </Form.Item>

              {user?.isAdmin && (
                <Form.Item
                  label={f({id: 'call_status_history'})}
                  name="callStatusHistory"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    style={{ borderColor: isValidHistory ? '' : 'red' }}
                    onChange={(e) => {
                      setIsValidHistory(isJsonValid(e.target.value));
                    }}
                    className={styles.formItem}
                  />
                </Form.Item>
              )}
              {!user?.isAdmin && (
                <Form.Item
                  name="callStatusHistory"
                  label={f({id: 'call_status_history'})}
                >
                  <TextArea
                    disabled
                    rows={4}
                    placeholder="Історія call статусів"
                    className={styles.formItem250}
                  />
                </Form.Item>
              )}
              {(isVisAdminDep || !user?.isClientAllowedToSetFakeInfo) && <Form.Item label={f({id: 'deposit'})} name="isDeposit">
                <>
                  <IsDepositIcon isDeposit={lead.isDeposit}/>
                  <i style={{marginLeft: 20, marginRight: 20}}>
                    {`${fromApiToClient(
                        lead.realDepositDate
                    )}`}
                  </i>
                  {(user?.isStatusChangeAllowed || user?.isAdmin) &&
                      <Button onClick={() => setIsModalOpen(true)} type="text" icon={<EditOutlined/>}/>}
                </>
              </Form.Item>}
              {(isShowFakeInfo && user?.isClientAllowedToSetFakeInfo) && (
                <Form.Item label={f({id: isVisAdminDep ? 'general_deposit' : 'deposit'})} name="generalIsDeposit">
                   <>
                    <IsDepositIcon isDeposit={lead.generalIsDeposit} />
                    <i style={{ marginLeft: 20 }}>
                      {`${fromApiToClient(lead.depositDate)}`}
                    </i>
                  </>
                </Form.Item>
              )}
              <Form.Item name="requestDetail" label="Request">
                <TextArea
                  disabled
                  rows={4}
                  placeholder="Request"
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item name="responseDetail" label="Response">
                <TextArea
                  disabled
                  rows={4}
                  placeholder="Response"
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item name="unsignedDetails" label={f({id: 'details'})}>
                <TextArea
                  disabled
                  rows={4}
                  placeholder={f({id: 'details'})}
                  className={styles.formItem}
                />
              </Form.Item>
            </FormFieldRestricter>
          </Col>
        </Row>
        {((user?.isCanSetFakeInfo || user?.isAdmin)  && user?.isClientAllowedToSetFakeInfo) && (
            <div>
              <hr></hr>
              <h3 className={styles.webInfoTitle}>WEB info</h3>
              <Col className={styles.webInfo}>
                <Form.Item
                    label={f({id: 'web_status'})}
                    name="webStatus"
                    className={styles.formItemWithSwicherWraper}
                >
                  <div className={styles.formItemWithSwicher}>
                    <Form.Item name="webStatus">
                      <Select
                          disabled={!user?.isCanSetFakeInfo && !user?.isAdmin}
                          onChange={(value) => setWebStatus(value)}
                          options={allStatus}
                          style={{width: 250}}
                          className={styles.formItem}
                      />
                    </Form.Item>
                    <div className={styles.switchWraper}>
                      <Switch
                          defaultChecked={switchUseWebStatus}
                          onChange={setSwitchUseWebStatus}
                          disabled={!user?.isCanSetFakeInfo && !user?.isAdmin}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                    label={f({id: 'web_call_status'})}
                    className={styles.formItemWithSwicherWraper}
                    name="webCallStatus"
                >
                  <div className={styles.formItemWithSwicher}>
                    <Form.Item name="webCallStatus">
                      <Input
                          style={{width: 250}}
                          className={styles.formItem}
                          disabled={!user?.isCanSetFakeInfo && !user?.isAdmin}
                      />
                    </Form.Item>
                    <div className={styles.switchWraper}>
                      <Switch
                          defaultChecked={switchUseWebCallStatus}
                          onChange={setSwitchUseWebCallStatus}
                          disabled={!user?.isCanSetFakeInfo && !user?.isAdmin}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                    name="webCallStatusHistory"
                    label={f({id: 'call_status_history'})}
                    className={styles.formItemWithSwicherWraper}
                >
                  <div className={styles.formItemWithSwicher}>
                    <Form.Item
                        name="webCallStatusHistory"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                    >
                      <TextArea
                          rows={4}
                          style={{borderColor: isValidHistory ? '' : 'red'}}
                          onChange={(e) => {
                            setIsValidHistory(isJsonValid(e.target.value));
                          }}
                          className={styles.formItem}
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                    name="webIsDeposit"
                    label={f({id: 'web_deposit'})}
                    className={styles.formItemWithSwicherWraper}
                >
                  <div className={styles.formItemWithSwicher}>
                    <Form.Item name="webIsDeposit">
                      <Select
                          disabled={!user?.isCanSetFakeInfo && !user?.isAdmin}
                          onChange={(value) => {
                            if (!value) setWebDepositDate(null)
                            setWebIsDeposit(value)
                          }}
                          options={[
                            {value: false, label: 'Немає'},
                            {value: true, label: 'Наявний',}
                          ]}
                          style={{minWidth: '250px'}}
                          className={styles.formItem}
                      />
                    </Form.Item>
                    <div className={styles.switchWraper}>
                      <Switch
                          defaultChecked={switchUseWebIsDeposit}
                          onChange={setSwitchUseWebIsDeposit}
                          disabled={!user?.isCanSetFakeInfo && !user?.isAdmin}
                      />
                    </div>
                  </div>
                </Form.Item>
                {webIsDeposit && (<Form.Item
                    name="webDepositDate"
                    label={f({id: 'web_deposit_date'})}
                    className={styles.formItemWithSwicherWraper}
                >
                  <div className={styles.formItemWithSwicher}>
                    <Form.Item name="webDepositDate">
                      <DatePicker
                          disabled={!user?.isCanSetFakeInfo && !user?.isAdmin}
                          defaultValue={webDepositDate ? dayjs(webDepositDate, 'YYYY-MM-DD HH:mm') : null}
                          showTime
                          className={styles.formItem}
                          onChange={date => {
                            setWebDepositDate(date)
                          }}
                      />
                    </Form.Item>
                  </div>
                </Form.Item>)}
              </Col>
              <hr></hr>
            </div>
        )}

        { isVisAdminDep && (
            <Form.Item
                wrapperCol={{offset: 1}}
                labelCol={{offset: 10}}
                name="isStatusLocked"
                label={f({id: 'status_lock'})}
            >
              <Checkbox
                  onClick={onLockClick}
                  defaultChecked={lead ? lead.isStatusLocked : true}
                  checked={isStatusLocked}
              />
            </Form.Item>
        )}
        {((user?.isStatusChangeAllowed || user?.isAdmin) &&  user?.isClientAllowedToSetFakeInfo) && (
            <Form.Item
                wrapperCol={{offset: 1}}
                labelCol={{offset: 10}}
                name="isGeneralStatusLocked"
                label={f({id: isVisAdminDep ? 'general_status_lock' : 'status_lock'})}
            >
              <Checkbox
                  onClick={onLockGeneralStatusClick}
                  defaultChecked={lead ? lead.isGeneralStatusLocked : true}
                  checked={isGeneralStatusLocked}
              />
            </Form.Item>
        )}
        {user?.isAdmin && (
            <Form.Item
                wrapperCol={{offset: 1}}
                labelCol={{offset: 10}}
                name="isCallStatusLocked"
                label={f({id: 'call_status_lock'})}
            >
              <Checkbox
                  onClick={onLockCallClick}
                  defaultChecked={isCallStatusLocked}
                  checked={isCallStatusLocked}
              />
          </Form.Item>
        )}
        <Form.Item
            wrapperCol={{ offset: 1 }}
            labelCol={{ offset: 10 }}
            name="isAnswer"
            label={f({id: 'is_answer'})}
        >
          <Checkbox
              onClick={onAnswerClick}
              defaultChecked={isAnswer}
              checked={isAnswer}
          />
        </Form.Item>
        {hasPermissions(user, PERMISSIONS.LEADS_EDIT) && (
          <Row className={styles.formContainer}>
            <Form.Item>
              <Button
                loading={isDisabled}
                disabled={isDisabled}
                type="primary"
                htmlType="submit"
              >
                {f({id: 'save'})}
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
      <ChangeCallStatusAndDepositModal
          isModalOpen={isModalOpen}
          leads={[{id: allDataLead?.id}]}
          handleCancel={() => setIsModalOpen(false)}
          onSuccess={() => {
            setIsModalOpen(false)
            fetchLead()
          }}
      />
    </div>
  );
};

const IsDepositIcon = ({ isDeposit }) => {
  return isDeposit ? <CheckOutlined /> : <MinusOutlined />;
};

const checkIsShowFakeInfo = (user, lead) => {
  return user?.isAdmin || lead.order?.useManualApprovalDeposit;

};
const isDisabledInput = (isCanEdit, user) => {
  return isCanEdit || user?.isAdmin
}

