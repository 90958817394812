import io from 'socket.io-client';

export const socket = io(
  `${window.REACT_APP_WS_API_HOST.replace('http', 'ws').replace(
    'https',
    'ws'
  )}:${window.REACT_APP_WS_API_PORT}`,
  {
    transports: ['websocket'],
    path: window.REACT_APP_WS_API_PATH || undefined,
  }
);
