import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const directionsApi = createApi({
  reducerPath: 'directionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDirections: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `direction?${url}${sort}`;
      },
    }),
    getDirection: builder.query({
      query: (id) => `direction/${id}`,
    }),
  }),
  tagTypes: ['directions'],
});

export const { useGetDirectionsQuery, useGetDirectionQuery, useLazyGetDirectionsQuery } =
    directionsApi;
