import { Select } from 'antd';
import {FormattedMessage} from "react-intl";
import {languages} from "../../utils/languageIso";

const { Option } = Select;

export const LanguageSelect = ({ values, onChange, mode, onlyCountry, ...props }) => {
  return (
    <Select
      mode={mode}
      placeholder={<FormattedMessage id="language" />}
      onChange={onChange}
      optionLabelProp="label"
      defaultValue={values}
      showSearch
      filterOption={(inputValue, option) =>
        option.label.toUpperCase().includes(inputValue.toUpperCase()) ||
        option.value.toUpperCase().includes(inputValue.toUpperCase())
      }
      {...props}
    >
      {languages.map((c, index) => (
        <Option key={index} value={c.code} label={`${c.name} (${c.code})`}>
          <div>
            <span role="img" aria-label={`${c.name} (${c.code})`}>
              {c.flag}&nbsp;
            </span>
            {c.name}&nbsp;({c.code.trim()})
          </div>
        </Option>
      ))}
    </Select>
  );
};
