import { Spin } from 'antd';
import { useContext } from 'react';
import { ThemeContext } from '../../providers/ThemeProvider';

export const Loader = ({ isFullScreen }) => {
  const context = useContext(ThemeContext);
  let darkTheme = context?.isDarkTheme;
  if (!darkTheme) {
    darkTheme = localStorage.getItem('isDarkTheme') === 'true' || false
  }
  return (
    <div
      className={`${isFullScreen ? 'w-screen h-screen' : ''} flex justify-center items-center`}
      style={{ backgroundColor: darkTheme && '#323743' }}
    >
      <Spin size="large" />
    </div>
  );
};
