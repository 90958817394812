import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: ({ filters }) => {
        const groupings = [{ value: 'buyer', label: 'Веб' }];

        return `/report-dashboard?groupings=${groupings
          .map((g) => g.value)
          .join(',')}&filters=${JSON.stringify(filters)}`;
      },
    }),
  }),
  tagTypes: ['dashboard'],
});

export const { useGetDashboardQuery } = dashboardApi;
