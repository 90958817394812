import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const countryBoxApi = createApi({
  reducerPath: 'countryBoxApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCountryBoxes: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `country-box?${url}${sort}`;
      },
    }),
    getCountryBox: builder.query({
      query: (id) => `country-box/${id}`,
    }),
    updateCountryBox: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `country-box/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
    updateResponsible: builder.mutation({
      query: (patch) => ({
        url: `country-box/responsible`,
        method: 'PUT',
        body: patch,
      }),
    }),
  }),
  tagTypes: ['countryBox'],
});

export const {
  useGetCountryBoxesQuery,
  useGetCountryBoxQuery,
  useUpdateCountryBoxMutation,
  useLazyGetCountryBoxesQuery,
  useUpdateResponsibleMutation,
} = countryBoxApi;
