import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { CloudflareForm } from '../CloudflareForm/CloudflareForm';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const EditCloudflare = ({drawerId}) => {
  const { message } = App.useApp();
  const { formatMessage: f } = useIntl();
  const [cf, setCf] = useState();
  const { id } = useParams();
  usePermissionsAllowed(PERMISSIONS.DOMAINS_EDIT);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reqId] = useState(drawerId ? drawerId : id);
  useEffect(() => {
    api.get(`/domain-purchase/cloudflare/${reqId}`).then((res) => {
      setCf({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedCf) => {
    setIsDisabled(true);
    delete updatedCf.updatedAt;
    delete updatedCf.createdAt;
    api
      .put(`/domain-purchase/cloudflare/${reqId}`, {
        ...updatedCf,
      })
      .then((_) => {
        if(!drawerId) {
          navigate(-1);
        }
        message.success(f({id: 'cloudflare_updated'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  return <CloudflareForm isDisabled={isDisabled} onFinish={onFinish} cf={cf} />;
};
