import {App} from 'antd';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {usePermissionsAllowed} from '../../../hooks/usePermissionsAllowed';
import api from '../../../api/api';
import {PERMISSIONS} from '../../../utils/permissions';
import {useIntl} from "react-intl";
import {TemplateIntegrationsDetails} from "../TemplateIntegrationDetails/TemplateIntegrationsDetails";

export const EditTemplateIntegration = ({drawerId}) => {
  const { message } = App.useApp();
  const [integration, setIntegration] = useState();
  const { id } = useParams();
  const { formatMessage: f } = useIntl();
  usePermissionsAllowed(PERMISSIONS.INTEGRATIONS_EDIT);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    api.get(`/template-integrations/${reqId}`).then((res) => {
      setIntegration(res.data);
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedIntegration) => {
    setIsDisabled(true);
    delete updatedIntegration.updatedAt;
    api
      .put(`/template-integrations/${reqId}`, {
        ...updatedIntegration,
      })
      .then((_) => {
        if(!drawerId) {
          navigate(-1);
        }
        message.success(f({id: 'integration_updated'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  return (
    <TemplateIntegrationsDetails
      isDisabled={isDisabled}
      onFinish={onFinish}
      integration={integration}
    />
  );
};
