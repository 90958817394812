import { Form, Select } from 'antd';
import styles from '../Table/AppTable.module.css';
import { updateFilters } from '../../store/sharedSlices/defaultPageSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFilterContext } from '../../hooks/useFilterContext';
import { useIntl } from "react-intl";

export const IdFilters = ({ type, updateTypeFilters = updateFilters, ids }) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const [querystr, setSearchParams] = useFilterContext();
  return (
    <>
      <Form.Item key="ID" label="ID" name="id" className={styles.filter}>
        <Select
          onChange={(e) => {
            dispatch(
              updateTypeFilters({ name: 'id', value: `$in:${e.join(',')}` })
            );
            setSearchParams({
              filters: JSON.stringify({
                ...querystr,
                id: [e, 'id', `$in:${e.join(',')}`],
              }),
            });
          }}
          defaultValue={
            ids
              ? `${ids.split(',').length} ${f({ id: 'leads'})}`
              : querystr?.id
              ? querystr?.id[0]
              : undefined
          }
          allowClear
          style={{
            width: '130px',
          }}
          mode="tags"
        />
      </Form.Item>
    </>
  );
};
