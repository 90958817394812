import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Row,
  Tooltip,
} from 'antd';
import styles from './PermissionsForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';

import {useIntl} from "react-intl";
import { useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import {buyerPermissionsApi} from "../permissionsApi";

export const PermissionsForm = ({ permissions, isDisabled, onFinish, isExistingOrder = false }) => {
  const { formatMessage: f } = useIntl();
  const [form] = Form.useForm();

  const leadsOptions = [
    'id',
    'name',
    'lastName',
    'email',
    'phone',
    'box_id',
    'order_id',
    'ip',
    'webCallStatus',
    'webCallStatusHistory',
    'country',
    'webIsDeposit',
    'webStatus',
    'sub1',
    'sub2',
    'sub3',
    'sub4',
    'sub5',
    'sub6',
    'sub7',
    'sub8',
    'utmCampaign',
    'utmSource',
    'utmContent',
    'utmMedium',
    'utmTerm',
    'platform',
    'url',
    'webDepositDate',
    'offerId',
    'createdAt',
    'responseDetail',
    'unsignedDetails',
    'sentAt',
    'createdAt',
    'uuid',
    'isAutoLogin',
  ];
  const [isCanInject, setIsCanInject] = useState(false);
  const [isAllowedLogin, setIsAllowedLogin] = useState(true);
  const [isAllowedApi, setIsAllowedApi] = useState(true);
  const [isLockedStatusVisible, setIsLockedStatusVisible] = useState(true);
  const [selectedLeadsOptions, setSelectedLeadsOptions] = useState([]);
  const dispatch = useDispatch();

  const onCanInjectChange = (e) => {
    setIsCanInject(e.target.checked);
  };
  const onAllowedLogin = (e) => {
    setIsAllowedLogin(e.target.checked);
  };
  const onAllowedApi = (e) => {
    setIsAllowedApi(e.target.checked);
  };
  const onIsLockedStatusVisible = (e) => {
    setIsLockedStatusVisible(e.target.checked);
  };

  const { user } = useSelector((state) => state.globalSlice);

  const onChangeLeads = (e) => {
    setSelectedLeadsOptions(e);
  };

  useEffect(() => {
    if (permissions) {
      setIsAllowedLogin(permissions.isAllowedLogin);
      setIsAllowedApi(permissions.isAllowedApi);
      setIsCanInject(permissions.isCanInject);
      form.setFieldsValue(permissions);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(permissions.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(permissions.updatedAt, user.timezone)
      );
      const selectedLeads = JSON.parse(permissions.columns).leads;
      form.setFieldValue('leads', selectedLeads);
      setSelectedLeadsOptions(selectedLeads);
    }
  }, [permissions]);


  const CopyButton = ({ id }) => {
    const navigate = useNavigate();
    return (
      <>
        <div>
          <Tooltip placement="bottom" title={f({id: 'tooltip_duplicate'})}>
            <Button 
              style={{ minWidth: '140px', marginTop: '15px' }} type="primary" success
              onClick={() => navigate(`/buyer-permissions/duplicate/${id}`)}
            >
              {f({id: 'duplicate'})}
              <CopyOutlined  className="text-base" />
            </Button>
          </Tooltip>
        </div>
      </>
    );
  };

  return (
    <>
      {hasPermissions(user, PERMISSIONS.PERMISSIONS_EDIT) && isExistingOrder && (
        <div>
          <DeleteButton entity={permissions} onFinish={onFinish} />
          <CopyButton id={permissions?.id}/>
        </div>
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(buyerPermissionsApi.util.resetApiState());
          onFinish({
            name: form.getFieldValue('name')?.trim(),
            leads: selectedLeadsOptions,
            isCanInject,
            isAllowedLogin,
            isAllowedApi,
            isLockedStatusVisible,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item name="isCanInject" label={f({ id: 'can_inject' })}>
          <Checkbox
              onClick={onCanInjectChange}
              defaultChecked={permissions?.isCanInject}
              rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="isAllowedLogin" label={f({ id: 'can_login' })}>
          <Checkbox
              onClick={onAllowedLogin}
              defaultChecked={permissions?.isAllowedLogin}
              rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="isAllowedApi" label={f({ id: 'can_use_api'})}>
          <Checkbox
              onClick={onAllowedApi}
              defaultChecked={permissions?.isAllowedApi}
              rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="isLockedStatusVisible" label={f({ id: 'is_locked_status_visible'})}>
          <Checkbox
              onClick={onIsLockedStatusVisible}
              defaultChecked={permissions?.isLockedStatusVisible}
              rootClassName={styles.input}
          />
        </Form.Item>
        <Divider orientation="center">{f({id: 'general'})}</Divider>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >

        </div>
        <Divider orientation="center">{f({id: 'columns'})}</Divider>
        <Row className="flex justify-center">
          <div className="flex">
            <div className="mr-2">{f({id: 'lead_menu'})}:</div>
            <Checkbox.Group
              value={selectedLeadsOptions}
              options={leadsOptions}
              onChange={onChangeLeads}
            />
          </div>
        </Row>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isDisabled}
            disabled={isDisabled}
            type="primary"
            htmlType="submit"
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
