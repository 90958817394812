import React, {useEffect, useState} from 'react';
import {fromApiToClient} from '../../../utils/dateFilters';
import {App, Button, Form, Input} from 'antd';
import styles from './TemplateIntegrationsDetails.module.css';
import Editor from '@monaco-editor/react';
import {checkStatusPattern, sendLeadPattern,} from '../../../utils/editorUtils';
import {templateIntegrationsApi} from '../templateIntegrationsApi';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from "react-intl";
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {hasPermissions} from "../../../hooks/usePermissionsAllowed";
import {PERMISSIONS} from "../../../utils/permissions";
import DeleteButton from "../../../shared/DeleteButton/DeleteButton";

const { TextArea } = Input;

export const TemplateIntegrationsDetails = ({ integration, isDisabled, onFinish }) => {
  const [sendLead, setSendLead] = useState(
    integration ? integration.sendLead : sendLeadPattern()
  );
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  const [checkStatus, setCheckStatus] = useState(
    integration ? integration.checkStatus : checkStatusPattern()
  );
  const [isActive, setIsActive] = useState(
    integration ? integration.isActive : true
  );
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});

  useEffect(() => {
    if (!integration) form.setFieldValue('isActive', true);
  }, [integration]);

  useEffect(() => {
    if (integration) {
      form.setFieldsValue(integration);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(integration.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(integration.updatedAt, user.timezone)
      );
      setIsActive(integration.isActive);
      setSendLead(integration.sendLead);
      setCheckStatus(integration.checkStatus);
    }
  }, [integration]);

  return (
    <>
      {hasPermissions(user, PERMISSIONS.INTEGRATIONS_EDIT) && (
          <DeleteButton entity={integration} onFinish={onFinish} />
      )}
      <Form
        form={form}
        onFinish={() => {
          if(sendLead.includes('console.warn') || sendLead.includes('console.err')) {
            message.error(f({id: 'dont_use_console_log'}));
            return false
          }
          dispatch(templateIntegrationsApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            sendLead,
            checkStatus,
            isActive,
            createdAt: undefined,
            updatedAt: undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({ id: 'title' })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({ id: 'title' })} rootClassName={styles.input} />
        </Form.Item>
        {integration && (
          <Form.Item name="createdAt" label={f({ id: 'created' })}>
            <Input
              disabled
              placeholder={f({ id: 'created' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {integration && (
          <Form.Item name="updatedAt" label={f({ id: 'updated' })}>
            <Input
              disabled
              placeholder={f({ id: 'updated' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}

        <div className={styles.editorLabel}>{f({ id: 'sending_lead' })}</div>
        <Form.Item
          className={styles.editor}
          wrapperCol={{ offset: 1 }}
          labelCol={{ span: 24 }}
          layout="vertical"
          name="sendLead"
        >
          <Editor
            options={{
              minimap: { enabled: true },
              scrollbar: {
                handleMouseWheel: false,
                alwaysConsumeMouseWheel: false,
              },
            }}
            height="80vh"
            width="70vw"
            theme="vs-dark"
            defaultLanguage="javascript"
            value={sendLead}
            onChange={setSendLead}
            defaultValue={sendLeadPattern()}
          />
        </Form.Item>

        <div className={styles.editorLabel}>{f({ id: 'check_status' })}</div>
        <Form.Item
          wrapperCol={{ offset: 1 }}
          labelCol={{ span: 24 }}
          layout="vertical"
          name="checkStatus"
        >
          <Editor
            options={{
              minimap: { enabled: true },
              scrollbar: {
                handleMouseWheel: false,
                alwaysConsumeMouseWheel: false,
              },
            }}
            height="80vh"
            width="70vw"
            theme="vs-dark"
            value={checkStatus}
            onChange={setCheckStatus}
            defaultLanguage="javascript"
            defaultValue={checkStatusPattern()}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isDisabled}
            disabled={isDisabled}
            type="primary"
            htmlType="submit"
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
    </>
  );
};
