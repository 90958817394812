export const permissionsColumnsBuyer = () => {
  return [
    'id',
    'name',
    'lastName',
    'email',
    'phone',
    'box_id',
    'order_id',
    'ip',
    'webIsDeposit',
    'webStatus',
    'webCallStatus',
    'callStatusHistory',
    'country',
    'sub1',
    'sub2',
    'sub3',
    'sub4',
    'sub5',
    'sub6',
    'sub7',
    'sub8',
    'utmCampaign',
    'utmSource',
    'utmContent',
    'utmMedium',
    'utmTerm',
    'platform',
    'url',
    'offerId',
    'responseDetail',
    'webDepositDate',
    'sentAt',
    'createdAt',
    'uuid',
    'isAutoLogin'
  ];
};

export const permissionsFiltersBuyer = () => {
  return [
    'id',
    'name',
    'lastName',
    'email',
    'phone',
    'country',
    'ip',
    'order',
    'webCallStatus',
    'webCallStatusHistory',
    'webIsDeposit',
    'webStatus',
    'offerId',
    'webDepositDate',
    'sentAt',
    'createdAt',
    'uuid',
    'isAutoLogin'
  ];
};

export const dashboardPermissionsFiltersBuyer = () => {
  return [
    'countries',
    'ip',
    'order',
    'box',
    'offerName',
    'createdAt',
    'webDepositDate',
    'depositAt',
    'sentAt',
    'webIsDeposit',
    'ReportsDateFilters',
  ];
};

export const permissionsPagesBuyer = () => {
  return ['leads', 'dashboard'];
};
