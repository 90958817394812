// store/slice.js
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {FormattedMessage} from "react-intl";
const initialState = {
  filters: {
    sentAtFrom: dayjs().startOf('week').toISOString(),
    sentAtTo: dayjs().endOf('week').toISOString(),
  },
  groupingsChart: [{ value: 'day', label: 'Дні' }],
  groupings: [{ value: 'buyer', label: 'Веб' }],
  groupingsFT: [{ value: 'buyer', label: 'Веб' }],
  filterBuilder: [],
  selectedThemeKey: localStorage.getItem('themeKey') || '1',
};
const reportSlice = createSlice({
  name: 'reports',
  initialState: initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setFiltersBuilder: (state, action) => {
      state.filterBuilder = action.payload;
    },
    setSelectedThemeKey: (state, action) => {
      state.selectedThemeKey = action.payload;
    },
    updateGroupings: (state, action) => {
      state.groupings = [
        ...state.groupings,
        { value: action.payload.value, label: action.payload.label },
      ];
    },
    updateGroupingsFT: (state, action) => {
      state.groupingsFT = [
        ...state.groupingsFT,
        { value: action.payload.value, label: action.payload.label },
      ];
    },
    removeGroupingsFT: (state, action) => {
      state.groupingsFT = [
        ...state.groupingsFT.filter((fg) => action.payload.value !== fg.value),
      ];
    },
    removeGroupings: (state, action) => {
      state.groupings = [
        ...state.groupings.filter((fg) => action.payload.value !== fg.value),
      ];
    },
    updateCharts: (state, action) => {
      state.groupingsChart = [
        { value: action.payload.value, label: action.payload.label },
      ];
    },
    removeDateFilter: (state, action) => {
      state.filters = Object.keys(state.filters).reduce((acc, curr) => {
        if (curr.includes('From') || curr.includes('To')) {
          return acc;
        }
        acc[curr] = state.filters[curr];
        return acc;
      }, {});
    },
    resetStateReports: () => {
      return initialState;
    },
  },
});

export const {
  updateFilters,
  removeDateFilter,
  setFilters,
  setFiltersBuilder,
  updateGroupings,
  updateCharts,
  removeGroupings,
  updateGroupingsFT,
  removeGroupingsFT,
  resetStateReports,
  setSelectedThemeKey,
} = reportSlice.actions;
export default reportSlice.reducer;
