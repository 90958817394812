import { Button, Checkbox, Form, Input } from 'antd';
import styles from './RegwayForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { regwayApi } from '../regwayApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const RegwayForm = ({ regway, isDisabled, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(regway ? regway.isActive : true);
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  useEffect(() => {
    if (!regway) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (regway) {
      form.setFieldsValue(regway);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(regway.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(regway.updatedAt, user.timezone)
      );
      setIsActive(regway.isActive);
    }
  }, [regway]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  return (
    <>
      {hasPermissions(user, PERMISSIONS.DOMAINS_EDIT) && (
        <DeleteButton entity={regway} onFinish={onFinish} />
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(regwayApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            name: form.getFieldValue('name')?.trim(),
            login: form.getFieldValue('login')?.trim(),
            password: form.getFieldValue('password')?.trim(),
            rId: form.getFieldValue('rId')?.trim(),
            rKey: form.getFieldValue('rKey')?.trim(),
            rUserId: form.getFieldValue('rUserId')?.trim(),
            rContactId: form.getFieldValue('rContactId')?.trim(),
            userId: regway ? regway?.userId : user?.id,
            createdAt: undefined,
            updatedAt: undefined,
            isActive,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="login"
          label={f({id: 'login'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'login'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="password"
          label={f({id: 'password'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'password'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="rId"
          label="Regway ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Regway ID" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="rKey"
          label="Regway Key"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Regway Key" rootClassName={styles.input} />
        </Form.Item>

        <Form.Item
          name="rUserId"
          label="Regway User ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Regway User ID" rootClassName={styles.input} />
        </Form.Item>

        <Form.Item
          name="rContactId"
          label="Regway Contact ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Regway Contact ID" rootClassName={styles.input} />
        </Form.Item>

        {regway && (
          <Form.Item name="createdAt" label={f({id: 'created'})}>
            <Input
              disabled
              placeholder={f({id: 'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {regway && (
          <Form.Item name="updatedAt" label={f({id: 'updated'})}>
            <Input
              disabled
              placeholder={f({id: 'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            disabled={isDisabled}
            loading={isDisabled}
            type="primary"
            htmlType="submit"
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
