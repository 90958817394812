import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const capsApi = createApi({
  reducerPath: 'capsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCaps: builder.query({
      query: ({ filters, currentPage, pageSize }) => {
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `cap?${url}`;
      },
    }),
  }),
  tagTypes: ['cap'],
});

export const {
  useGetCapsQuery,
  useLazyGetCapsQuery,
} = capsApi;
