import { Modal } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import TextArea from "antd/es/input/TextArea";

export const LogsDetailsModal = ({ data, isModalOpen, handleCancel }) => {
    const { formatMessage: f } = useIntl();

    const parsedData = JSON.parse(data);

    return (
        <Modal
            title={f({ id: 'details' })}
            open={isModalOpen}
            footer={null}
            className="w-1/2"
            width={parsedData.new ? 'max-content' : '500px'}
            onCancel={handleCancel}
        >
            {parsedData.new && (
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-evenly space-x-4">
                        <div className="w-max bg-red-100 p-4 rounded-lg max-h-64 overflow-auto">
                            <div className="font-bold">Old Values</div>
                            <pre className="text-red-600 break-words">
                            {JSON.stringify(parsedData.old, undefined, 2)}
                        </pre>
                        </div>
                        <div className="w-max bg-green-100 p-4 rounded-lg max-h-64 overflow-auto">
                            <div className="font-bold">New Values</div>
                            <pre className="text-green-600 break-words">
                            {JSON.stringify(parsedData.new, undefined, 2)}
                        </pre>
                        </div>
                    </div>
                </div>
            )}
            {!parsedData.new && (
                <TextArea
                    rows={30}
                    value={JSON.stringify(JSON.parse(data), undefined, 2)}
                    disabled
                />
            )}

        </Modal>
    );
};
