import {Button, Form, message, Modal, Popconfirm, Select, Tag} from 'antd';
import React, { useState } from 'react';
import {useIntl} from "react-intl";
import styles from "../../../pages/Orders/OrderForm/OrdersForm.module.css";

export const ChangeOrderStatusModal = ({
                                           rows,
                                           isModalOpen,
                                           handleCancel,
                                           onSuccess,
                                           changeOrderStatusModal,
                                           dispatch,
                                       }) => {
    const [form] = Form.useForm();
    const [status, setStatus] = useState('at_work');
    const [isLoading, setIsLoading] = useState(false);
    const { formatMessage: f } = useIntl();
    const onStatusUpdate = () => {
        setIsLoading(true);
            changeOrderStatusModal(
                {status: status,ids:rows.map((l) => l.id)}
            )
                .then(() => {
                    message.success(f({ id: 'status_changed' }));
                    onSuccess();
                })
                .catch((e) => message.error(e.response.data?.message))
                .finally(() => setIsLoading(false));
    };


    return (
        <Modal
            title={f({ id: 'change_status' })}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
        >
            <Form
                wrapperCol={{ span: 12, offset: 1 }}
                labelCol={{ span: 7, offset: 1 }}
                form={form}
            >
                <Form.Item
                    name="status"
                    label={f({id: 'status'})}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            { value: 'active', label: <Tag color="green">{f({id: 'at_work'})}</Tag>},
                            { value: 'pause', label: <Tag color="yellow">{f({id: 'on_pause'})}</Tag>}
                        ]}
                        onChange={(v) => setStatus(v)}
                        className={styles.input}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 11 }}>

                        <Button
                            loading={isLoading}
                            disabled={isLoading}
                            type="primary"
                            htmlType="submit"
                            onClick={onStatusUpdate}
                        >
                            {f({ id: 'change' })}
                        </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
