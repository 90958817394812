import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, Row} from 'antd';
import api from "../../../../api/api";
import {useIntl} from "react-intl";

// Функція для знаходження всіх змінних у коді
const findVariables = (code) => {
    const regex = /\$_[a-zA-Z_][a-zA-Z0-9_-]*/g;
    const matches = code.match(regex);
    return [...new Set(matches)].map(variable => variable.slice(2)); // Видалення префіксу $
};

// Функція для заміни змінних у коді
const replaceVariablesInCode = (code, replacements) => {
    const regex = /\$_[a-zA-Z_][a-zA-Z0-9_-]*/g;
    return code.replace(regex, (match) => {
        const key = match.slice(2);
        if (key in replacements) {
           return replacements[key];
        }
        return match;
    });
};

export const TemplateModal = ({ isOpen, handleCancel, defaultIntegrationId, onSuccess }) => {
    const [sendLead, setSendLead] = useState('');
    const [checkStatus, setCheckStatus] = useState('');
    const [variables, setVariables] = useState([]);
    const [form] = Form.useForm();
    const { formatMessage: f } = useIntl();

    useEffect(() => {
        const fetchIntegration = async () => {
            if (!defaultIntegrationId) return;
            const axiosResponse = await api.get(`template-integrations/${defaultIntegrationId}`);
            const sendLeadCode = axiosResponse.data.sendLead;
            const checkStatusCode = axiosResponse.data.checkStatus;
            setSendLead(sendLeadCode);
            setCheckStatus(checkStatusCode);

            const sendLeadVars = findVariables(sendLeadCode);
            const checkStatusVars = findVariables(checkStatusCode);
            const allVars = [...new Set([...sendLeadVars, ...checkStatusVars])];
            setVariables(allVars);

            const initialValues = allVars.reduce((acc, curr) => ({ ...acc, [curr]: '' }), {});
            form.setFieldsValue(initialValues);
        };

        fetchIntegration();
    }, [defaultIntegrationId, form]);

    const handleSave = () => {
        const values = form.getFieldsValue();
        const cleanedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = values[key].endsWith('/') ? values[key].slice(0, -1) : values[key];
            return acc;
        }, {});
        const replacedSendLead = replaceVariablesInCode(sendLead, cleanedValues);
        const replacedCheckStatus = replaceVariablesInCode(checkStatus, cleanedValues);
        onSuccess(replacedSendLead, replacedCheckStatus);
    };

    return (
        <Modal
            title={f({id: 'integration_data'})}
            open={isOpen}
            footer={null}
            onCancel={handleCancel}
        >
            <Form form={form} layout="vertical" onFinish={handleSave}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        {variables.slice(0, Math.ceil(variables.length / 2)).map((variable) => (
                            <Form.Item  required={true} key={variable} label={variable} name={variable}>
                                <Input placeholder={variable === 'brand_domain' || variable === 'customer_domain' ? 'https://domain.com' : ''} />
                            </Form.Item>
                        ))}
                    </Col>
                    <Col span={12}>
                        {variables.slice(Math.ceil(variables.length / 2)).map((variable) => (
                            <Form.Item required={true} key={variable} label={variable} name={variable}>
                                <Input placeholder={variable === 'brand_domain' || variable === 'customer_domain' ? 'https://domain.com' : ''} />
                            </Form.Item>
                        ))}
                    </Col>
                </Row>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        { f({id: 'save' })}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
