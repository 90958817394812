import { configureStore } from '@reduxjs/toolkit';
import { leadsApi } from '../pages/Leads/leadsApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import defaultPageReduser from './sharedSlices/defaultPageSlice';
import globalSliceReduser from './sharedSlices/globalSlice';
import leadsReduser from '../pages/Leads/leadsSlice';
import boxesReduser from '../pages/Boxes/boxesSlice';
import brandsReduser from '../pages/Brands/brandsSlice';
import campaignsReduser from '../pages/Campaign/campaignSlice';
import cloudflareReduser from '../pages/Cloudflare/cloudflareSlice';
import countryBoxReduser from '../pages/CountryBox/countryBoxSlice';
import customersReduser from '../pages/Customer/customersSlice';
import domainsReduser from '../pages/Domains/domainsSlice';
import entityLogsReduser from '../pages/EntityLogs/entityLogsSlice';
import groupsReduser from '../pages/Group/groupsSlice';
import logsReduser from '../pages/Logs/logsSlice';
import offersReduser from '../pages/Offer/offersSlice';
import ordersReduser from '../pages/Orders/ordersSlice';
import regwayReduser from '../pages/Regway/regwaySlice';
import serversReduser from '../pages/Servers/serversSlice';
import teamsReduser from '../pages/Teams/teamsSlice';
import usersReduser from '../pages/Users/usersSlice';
import permissionsReduser from '../pages/Permissions/permissionsSlice';
import integrationsReduser from '../pages/Integrations/integrationsSlice';
import callStatusGroupReduser from '../pages/CallStatusGroups/callStatusGroupsSlice';
import dashboardReduser from '../pages/Dashboard/dashboardSlice';
import reportReducer from '../pages/Report/reportsSlice';
import capsReduser from '../pages/Caps/capsSlice';
import currentEditItemReduser from './sharedSlices/currentEditItemSlice';
import blacklistReducer from '../pages/BlackList/blacklistsSlice';
import namecheapsReduser from '../pages/Namecheap/namecheapSlice';
import reportKpiReducer from '../pages/KpiReport/reportsSlice';
import directionsReducer from '../pages/Direction/directionsSlice';
import buyerPermissionsReducer from '../pages/BuyerPermissions/permissionsSlice';
import { boxesApi } from '../pages/Boxes/boxesApi';
import { ordersApi } from '../pages/Orders/ordersApi';
import { customersApi } from '../pages/Customer/customersApi';
import { countryBoxApi } from '../pages/CountryBox/countryBoxApi';
import { integrationsApi } from '../pages/Integrations/integrationsApi';
import { callStatusGroupApi } from '../pages/CallStatusGroups/callStatusGroupsApi';
import { permissionsApi } from '../pages/Permissions/permissionsApi';
import { usersApi } from '../pages/Users/usersApi';
import { teamsApi } from '../pages/Teams/teamsApi';
import { logsApi } from '../pages/Logs/logsApi';
import { entityLogsApi } from '../pages/EntityLogs/entityLogsApi';
import { domainsApi } from '../pages/Domains/domainsApi';
import { cloudflareApi } from '../pages/Cloudflare/cloudflareApi';
import { regwayApi } from '../pages/Regway/regwayApi';
import { serversApi } from '../pages/Servers/serversApi';
import { dashboardApi } from '../pages/Dashboard/dashboardApi';
import { offersApi } from '../pages/Offer/offersApi';
import { groupsApi } from '../pages/Group/groupsApi';
import { reportsApi } from '../pages/Report/reportsApi';
import { sessionsApi } from '../layout/Layout/AppLayout/sessionsApi';
import { brandsApi } from '../pages/Brands/brandsApi';
import { buyersApi } from '../pages/Buyers/buyersApi';
import { capsApi } from '../pages/Caps/capsApi';
import buyersReduser from '../pages/Buyers/buyersSlice';
import { callStatusCategoriesApi } from '../pages/CallStatusCategories/callStatusCategoriesApi';
import { blacklistsApi } from '../pages/BlackList/blacklistsApi';
import {namecheapApi} from '../pages/Namecheap/namecheapApi';
import { globalApi } from './sharedSlices/globalApi';
import { kpiApi } from '../pages/Kpi/kpiApi';
import { reportsKpiApi } from '../pages/KpiReport/reportsApi';
import {templateIntegrationsApi} from "../pages/TemplateIntegrations/templateIntegrationsApi";
import {directionsApi} from "../pages/Direction/directionsApi";
import {buyerPermissionsApi} from "../pages/BuyerPermissions/permissionsApi";

export const store = configureStore({
  reducer: {
    [leadsApi.reducerPath]: leadsApi.reducer,
    [boxesApi.reducerPath]: boxesApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [countryBoxApi.reducerPath]: countryBoxApi.reducer,
    [integrationsApi.reducerPath]: integrationsApi.reducer,
    [callStatusGroupApi.reducerPath]: callStatusGroupApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [teamsApi.reducerPath]: teamsApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [entityLogsApi.reducerPath]: entityLogsApi.reducer,
    [domainsApi.reducerPath]: domainsApi.reducer,
    [cloudflareApi.reducerPath]: cloudflareApi.reducer,
    [regwayApi.reducerPath]: regwayApi.reducer,
    [serversApi.reducerPath]: serversApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [offersApi.reducerPath]: offersApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [brandsApi.reducerPath]: brandsApi.reducer,
    [buyersApi.reducerPath]: buyersApi.reducer,
    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [namecheapApi.reducerPath]: namecheapApi.reducer,
    [callStatusCategoriesApi.reducerPath]: callStatusCategoriesApi.reducer,
    [blacklistsApi.reducerPath]: blacklistsApi.reducer,
    [globalApi.reducerPath]: globalApi.reducer,
    [kpiApi.reducerPath]: kpiApi.reducer,
    [reportsKpiApi.reducerPath]: reportsKpiApi.reducer,
    [capsApi.reducerPath]: capsApi.reducer,
    [templateIntegrationsApi.reducerPath]: templateIntegrationsApi.reducer,
    [directionsApi.reducerPath]: directionsApi.reducer,
    [buyerPermissionsApi.reducerPath]: buyerPermissionsApi.reducer,
    defaultPage: defaultPageReduser,
    globalSlice: globalSliceReduser,
    leads: leadsReduser,
    boxes: boxesReduser,
    brands: brandsReduser,
    cloudflare: cloudflareReduser,
    countryBox: countryBoxReduser,
    customer: customersReduser,
    domains: domainsReduser,
    entityLogs: entityLogsReduser,
    groups: groupsReduser,
    logs: logsReduser,
    offers: offersReduser,
    orders: ordersReduser,
    permissions: permissionsReduser,
    regway: regwayReduser,
    servers: serversReduser,
    teams: teamsReduser,
    users: usersReduser,
    integrations: integrationsReduser,
    callStatusGroups: callStatusGroupReduser,
    campaigns: campaignsReduser,
    dashboard: dashboardReduser,
    currentEditItem: currentEditItemReduser,
    reports: reportReducer,
    caps: capsReduser,
    buyers: buyersReduser,
    blacklist: blacklistReducer,
    namecheap: namecheapsReduser,
    reportKpi: reportKpiReducer,
    directions: directionsReducer,
    buyerPermissions: buyerPermissionsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      leadsApi.middleware,
      boxesApi.middleware,
      ordersApi.middleware,
      customersApi.middleware,
      countryBoxApi.middleware,
      integrationsApi.middleware,
      callStatusGroupApi.middleware,
      permissionsApi.middleware,
      usersApi.middleware,
      logsApi.middleware,
      entityLogsApi.middleware,
      domainsApi.middleware,
      cloudflareApi.middleware,
      regwayApi.middleware,
      dashboardApi.middleware,
      teamsApi.middleware,
      serversApi.middleware,
      offersApi.middleware,
      groupsApi.middleware,
      reportsApi.middleware,
      brandsApi.middleware,
      sessionsApi.middleware,
      buyersApi.middleware,
      callStatusCategoriesApi.middleware,
      blacklistsApi.middleware,
      namecheapApi.middleware,
      globalApi.middleware,
      capsApi.middleware,
      kpiApi.middleware,
      reportsKpiApi.middleware,
      templateIntegrationsApi.middleware,
      directionsApi.middleware,
      buyerPermissionsApi.middleware,
    ),
});

setupListeners(store.dispatch);
