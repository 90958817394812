import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { PermissionsForm } from '../PermissionsForm/PermissionsForm';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const EditBuyerPermissions = ({drawerId}) => {
  const { message } = App.useApp();
  const [permissions, setPermissions] = useState();
  const { id } = useParams();
  usePermissionsAllowed(PERMISSIONS.PERMISSIONS_EDIT);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const { formatMessage: f } = useIntl();
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    api.get(`/buyer-permissions/${reqId}`).then((res) => {
      setPermissions({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedPermissions) => {
    setIsDisabled(true);
    api
      .put(`/buyer-permissions/${reqId}`, {
        ...updatedPermissions,
      })
      .then((_) => {
        if(!drawerId) {
          navigate(-1);
        }
        message.success(f({id: 'permissions_added'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  return (
    <PermissionsForm
      isDisabled={isDisabled}
      onFinish={onFinish}
      permissions={permissions}
      isExistingOrder={true}
    />
  );
};
