import { Checkbox, Col, Modal, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsFiltersModalOpen } from '../store/sharedSlices/defaultPageSlice';
import { snakeToCamel } from '../utils/snakeToCamel';
import {
  permissionsFiltersBuyer,
  dashboardPermissionsFiltersBuyer,
} from '../utils/permissionsBuyer';
import { useLocation } from 'react-router-dom';
import { useIntl } from "react-intl";

export const FiltersProvider = ({
  children,
  hiddenFilters,
  onHiddenFiltersChange,
  dataPrefix,
}) => {
  let { pathname } = useLocation();
  const isModalOpen = useSelector(
    (state) => state.defaultPage.isFiltersModalOpen
  );
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  if (!user) return 'loading';
  let allowedColumnsFromUser;
  if (user.type !== 'buyer') {
    allowedColumnsFromUser = user?.permissions?.columns[dataPrefix];

  } else {
    allowedColumnsFromUser =
      pathname === '/dashboard'
        ? dashboardPermissionsFiltersBuyer()
        : permissionsFiltersBuyer();
  }
  let childrenAllowedToUser = children;

  if (allowedColumnsFromUser) {
    const transformed = allowedColumnsFromUser
      .map(snakeToCamel)
      .map((f) => (f.endsWith('Id') ? f.slice(0, f.length - 2) : f));
    childrenAllowedToUser = children.filter((c) =>
      transformed.find((af) => {
        const childrenName = ['createdatfrom', 'createdatto'].includes(
          c?.props?.name?.toLowerCase()
        )
          ? 'createdat'
          : c?.type?.name?.toLowerCase() === 'reportsdatefilters'
          ? 'reportsdatefilters'
          : c?.props?.name?.toLowerCase();
        return af.toLowerCase().includes(childrenName);
      })
    );
  }

  return (
    <>
      <Modal
        title={f({ id: 'show_filters' })}
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          dispatch(updateIsFiltersModalOpen(false));
        }}
      >
        <Col>
          {childrenAllowedToUser.map((f, index) => {
            return (
              <Row key={index}>
                <Checkbox
                  defaultChecked={
                    !hiddenFilters?.find((af) => af === f?.props?.name)
                  }
                  onChange={(e) => {
                    let newHiddenArray = [...hiddenFilters];
                    if (e.target.checked) {
                      newHiddenArray = hiddenFilters?.filter(
                        (hf) => hf !== f?.props.name
                      );
                    } else {
                      newHiddenArray.push(f?.props.name);
                    }
                    onHiddenFiltersChange(newHiddenArray);
                    localStorage.setItem(
                      `${dataPrefix}_filters_hidden`,
                      JSON.stringify(newHiddenArray)
                    );
                  }}
                >
                  {f?.props?.label}
                </Checkbox>
              </Row>
            );
          })}
        </Col>
      </Modal>
      {childrenAllowedToUser.filter(
        (c) => !hiddenFilters?.includes(c.props?.name)
      )}
    </>
  );
};
