import { Form, Input } from 'antd';
import styles from '../Table/AppTable.module.css';
import { updateFilters } from '../../store/sharedSlices/defaultPageSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFilterContext } from '../../hooks/useFilterContext';
import debounce from 'lodash.debounce';

export const InputStrFilters = ({
  label,
  name,
  type,
  updateTypeFilters = updateFilters,
}) => {
  const dispatch = useDispatch();
  const [querystr, setSearchParams] = useFilterContext();
  return (
    <>
      <Form.Item key={name} label={label} name={name} className={styles.filter}>
        <Input
          placeholder={label}
          defaultValue={querystr?.hasOwnProperty(type) ? querystr[type][0] : ''}
          onChange={debounce((e) => {
            dispatch(
              updateTypeFilters({
                name: type,
                value: `$ilike:${e.target.value}`,
              })
            );
            setSearchParams({
              filters: JSON.stringify({
                ...querystr,
                [type]: [e.target.value, type, `$ilike:${e.target.value}`],
              }),
            });
          }, 500)}
          style={{ width: '160px' }}
        />
      </Form.Item>
    </>
  );
};
