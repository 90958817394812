import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const blacklistsApi = createApi({
  reducerPath: 'blacklistsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getBlacklists: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `blacklist?${url}${sort}`;
      },
    }),
    getBlacklist: builder.query({
      query: (id) => `blacklist/${id}`,
    }),
    updateBlacklist: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `blacklist/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
    updateResponsible: builder.mutation({
      query: (patch) => ({
        url: `blacklist/responsible`,
        method: 'PUT',
        body: patch,
      }),
    }),
  }),
  tagTypes: ['blacklist'],
});

export const {
  useGetBlacklistsQuery,
  useGetBlacklistQuery,
  useUpdateBlacklistMutation,
  useUpdateResponsibleMutation,
  useLazyGetBlacklistsQuery,
} = blacklistsApi;
