import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageSize: Number(JSON.parse(localStorage.getItem('pageSize'))?.namecheap) || 10,
};

export const namecheapSlice = createSlice({
  name: 'namecheap',
  initialState,
  reducers: {
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
      const storageItemsCount = JSON.parse(localStorage.getItem('pageSize'));
      localStorage.setItem(
        'pageSize',
        JSON.stringify({ ...storageItemsCount, namecheap: action.payload })
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageSize } = namecheapSlice.actions;

export default namecheapSlice.reducer;
