import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const callStatusGroupApi = createApi({
  reducerPath: 'callStatusGroupApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCallStatusGroups: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `call-status-group?${url}${sort}`;
      },
    }),
    getCallStatusGroup: builder.query({
      query: (id) => `call-status-group/${id}`,
    }),
    onDeleteCallStatus: builder.mutation({
      query: (id) => ({
        url: `call-status/${id}`,
        method: 'DELETE',
      }),
    }),
    updateResponsible: builder.mutation({
      query: (patch) => ({
        url: `call-status-group/responsible`,
        method: 'PUT',
        body: patch,
      }),
    }),
  }),
  tagTypes: ['customer'],
});

export const {
  useGetCallStatusGroupsQuery,
  useGetCallStatusGroupQuery,
  useOnDeleteCallStatusMutation,
  useLazyGetCallStatusGroupsQuery,
  useUpdateResponsibleMutation,
} = callStatusGroupApi;
