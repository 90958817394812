import React from 'react';
import { Input } from 'antd';
import { useIntl } from "react-intl";

const { TextArea } = Input;

export const AreaInput = ({ defaultValue, onChange }) => {
  const { formatMessage: f } = useIntl();
  return (
    <TextArea
      rows={2}
      placeholder={f({ id: 'comment' })}
      style={{ resize: 'none', width: '100px' }}
      defaultValue={defaultValue}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
