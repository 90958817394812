import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';
function DebounceSelect({
  fetchOptions,
  debounceTimeout = 400,
  initialFetchOptions,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const initialLoad = () => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);
    initialFetchOptions().then((newOptions) => {
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }
      setOptions(newOptions);
      setFetching(false);
    });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      showSearch
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      onFocus={initialLoad}
      onBlur={() => setOptions([])}
      options={options}
    />
  );
}

export const AutocompleteSelect = ({
  fetch,
  initialFetch,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  return (
    <DebounceSelect
      value={value}
      placeholder={placeholder}
      fetchOptions={fetch}
      initialFetchOptions={initialFetch}
      onChange={onChange}
      {...props}
    />
  );
};
