import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const entityLogsApi = createApi({
  reducerPath: 'entityLogsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getEntityLogs: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize);
        return `entity-log?${url}${sort}`;
      },
    }),
    getEntityLogById: builder.query({
       query: ({id, entity}) => {
         return `entity-log/entities/${entity}/${id}`
       },
    }),
    getEntityLog: builder.query({
      query: (id) => `entity-log/${id}`,
    }),
  }),
  tagTypes: ['entityLogs'],
});

export const { useGetEntityLogsQuery, useGetEntityLogQuery, useLazyGetEntityLogByIdQuery } = entityLogsApi;
