export const PERMISSIONS = {
  LEADS_READ: 'leads:read',
  LEADS_EDIT: 'leads:edit',
  ORDERS_READ: 'orders:read',
  ORDERS_EDIT: 'orders:edit',
  BOXES_READ: 'boxes:read',
  BOXES_EDIT: 'boxes:edit',
  USERS_READ: 'users:read',
  USERS_EDIT: 'users:edit',
  INTEGRATIONS_READ: 'integrations:read',
  INTEGRATIONS_EDIT: 'integrations:edit',
  COUNTRY_BOXES_READ: 'countryBoxes:read',
  COUNTRY_BOXES_EDIT: 'countryBoxes:edit',
  CALL_STATUSES_READ: 'callStatuses:read',
  CALL_STATUSES_EDIT: 'callStatuses:edit',
  CALL_STATUSES_GROUP_READ: 'callStatusesGroup:read',
  CALL_STATUSES_GROUP_EDIT: 'callStatusesGroup:edit',
  CUSTOMERS_READ: 'customers:read',
  CUSTOMERS_EDIT: 'customers:edit',
  TEAMS_READ: 'teams:read',
  TEAMS_EDIT: 'teams:edit',
  LOGS_READ: 'logs:read',
  LOGS_EDIT: 'logs:edit',
  BRANDS_READ: 'brands:read',
  BRANDS_EDIT: 'brands:edit',
  DOMAINS_READ: 'domains:read',
  DOMAINS_EDIT: 'domains:edit',
  OFFERS_READ: 'offers:read',
  OFFERS_EDIT: 'offers:edit',
  PERMISSIONS_READ: 'permissions:read',
  PERMISSIONS_EDIT: 'permissions:edit',
  BUYERS_READ: 'buyers:read',
  BUYERS_EDIT: 'buyers:edit',
  BLACKLISTS_READ: 'blacklists:read',
  BLACKLISTS_EDIT: 'blacklists:edit',
  KPI_EDIT: 'kpi:edit',
};
