import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { CallStatusGroupForm } from '../CallStatusGroupForm/CallStatusGroupForm';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const EditCallStatusGroups = ({drawerId}) => {
  const { formatMessage: f } = useIntl();
  const { message } = App.useApp();
  const [callStatusGroup, setCallStatusGroup] = useState();
  const { id } = useParams();
  usePermissionsAllowed(PERMISSIONS.CALL_STATUSES_GROUP_EDIT);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    getData();
  }, []);

  useDidUpdateEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    setIsLoading(true);
    api.get(`/call-status-group/${reqId}`).then((res) => {
      setCallStatusGroup(res.data);
      setIsLoading(false);
    });
  };

  const onFinish = (updatedBox) => {
    setIsDisabled(true);
    api
      .put(`/call-status-group/${reqId}`, {
        ...updatedBox,
      })
      .then((_) => {
        if(!drawerId) {
          navigate(-1);
        }
        message.success(f({id: 'group_call_status_updated'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  return (
    <CallStatusGroupForm
      onFinish={onFinish}
      isDisabled={isDisabled}
      callStatusGroup={callStatusGroup}
      isDefault={callStatusGroup.isDefault}
      onRefresh={getData}
    />
  );
};
