import { Button, Form, Input } from 'antd';
import styles from './ServersForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { useDispatch, useSelector } from 'react-redux';
import { serversApi } from '../serversApi';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const ServersForm = ({ server, isDisabled, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(server ? server.isActive : true);
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  useEffect(() => {
    if (!server) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (server) {
      form.setFieldsValue(server);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(server.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(server.updatedAt, user.timezone)
      );
      setIsActive(server.isActive);
    }
  }, [server]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  return (
    <>
      {hasPermissions(user, PERMISSIONS.DOMAINS_EDIT) && (
        <DeleteButton entity={server} onFinish={onFinish} />
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(serversApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            name: form.getFieldValue('name')?.trim(),
            login: form.getFieldValue('login')?.trim(),
            password: form.getFieldValue('password')?.trim(),
            keitaroKey: form.getFieldValue('keitaroKey')?.trim(),
            ip: form.getFieldValue('ip')?.trim(),
            userId: server ? server?.userId : user?.id,
            createdAt: undefined,
            updatedAt: undefined,
            isActive,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="login"
          label={f({id: 'login'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'login'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="password"
          label={f({id: 'password'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'password'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="ip"
          label="IP"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="IP" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item name="keitaroKey" label="Keitaro Key">
          <Input placeholder="Keitaro Key" rootClassName={styles.input} />
        </Form.Item>

        {server && (
          <Form.Item name="createdAt" label={f({id: 'created'})}>
            <Input
              disabled
              placeholder={f({id: 'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {server && (
          <Form.Item name="updatedAt" label={f({id: 'updated'})}>
            <Input
              disabled
              placeholder={f({id: 'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            disabled={isDisabled}
            loading={isDisabled}
            type="primary"
            htmlType="submit"
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
