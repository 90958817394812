import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageSize: Number(JSON.parse(localStorage.getItem('pageSize'))?.group) || 10,
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
      const storageItemsCount = JSON.parse(localStorage.getItem('pageSize'));
      localStorage.setItem(
        'pageSize',
        JSON.stringify({ ...storageItemsCount, group: action.payload })
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageSize } = groupsSlice.actions;

export default groupsSlice.reducer;
