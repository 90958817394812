import React from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import {FormattedMessage, useIntl} from "react-intl";

export const BuyersInput = ({ defaultValue, onChange }) => {
  const { formatMessage: f } = useIntl();
  const fetchUsers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/buyer?filter.nickname=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };
  const initialFetchUsers = async () => {
    const res = await api.get(`/buyer?limit=10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  return (
    <AutocompleteSelect
      mode="multiple"
      style={{
        width: '130px',
      }}
      placeholder={f({ id: 'executors' })}
      initialFetch={initialFetchUsers}
      fetch={fetchUsers}
      onChange={(e) => onChange(e.map((v) => v.value))}
      defaultValue={defaultValue.map((u) => ({
        value: u.id,
        label: `#${u.id} ${u.nickname}`,
      }))}
    />
  );
};
