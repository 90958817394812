// eslint-disable-next-line
export default (headers) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }
  headers.set(
    'x-user-timezone',
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  return headers;
};
