import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const templateIntegrationsApi = createApi({
  reducerPath: 'templateIntegrationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getTemplateIntegrations: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `template-integrations?${url}${sort}`;
      },
    })
  }),
  tagTypes: ['template-integrations'],
});

export const {
  useLazyGetTemplateIntegrationsQuery,
  useUpdateResponsibleMutation,
} = templateIntegrationsApi;
