import { Input, InputNumber, Form } from 'antd';
import { UsersInput } from './inputFields/UsersInput';
import { CustomerInput } from './inputFields/CustomerInput';
import { DateInput } from './inputFields/DateInput';
import { CountriesInput } from './inputFields/CountriesInput';
import { PaymentTypeInput } from './inputFields/PaymentTypeInput';
import { PaymentFormInput } from './inputFields/PaymentFormInput';
import { AreaInput } from './inputFields/AreaInput';
import { cloneElement } from 'react';
import { useDispatch } from 'react-redux';
import { updateItem } from '../../store/sharedSlices/currentEditItemSlice';
import { IntegrationInput } from './inputFields/IntegrationInput';
import { CountryBoxInput } from './inputFields/CountryBoxInput';
import { CallStatusGroupInput } from './inputFields/CallStatusGroupInput';
import { CountryBoxOrderInput } from '../../pages/Orders/Orders/tableInputs/CountryBoxOrderInput';
import { CountriesOrderInput } from '../../pages/Orders/Orders/tableInputs/CountriesOrderInput';
import { CheckboxInput } from './inputFields/CheckboxInput';
import { CountryInput } from './inputFields/CountryInput';
import { RoleInput } from './inputFields/RoleInput';
import { PermissionInput } from './inputFields/PermissionInput';
import { TeamInput } from './inputFields/TeamInput';
import { BuyersInput } from './inputFields/BuyersInput';
import {IpInput} from "./inputFields/IpInput";

export const EditableCellGlobalForm = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  defaultValue,
  form,
  ...restProps
}) => {
  let inputNode;

  const dispatch = useDispatch();

  const onChange = (e) => {
    form.setFieldValue(dataIndex, e?.target ? e.target.value : e);
    dispatch(
      updateItem({ key: dataIndex, value: e?.target ? e.target.value : e })
    );
  };

  switch (inputType) {
    case 'number': {
      inputNode = <InputNumber />;
      break;
    }
    case 'users': {
      inputNode = <UsersInput />;
      break;
    }
    case 'user': {
      inputNode = <UsersInput />;
      break;
    }
    case 'buyers': {
      inputNode = <BuyersInput />;
      break;
    }
    case 'customer': {
      inputNode = <CustomerInput />;
      break;
    }
    case 'date': {
      inputNode = <DateInput />;
      break;
    }
    case 'ip': {
      inputNode = <IpInput />;
      break;
    }
    case 'countries': {
      inputNode = <CountriesInput />;
      break;
    }
    case 'country': {
      inputNode = <CountryInput />;
      break;
    }
    case 'paymentType': {
      inputNode = <PaymentTypeInput />;
      break;
    }
    case 'paymentForm': {
      inputNode = <PaymentFormInput />;
      break;
    }
    case 'area': {
      inputNode = <AreaInput />;
      break;
    }
    case 'integration': {
      inputNode = <IntegrationInput />;
      break;
    }
    case 'countryBox': {
      inputNode = <CountryBoxInput />;
      break;
    }
    case 'callStatusGroup': {
      inputNode = <CallStatusGroupInput />;
      break;
    }
    case 'countryBoxOrder': {
      inputNode = <CountryBoxOrderInput />;
      break;
    }
    case 'countriesOrder': {
      inputNode = <CountriesOrderInput />;
      break;
    }
    case 'checkbox': {
      inputNode = <CheckboxInput />;
      break;
    }
    case 'role': {
      inputNode = <RoleInput />;
      break;
    }
    case 'permission': {
      inputNode = <PermissionInput />;
      break;
    }
    case 'team': {
      inputNode = <TeamInput />;
      break;
    }
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {cloneElement(inputNode, { defaultValue, onChange })}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
