// store/slice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFiltersModalOpen: false,
  filters: {},
  currentPage: 1,
  pageSize: 10,
  totalItems: 0,
  sorter: '',
  editingKey: '',
};

const defaultPageSlice = createSlice({
  name: 'defaultPage',
  initialState: {
    ...initialState,
    isInitialLoad: true,
  },
  reducers: {
    updateIsFiltersModalOpen: (state, action) => {
      state.isFiltersModalOpen = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
      state.currentPage = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.currentPage = 1;
    },
    setIsInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setSorter: (state, action) => {
      state.sorter = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setEditingKey: (state, action) => {
      state.editingKey = action.payload;
    },
    removeDateFilter: (state, action) => {
      state.filters = Object.keys(state.filters).reduce((acc, curr) => {
        if (curr.includes('At')) {
          return acc;
        }
        acc[curr] = state.filters[curr];
        return acc;
      }, {});
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const {
  updateIsFiltersModalOpen,
  updateFilters,
  setFilters,
  setSorter,
  setCurrentPage,
  setPageSize,
  resetState,
  setEditingKey,
  removeDateFilter,
  setIsInitialLoad,
} = defaultPageSlice.actions;
export default defaultPageSlice.reducer;
