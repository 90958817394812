import { Button, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import api from '../../../api/api';
import styles from './CallStatusCategoryChildren.module.css';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { OneColumn } from '../OneColumn/OneColumn';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { ScreenBlock } from '../../../shared/Modals/ScreenBlock';
import {useIntl} from "react-intl";

export const CallStatusCategoryChildren = () => {
  const { parentId } = useParams();
  const { formatMessage: f } = useIntl();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [currentCategory, setCurrentCategory] = useState();
  const navigate = useNavigate();
  const { isDarkTheme } = useContext(ThemeContext);
  const [isScreenBlocked, setIsScreenBlocked] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.get(`/call-status-category/children/${parentId}`).then((res) => {
      setData(res.data);
      window.scrollTo(0, 0);
    });
  };

  useEffect(() => {
    const categories = {};
    data.forEach((category) => {
      const isParentCategory = category.id === parseInt(parentId);
      if (isParentCategory) setCurrentCategory(category);
      categories[category.id] = {
        name: isParentCategory ? f({ id: 'without_subcategory' }) : category.name,
        items: category.callStatuses.map((s) => ({
          ...s,
          id: s.id.toString(),
        })),
        color: isParentCategory ? '#e9e9e9' : category.color,
        isDefault: isParentCategory,
      };
    });
    setColumns(categories);
  }, [data]);

  const onDelete = (id) => {
    api.delete(`/call-status-category/${id}`).then(() => {
      getData();
      window.location.reload();
    });
  };

  const updateStatus = (id, callSatusCategoryId) => {
    return new Promise((resolve, reject) => {
      api
        .put(`/call-status/${id}`, {
          call_status_category_id: callSatusCategoryId,
        })
        .then((_) => {
          message.success(f({ id: 'call_status_updated'}));
          resolve();
        })
        .catch((e) => {
          message.error(e.response.data.message);
          reject();
        });
    });
  };

  const onDragEnd = async (result, columns, setColumns) => {
    setIsScreenBlocked(true);
    try {
      if (!result.destination) return;
      const { source, destination } = result;
      if (source.droppableId !== destination.droppableId) {
        try {
          await updateStatus(
            result.draggableId,
            result.destination.droppableId
          );
          getData();
          const sourceColumn = columns[source.droppableId];
          const destColumn = columns[destination.droppableId];
          const sourceItems = [...sourceColumn.items];
          const destItems = [...destColumn.items];
          const [removed] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, removed);
          setColumns({
            ...columns,
            [source.droppableId]: {
              ...sourceColumn,
              items: sourceItems,
            },
            [destination.droppableId]: {
              ...destColumn,
              items: destItems,
            },
          });
        } catch (e) {}
      } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        });
      }
    } finally {
      setIsScreenBlocked(false);
    }
  };

  return (
    <div>
      <div className={styles.categoryTitleBox}>
        <div className={styles.btnsGroup}>
          <Button
            className={styles.backArrow}
            type="primary"
            onClick={() => navigate('/call-status-categories')}
          >
            <ArrowLeftOutlined />
          </Button>
          <Button
            type="primary"
            onClick={() =>
              navigate(`/call-status-categories/children/create/${parentId}`)
            }
          >
            <PlusOutlined />
          </Button>
        </div>
      </div>
      <h1
        style={{ color: isDarkTheme ? 'white' : 'black' }}
        className={styles.categoryTitle}
      >
        {currentCategory?.name}
      </h1>

      <div
        style={{ display: 'flex', justifyContent: 'center', height: '100%' }}
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.keys(columns).length && (
            <span className={styles.oneColumnWraper}>
              <OneColumn
                columnId={Object.keys(columns)[0]}
                column={Object.values(columns)[0]}
                child={true}
              />
            </span>
          )}
          <div className={styles.scrollContent}>
            {Object.entries(columns).map(
              ([columnId, column], index) =>
                columnId !== Object.keys(columns)[0] && (
                  <OneColumn
                    columnId={columnId}
                    column={column}
                    onDelete={onDelete}
                    child={true}
                  />
                )
            )}
          </div>
        </DragDropContext>
      </div>
      <ScreenBlock isOpen={isScreenBlocked}></ScreenBlock>
    </div>
  );
};
