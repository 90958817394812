import { CountrySelect } from '../../../shared/CountryMultiselect/CountrySelect';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select, Tag,
  TimePicker,
  Tooltip,
} from 'antd';
import styles from './OrdersForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../../api/api';
import dayjs from 'dayjs';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { FormFieldRestricter } from '../../../shared/FormFieldRestricter/FormFieldRestricter';
import { ordersApi, useLazyGetOrdersQuery, useLazyGetSharedByOrderQuery } from '../ordersApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseOutlined,
  CopyOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import ShareButton from '../../../shared/ShareButton/ShareButton';
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {PermissionalButtonDrawer} from "../../../shared/PermissionalLink/PermissionalLink";
import {LanguageSelect} from "../../../shared/LanguageMultiselect/LanguageSelect";
import {LogsInEntity} from "../../../shared/LogsInEntity/LogsInEntity";

const { TextArea } = Input;
export const OrdersForm = ({ order, isDisabled, onFinish }) => {
  const [form] = Form.useForm();

  const { user } = useSelector((state) => state.globalSlice);

  const [trigger] = useLazyGetSharedByOrderQuery();

  const [isActive, setIsActive] = useState(order ? order.isActive : true);
  const [shared, setShared] = useState();
  const [isAllowDuplicates, setIsAllowDuplicates] = useState(
    order ? order.isAllowDuplicates : true
  );
  const [isUseFakeCs, setIsUseFakeCs] = useState(
      order ? order.isUseFakeCs : false
  );
  const [useManualApprovalDeposit, setUseManualApprovalDeposit] = useState(
    order ? order.useManualApprovalDeposit : true
  );
  const [paymentType, setPaymentType] = useState();
  const [status, setStatus] = useState('active');
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [callStatusGroup, setCallStatusGroup] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedCountryBox, setSelectedCountryBox] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [timezonesList, setTimezonesList] = useState([]);
  const [funnels, setFunnels] = useState([]);
  const [scheduleError, setScheduleError] = useState(false);
  const [timezone, setTimezone] = useState(
    order ? JSON.parse(order.callCenterSchedule)?.timezone : 'Europe/Kyiv'
  );
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const [daysCopy, setDaysCopy] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});
  const [days, setDays] = useState(
    order
      ? JSON.parse(order.callCenterSchedule).days
      : [
          { label: 'monday' },
          { label: 'tuesday' },
          { label: 'wednesday'},
          { label: 'thursday' },
          { label: 'friday' },
          { label: 'saturday' },
          { label: 'sunday' },
        ]
  );

  const refetchShared = () => {
    trigger(order.id)
      .then(res => {
        setShared(res.data)
      })
  }

  useEffect(() => {
    if (!order) form.setFieldValue('isActive', true);
    api.get(`/timezones`).then((res) => {
      setTimezonesList(res.data);
    });
    if (order) {
      refetchShared();
    }
  }, []);

  useEffect(() => {
    if (order && user) {
      form.setFieldsValue(order);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(order.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(order.updatedAt, user.timezone)
      );
      form.setFieldValue('countries', JSON.parse(order.countries));
      form.setFieldValue('customer', {
        label: `#${order.customer.id} ${order.customer.name}`,
        value: order.customer.id,
        key: order.customer.id,
      });
      form.setFieldValue('integration', {
        label: order.integration
          ? `#${order.integration?.id} ${order.integration?.name}`
          : null,
        value: order.integration?.id,
        key: order.integration?.id,
      });
      form.setFieldValue('callStatusGroup', {
        label: `#${order.callStatusGroup?.id} ${order.callStatusGroup?.name}`,
        value: order.callStatusGroup?.id,
        key: order.callStatusGroup?.id,
      });
      form.setFieldValue('countryBox', {
        value: order.countryBox?.id,
        label: order.countryBox
          ? `#${order.countryBox?.id} ${order.countryBox?.name}`
          : null,
      });
      if (order?.user?.id) {
        setSelectedUser({
          label: `#${order.user.id} ${order.user.nickname}`,
          value: order.user.id,
          key: order.user.id,
        });
        form.setFieldValue('user', {
          label: `#${order.user.id} ${order.user.nickname}`,
          value: order.user.id,
          key: order.user.id,
        });
      }
      setIsActive(order.isActive);
      setIsAllowDuplicates(order.isAllowDuplicates);
      setIsUseFakeCs(order.isUseFakeCs);
      setUseManualApprovalDeposit(order.useManualApprovalDeposit);
      setPaymentType(order?.paymentType);
      setStatus(order?.status);
      if (order?.customer?.brand?.id) {
        setSelectedBrand({
          label: `#${order.customer.brand.id} ${order.customer.brand.name}`,
          value: order.customer.brand.id,
          key: order.customer.brand.id,
        });
        form.setFieldValue('brand', {
          value: order.customer.brand.id,
          label: `#${order.customer.brand.id} ${order.customer.brand.name}`,
        });
      }
      setSelectedCustomer({
        label: `#${order.customer.id} ${order.customer.name} ${order.customer?.brand?.name}`,
        value: order.customer.id,
        key: order.customer.id,
      });
      setSelectedIntegration({
        label: order.integration
          ? `#${order.integration?.id} ${order.integration?.name}`
          : null,
        value: order.integration?.id,
        key: order.integration?.id,
      });
      setFunnels(JSON.parse(order.funnels))
      setLanguages(JSON.parse(order.languages))
      form.setFieldValue('funnels', JSON.parse(order.funnels));
      form.setFieldValue('languages', JSON.parse(order.languages));
      setCountries(JSON.parse(order.countries));
      setCallStatusGroup({
        label: `#${order.callStatusGroup.id} ${order.callStatusGroup.name}`,
        value: order.callStatusGroup.id,
        key: order.callStatusGroup.id,
      });
      setSelectedCountryBox({
        value: order.countryBox?.id,
        label: order.countryBox
          ? `#${order.countryBox?.id} ${order.countryBox?.name}`
          : null,
      });
      setDays(JSON.parse(order.callCenterSchedule).days);
      setTimezone(JSON.parse(order.callCenterSchedule).timezone);
    } else if (user) {
      setSelectedUser({
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
      form.setFieldValue('status','active')
      form.setFieldValue('user', {
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
    }
  }, [order, user]);

  const onAllowDuplicatesChange = (e) => {
    setIsAllowDuplicates(e.target.checked);
  };

  const onUseFakeCsChange = (e) => {
    setIsUseFakeCs(e.target.checked);
  };

  const onUseManualApprovalDepositChange = (e) => {
    setUseManualApprovalDeposit(e.target.checked);
  };

  const onCountryChange = (value) => {
    setCountries(value);
    form.setFieldValue('countries', value);
  };

  const onCLanguageChange = (value) => {
    setLanguages(value);
    form.setFieldValue('languages', value);
  };

  const getLabel = (c) => {
    let label = `#${c.id}`;
    if (c.name) {
      label = `${label} ${c.name}`;
    }
    return label;
  };

  const fetchBrands = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/brand/label?filter.name=$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: getLabel(c),
      value: c.id,
    }));
  };

  const initialFetchBrands = async () => {
    const res = await api.get(`/brand/label?limit10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: getLabel(c),
      value: c.id,
    }));
  };

  const fetchCustomers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const brandFilter = selectedBrand?.value
      ? `&filter.brand.id=$eq:${selectedBrand.value}`
      : '';
    const res = await api.get(
      `/customer/label?filter.name=$ilike:${name}&filter.isActive=$eq:true${idFilter}${brandFilter}`
    );
    return res.data.data.map((c) => ({
      label: `${getLabel(c)} ${c?.brand ? c?.brand?.name : ''}`,
      value: c.id,
    }));
  };

  const initialFetchCustomers = async () => {
    const brandFilter = selectedBrand?.value
      ? `&filter.brand.id=$eq:${selectedBrand.value}`
      : '';
    const res = await api.get(
      `/customer/label?limit10&filter.isActive=$eq:true${brandFilter}`
    );
    return res.data.data.map((c) => ({
      label: `${getLabel(c)} ${c?.brand?.name ? c?.brand?.name : ''}`,
      value: c.id,
    }));
  };

  const fetchIntegrations = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/integration/label?filter.name=$ilike:${name}&filter.customer.id=$in:${selectedCustomer.value}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: getLabel(c),
      value: c.id,
    }));
  };

  const initialFetchIntegrations = async () => {
    const url = selectedCustomer?.value
      ? `/integration/label?limit=10&filter.customer.id=$in:${selectedCustomer.value}&filter.isActive=$eq:true`
      : `/integration/label?limit=10`;
    const res = await api.get(url);
    return res.data.data.map((c) => ({
      label: getLabel(c),
      value: c.id,
    }));
  };

  const fetchCallStatusGroup = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/call-status-group?filter.name=$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: getLabel(c),
      value: c.id,
    }));
  };

  const initialFetchCallStatusGroup = async () => {
    const res = await api.get(
      `/call-status-group?limit=10&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: getLabel(c),
      value: c.id,
    }));
  };

  const fetchCountryBox = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/country-box?filter.isActive=$eq:true&filter.name=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: getLabel(c),
      value: c.id,
    }));
  };

  const initialFetchCountryBox = async () => {
    const res = await api.get(`/country-box?filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: getLabel(c),
      value: c.id,
    }));
  };

  const fetchUsers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?filter.nickname=$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const initialFetchUsers = async () => {
    const res = await api.get(`/user?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  if (!user) return 'Loading';

  return (
    <>
      <div className="flex justify-between">
        {hasPermissions(user, PERMISSIONS.ORDERS_EDIT) && (
          <DeleteButton entity={order} onFinish={onFinish} />
        )}
        <div className="flex justify-between">
          {order && <LogsInEntity drawerId={order.id} entity='Order'/>}
          {order && <ShareButton shares={shared} entity={{ order_id: order.id }} refetch={refetchShared} isSharingLeads />}
        </div>
      </div>
      <Form
        form={form}
        onFinish={() => {
          if (
            days.find((d) => d.isActive && d.from !== 0 && !d.to) &&
            timezone?.length
          ) {
            setScheduleError(true);
            return;
          }
          dispatch(ordersApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            isActive: order ? isActive : true,
            isAllowDuplicates: order ? isAllowDuplicates : true,
            isUseFakeCs: order ? isUseFakeCs : false,
            useManualApprovalDeposit: order ? useManualApprovalDeposit : true,
            // cap: +form.getFieldValue('cap'),
            dailyCap: +form.getFieldValue('dailyCap'),
            countries: JSON.stringify(countries),
            customerId: selectedCustomer?.value,
            countryBoxId: selectedCountryBox?.value || null,
            integrationId: selectedIntegration?.value || null,
            callStatusGroupId: callStatusGroup?.value,
            funnels: JSON.stringify(funnels),
            languages: JSON.stringify(languages),
            createdAt: undefined,
            updatedAt: undefined,
            user: undefined,
            user_id: selectedUser?.value,
            status: status,
            callCenterSchedule: JSON.stringify({
              timezone,
              days: days.map((d) => {
                if (d.isActive) {
                  return {
                    label: d.label,
                    from: d.from,
                    to: d.to,
                    isActive: d.isActive,
                  };
                }
                return {
                  label: d.label,
                  isActive: false,
                };
              }),
            }),
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <FormFieldRestricter allowedFields={user.permissions.columns.orders}>
          <Form.Item
            name="name"
            label={f({id: 'title'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
          </Form.Item>
          <Form.Item name="brand" label={f({id: 'brand'})}>
            <AutocompleteSelect
              allowClear
              className={styles.input}
              value={selectedBrand}
              placeholder={f({id: 'brand'})}
              initialFetch={initialFetchBrands}
              fetch={fetchBrands}
              onChange={setSelectedBrand}
            />
            {selectedBrand?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.BRANDS_EDIT} isShow={!!selectedBrand?.value}
                                       onClick={() => {
                                         setIsOpenDrawer(true)
                                         setDataDrawer({id: selectedBrand?.value, type: 'brands'})
                                       }}/>}
          </Form.Item>
          <Form.Item
            name="customer"
            label={f({id: 'customer'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
              <AutocompleteSelect
                className={styles.input}
                value={selectedCustomer}
                placeholder={f({id: 'customer'})}
                initialFetch={initialFetchCustomers}
                fetch={fetchCustomers}
                onChange={setSelectedCustomer}
              />
            {selectedCustomer?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.CUSTOMERS_EDIT} isShow={!!selectedCustomer?.value}
                                       onClick={() => {
                                         setIsOpenDrawer(true)
                                         setDataDrawer({id: selectedCustomer?.value, type: 'customers'})
                                       }}
            />}
          </Form.Item>
          <Form.Item name="integration" label={f({id: 'integration'})}>
            <AutocompleteSelect
              allowClear
              disabled={
                (order?.isSuccessOrder && !user.isAdmin && !user.isCanChangeIntegration) || !selectedCustomer
              }
              className={styles.input}
              value={selectedIntegration}
              placeholder={f({id: 'integration'})}
              initialFetch={initialFetchIntegrations}
              fetch={fetchIntegrations}
              onChange={setSelectedIntegration}
            />
            {selectedIntegration?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.INTEGRATIONS_EDIT} isShow={!!selectedIntegration?.value}
                                       onClick={() => {
                                         setIsOpenDrawer(true)
                                         setDataDrawer({id: selectedIntegration?.value, type: 'integrations'})
                                       }}/>}
          </Form.Item>
          <Form.Item
            name="callStatusGroup"
            label={f({id: 'call_status_group'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutocompleteSelect
              className={styles.input}
              value={callStatusGroup}
              placeholder={f({id: 'call_status_group'})}
              initialFetch={initialFetchCallStatusGroup}
              fetch={fetchCallStatusGroup}
              onChange={setCallStatusGroup}
            />
            {callStatusGroup?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.CALL_STATUSES_GROUP_EDIT}
                                       isShow={!!callStatusGroup?.value} onClick={() => {
              setIsOpenDrawer(true)
              setDataDrawer({id: callStatusGroup?.value, type: 'call-status-groups'})
            }}/>}
          </Form.Item>
          <Form.Item name="countryBox" label="Country Box">
            <AutocompleteSelect
              className={styles.input}
              value={selectedCountryBox}
              placeholder="Country Box"
              initialFetch={initialFetchCountryBox}
              fetch={fetchCountryBox}
              onChange={setSelectedCountryBox}
              disabled={countries.length}
              allowClear
            />
            {selectedCountryBox?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.COUNTRY_BOXES_EDIT} isShow={!!selectedCountryBox?.value}
                                       onClick={() => {
                                         setIsOpenDrawer(true)
                                         setDataDrawer({id: selectedCountryBox?.value, type: 'country-box'})
                                       }}/>}
          </Form.Item>
          <Form.Item name="countries" label={f({id: 'country_one'})}>
            <CountrySelect
              mode="multiple"
              onChange={onCountryChange}
              className={styles.input}
              disabled={selectedCountryBox?.value}
            />
          </Form.Item>
          <Form.Item name="languages" label={f({id: 'language'})}>
            <LanguageSelect
                mode="multiple"
                onChange={onCLanguageChange}
                className={styles.input}
            />
          </Form.Item>
          <Form.Item
              name="funnels"
              label={f({id: 'funnels'})}
          >
            <Select
                mode="tags"
                placeholder={f({id: 'funnels'})}
                className={styles.input}
                tokenSeparators={['\n', '\r\n', '\r']}
                onChange={setFunnels}
            >
            </Select>
          </Form.Item>
          <Form.Item
            name="paymentType"
            label={f({id: 'payment_type'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={[
                { value: 'CPA', label: 'CPA' },
                { value: 'CPL', label: 'CPL' },
                { value: 'CRG', label: 'CRG' },
                { value: 'revshara', label: 'Revshara'},
                { value: 'base', label: 'Base'},
                { value: 'base_percent', label: 'Base + %'},
              ]}
              onChange={(v) => setPaymentType(v)}
              className={styles.input}
            />
          </Form.Item>
          {paymentType === 'CRG' && (
            <Form.Item
              name="crPercent"
              label={f({id: 'cr_planned'})}
              rules={[
                {
                  max: 100,
                  min: 0,
                  required: true,
                },
              ]}
            >
              <Input
                placeholder="0.0"
                type="number"
                min="0"
                step="0.01"
                addonAfter="%"
                rootClassName={styles.input}
              />
            </Form.Item>
          )}
          {(paymentType === 'revshara' || paymentType === 'base_percent') && (
              <Form.Item
                  name="priceRevshara"
                  label="Price FTD"
                  rules={[
                    {
                      min: 0,
                      required: true,
                    },
                  ]}
              >
                <Input
                    placeholder="0"
                    type="number"
                    min="0"
                    step="1"
                    rootClassName={styles.input}
                />
              </Form.Item>
          )}
          <Form.Item name="comment" label={f({id: 'comment'})}>
            <TextArea
              rows={4}
              placeholder={f({id: 'comment'})}
              className={styles.input}
              style={{ resize: 'none' }}
            />
          </Form.Item>
          <Tooltip
            name="dailyCap"
            title={f({id: 'tooltip_in_box_orders'})}
          >
            <Form.Item name="dailyCap" label={f({id: 'daily_cap'})}>
              <Input
                placeholder={f({id: 'daily_cap'})}
                type="number"
                rootClassName={styles.input}
              />
            </Form.Item>
          </Tooltip>
          {order && (
            <Form.Item name="leadsPaid" label={f({id: 'leads_paid'})}>
              <Input
                disabled={!user.isPaymentAllowed}
                placeholder={f({id: 'leads_paid'})}
                rootClassName={styles.input}
              />
            </Form.Item>
          )}
          {hasPermissions(user, PERMISSIONS.USERS_READ) && (
            <Form.Item
              name="user"
              label={f({ id: 'responsible'})}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <AutocompleteSelect
                className={styles.input}
                value={selectedUser}
                placeholder={f({ id: 'user' })}
                initialFetch={initialFetchUsers}
                fetch={fetchUsers}
                onChange={setSelectedUser}
              />
              {selectedUser?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.USERS_EDIT} isShow={!!selectedUser?.value}
                                         onClick={() => {
                                           setIsOpenDrawer(true)
                                           setDataDrawer({id: selectedUser?.value, type: 'users'})
                                         }}/>}
            </Form.Item>
          )}
          {order && (
            <Form.Item name="createdAt" label={f({id: 'created'})}>
              <Input
                disabled
                placeholder={f({id: 'created'})}
                rootClassName={styles.input}
              />
            </Form.Item>
          )}
          {order && (
            <Form.Item name="updatedAt" label={f({id: 'updated'})}>
              <Input
                disabled
                placeholder={f({id: 'updated'})}
                rootClassName={styles.input}
              />
            </Form.Item>
          )}
          <Form.Item
              name="status"
              label={f({id: 'status'})}
              rules={[
                {
                  required: true,
                },
              ]}
          >
            <Select

                options={[
                  { value: 'active', label: <Tag color="green">{f({id: 'at_work'})}</Tag>},
                  { value: 'pause', label: <Tag color="yellow">{f({id: 'on_pause'})}</Tag>}
                ]}
                onChange={(v) => setStatus(v)}
                className={styles.input}
            />
          </Form.Item>
          <Form.Item name="isAllowDuplicate" label={f({id: 'allow_duplicates'})}>
            <Checkbox
              onClick={onAllowDuplicatesChange}
              defaultChecked={order ? order.isAllowDuplicates : true}
              rootClassName={styles.input}
            />
          </Form.Item>
          <Form.Item name="isUseFakeCs" label={f({id: 'delay_call_status'})}>
            <Checkbox
                onClick={onUseFakeCsChange}
                defaultChecked={order ? order.isUseFakeCs : false}
                rootClassName={styles.input}
            />
          </Form.Item>
          {user.isAdmin && (
            <Form.Item
              name="useManualApprovalDeposit"
              label={f({id: 'manual_deposit_confirmation'})}
            >
              <Checkbox
                onClick={onUseManualApprovalDepositChange}
                defaultChecked={order ? order.useManualApprovalDeposit : true}
                rootClassName={styles.input}
              />
            </Form.Item>
          )}

          <Divider />

          <div name="callCenterSchedule" className={styles.schedule}>
            <div className={styles.timezone}>
              <Select
                placeholder={f({id: 'timezone'})}
                style={{ width: 200 }}
                showSearch
                defaultValue={timezone}
                onChange={setTimezone}
                options={timezonesList.map((t) => ({
                  value: t.timezone,
                  label: t.name,
                  key: t.timezone,
                }))}
              />
            </div>
            <div className={styles.scheduleContainer}>
              <div className={styles.scheduleDays}>
                {days.map((day) => {
                  return (
                    <ScheduleItem
                      order={order}
                      day={day}
                      f={f}
                      onPeriodChange={(e) => {
                        setDays(
                          days.map((d) => {
                            if (d.label === day.label) {
                              if (e?.from && e?.to) {
                                return {
                                  ...e,
                                  label: d.label,
                                };
                              } else {
                                return {
                                  ...d,
                                  to: { h: e[1].$H, m: e[1].$m },
                                  from: { h: e[0].$H, m: e[0].$m },
                                };
                              }
                            }
                            return d;
                          })
                        );
                      }}
                      onActive={(checked, day, date) => {
                        setDays(
                          days.map((d) => {
                            if (d.label === day.label) {
                              return {
                                ...d,
                                to: { h: date[1].$H, m: date[1].$m },
                                from: { h: date[0].$H, m: date[0].$m },
                                isActive: checked,
                              };
                            }
                            return d;
                          })
                        );
                      }}
                      onCopyDays={(e) => {
                        setDaysCopy(e);
                      }}
                      isActive={day.isActive}
                      copyDays={daysCopy}
                      days={days}
                    />
                  );
                })}
              </div>
            </div>
            {scheduleError && (
              <div className={styles.scheduleErrorMsg}>
                {f({id: 'please_specify_time'})}
              </div>
            )}
          </div>
        </FormFieldRestricter>

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isDisabled}
            disabled={isDisabled}
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
    </>
  );
};

const ScheduleItem = ({
  day,
  onPeriodChange,
  onActive,
  isActive,
  disabled,
  onCopyDays,
  copyDays,
  f
}) => {
  return (
    <Row key={day.label} className={styles.scheduleItem}>
      <Col offset={8} span={1}>
        {f({id: day.label})}
      </Col>
      <Col offset={1}>
        <TimePicker.RangePicker
          changeOnBlur
          value={[
            day.from
              ? dayjs()
                  .minute(day?.from?.m ?? 0)
                  .hour(day?.from?.h ?? 0)
              : dayjs().hour(0).minute(0),
            day.to
              ? dayjs()
                  .minute(day?.to?.m ?? 0)
                  .hour(day?.to?.h ?? 0)
              : dayjs().hour(23).minute(59),
          ]}
          format={'HH:mm'}
          allowClear={false}
          disabled={disabled || !isActive}
          className={styles.timePicker}
          onChange={onPeriodChange}
        />
      </Col>
      <Col>
        <Checkbox
          disabled={disabled}
          defaultChecked={isActive}
          onChange={(e) =>
            onActive(e.target.checked, day, [
              day.from
                ? dayjs()
                    .minute(day?.from?.m ?? 0)
                    .hour(day?.from?.h ?? 0)
                : dayjs().hour(0).minute(0),
              day.to
                ? dayjs()
                    .minute(day?.to?.m ?? 0)
                    .hour(day?.to?.h ?? 0)
                : dayjs().hour(23).minute(59),
            ])
          }
        />
      </Col>
      {isActive && !copyDays && (
        <Col style={{ marginLeft: '20px' }}>
          <Button
            type="primary"
            onClick={() => {
              onCopyDays(day);
            }}
            shape="circle"
            icon={<CopyOutlined />}
          />
        </Col>
      )}
      {copyDays && isActive && copyDays?.label === day?.label ? (
        <Col style={{ marginLeft: '20px' }}>
          <Button
            type="primary"
            onClick={() => {
              onCopyDays(null);
            }}
            shape="circle"
            icon={<CloseOutlined />}
          />
        </Col>
      ) : copyDays && isActive ? (
        <Col style={{ marginLeft: '20px' }}>
          <Button
            type="primary"
            onClick={() => {
              onPeriodChange(copyDays);
            }}
            shape="circle"
            icon={<PushpinOutlined />}
          />
        </Col>
      ) : (
        ''
      )}
    </Row>
  );
};
