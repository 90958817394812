import React, { useEffect, useState } from 'react';
import {
  App,
  Button,
  Form,
  Input,
  Modal,
  Progress,
  Table,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import api from '../../../api/api';
import styles from '../BoxForm/BoxForm.module.css';
import {
    DeleteOutlined,
    EditOutlined,
    PauseOutlined,
    PlayCircleOutlined,
    PlusOutlined,
    QuestionCircleOutlined, ReloadOutlined,
} from '@ant-design/icons';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import { EditableCellBox } from '../../../shared/EditableCell/EditableCellBox';

import {useIntl} from "react-intl";
import {PermissionalLinkDrawer} from "../../../shared/PermissionalLink/PermissionalLinkDrawer";
import {PERMISSIONS} from "../../../utils/permissions";
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {PermissionalButtonDrawer, PermissionalLink} from "../../../shared/PermissionalLink/PermissionalLink";

export const BoxOrders = ({ boxId, isBoxes, updateOrderBox, createOrderBox, deleteOrderBox, fetchBoxOrders }) => {
  const { formatMessage: f } = useIntl();
  const { message } = App.useApp();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [value, setValue] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState({ update: false, id: null });
  const [form] = Form.useForm();
  const [boxOrders, setBoxOrders] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});

  const getBoxOrders = () => {
    setIsLoading(true);
    fetchBoxOrders(boxId)
      .then((res) => {
        setBoxOrders(res.data);
        setSelectedOrders(res.data.map((ob) => ob.order));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
    const onDrawerOpen = (id, type) => {
        setIsOpenDrawer(true)
        setDataDrawer({id: id, type: type})
    }
  useEffect(() => {
    getBoxOrders();
  }, []);

  const handleUpdate = (id, obj) => {
    setIsLoading(true);
    setIsUpdate({ update: false, id: null });
    updateOrderBox({ id, ...obj })
      .then(() => {
        message.success(f({ id: 'order_updated'}));
        getBoxOrders();
      })
      .finally(() => {
        setIsLoading(false);
          setValue({})
      });
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    deleteOrderBox({ id })
      .then(() => {
        message.success(f({ id: 'order_removed_from_box' }));
        getBoxOrders();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePause = (id, pause) => {
    setIsLoading(true);
    updateOrderBox({ id, ...pause })
      .then(() => {
        message.success(
          pause.isPause ? f({ id: 'order_is_not_active' }) : f({ id: 'order_is_active' })
        );
        getBoxOrders();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: f({ id: 'title_order' }),
      dataIndex: 'order',
      key: 'order',
      render: (order) => (
        <PermissionalLink
            label={`#${order?.id} ${order?.name ? order.name : ''} ${order.isActive && order.status === 'active' ? '' : `(${f({ id: 'inactive' })})`}`}
            isDrawer={true}
            onClick={() =>{
                setIsOpenDrawer(true)
                setDataDrawer({id: order?.id, type: 'orders'})
            }}
            permissions={PERMISSIONS.ORDERS_EDIT}>
        </PermissionalLink>
      ),
    },
    {
      title: f({ id: 'priority' }),
      dataIndex: 'priority',
      key: 'priority',
      render: (priority, fullObj) => {
        return (
          <EditableCellBox
            value={priority}
            boxId={fullObj.id}
            isUpdate={isUpdate}
            onChange={(e) => {
              setValue({
                ...value,
                priority: +e,
              });
            }}
            name="priority"
            onUpdate={() => {
              handleUpdate(fullObj.id, value);
            }}
          />
        );
      },
    },
    {
      title: f({ id: 'weight' }),
      dataIndex: 'weight',
      key: 'weight',
      render: (weight, fullObj) => {
        return (
          <EditableCellBox
            value={weight}
            boxId={fullObj.id}
            isUpdate={isUpdate}
            onChange={(e) => {
              setValue({
                ...value,
                weight: +e,
              });
            }}
            name="weight"
            onUpdate={() => {
              handleUpdate(fullObj.id, value);
            }}
          />
        );
      },
    },
    {
      title: f({ id: 'buy_price' }),
      dataIndex: 'buyPrice',
      key: 'buyPrice',
      render: (buyPrice, fullObj) => {
        return (
          <EditableCellBox
            value={buyPrice}
            boxId={fullObj.id}
            isUpdate={isUpdate}
            onChange={(e) => {
              setValue({
                ...value,
                buyPrice: +e,
              });
            }}
            name="buyPrice"
            onUpdate={() => {
              handleUpdate(fullObj.id, value);
            }}
            isDecimal
          />
        );
      },
    },
    {
      title: f({ id: 'sell_price' }),
      dataIndex: 'sellPrice',
      key: 'sellPrice',
      render: (sellPrice, fullObj) => {
        return (
          <EditableCellBox
            value={sellPrice}
            boxId={fullObj.id}
            isUpdate={isUpdate}
            onChange={(e) => {
              setValue({
                ...value,
                sellPrice: +e,
              });
            }}
            name="sellPrice"
            onUpdate={() => {
              handleUpdate(fullObj.id, value);
            }}
            isDecimal
          />
        );
      },
    },
    {
      title: f({ id: 'daily_cap_box' }),
      dataIndex: 'dailyCap',
      align: 'center',
      key: 'dailyCap',
      render: (dailyCap, bo) => {
        let percent = 0;
        let leadsForFormat = 0;
        const cc = JSON.parse(bo.order?.callCenterSchedule);
        if (cc?.timezone && dailyCap && dailyCap > 0) {
          leadsForFormat = bo['boxCaps'];
          percent = (100 * leadsForFormat) / dailyCap;
        }
        return (dailyCap && dailyCap > 0) || isUpdate.update ? (
          <div
            style={{
              width: 130,
              margin: '0px auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Progress
              percent={percent}
              size="small"
              format={() => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: '15px' }}>{leadsForFormat}/</span>
                  <span style={{ fontSize: '15px' }}>
                    <EditableCellBox
                      isUpdate={isUpdate}
                      value={dailyCap}
                      boxId={bo.id}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          dailyCap: +e,
                        });
                      }}
                      name="dailyCap"
                      onUpdate={() => {
                        handleUpdate(bo.id, value);
                      }}
                    />
                  </span>
                </div>
              )}
            />
          </div>
        ) : (
          ''
        );
      },
    },
    {
      title: (
        <Tooltip
          title={
            f({ id: 'daily_cap_order_details' })
          }
        >
          <span>
            {f({ id: 'daily_order_cap' })} <QuestionCircleOutlined />
          </span>
        </Tooltip>
      ),
      dataIndex: 'dailyCap',
      align: 'center',
      key: 'dailyCap',
      render: (dailyCap, bo) => {
        let percent = 0;
        let leadsForFormat = 0;
        const cc = JSON.parse(bo.order?.callCenterSchedule);
        if (cc?.timezone && bo.order?.dailyCap && bo.order?.dailyCap > 0) {
          leadsForFormat = bo.order?.dailyCapOrder;
          percent = (100 * leadsForFormat) / bo.order?.dailyCap;
        }
        return bo.order?.dailyCap && bo.order?.dailyCap > 0 ? (
          <div
            style={{
              width: 130,
              margin: '0px auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Progress
              percent={percent}
              size="small"
              format={() => (
                <div>
                  {leadsForFormat}/{bo.order?.dailyCap}
                </div>
              )}
            />
          </div>
        ) : (
          ''
        );
      },
    },
    {
      render: (_, obj) => (
        <DeleteOutlined
          style={{
            color: 'red',
            fontSize: 20,
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={() => handleDelete(obj.id)}
        />
      ),
    },
    {
      render: (_, obj) => {
        if (!obj.isPause) {
          return (
            <PauseOutlined
              style={{
                color: 'orange',
                fontSize: 20,
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => handlePause(obj.id, { isPause: !obj.isPause })}
            />
          );
        } else {
          return (
            <PlayCircleOutlined
              style={{
                color: 'green',
                fontSize: 20,
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => handlePause(obj.id, { isPause: !obj.isPause })}
            />
          );
        }
      },
    },
    {
      render: (_, obj) => (
        <EditOutlined
          style={{
            color: 'blue',
            fontSize: 20,
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={() => setIsUpdate({ update: !isUpdate.update, id: obj.id })}
        />
      ),
    },
  ];

  const fetchOrders = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/order/label?filter.name=$or:$ilike:${name}${idFilter}${
        selectedOrders.length
          ? `&filter.id=$not:$in:${selectedOrders
              .map((o) => o.id.toString())
              .join(',')}`
          : ''
      }&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name ? c.name : ''}`,
      value: c.id,
    }));
  };

  const initialFetchOrders = async (name) => {
    const res = await api.get(
      `/order/label?limit=10${
        selectedOrders.length
          ? `&filter.id=$not:$in:${selectedOrders
              .map((o) => o.id.toString())
              .join(',')}`
          : ''
      }&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name ? c.name : ''}`,
      value: c.id,
    }));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onItemAdd = () => {
    setIsDisabled(true);
    createOrderBox({
      orderId: form.getFieldValue('order').value,
      boxId: +boxId,
      priority: +form.getFieldValue('priority'),
      weight: +form.getFieldValue('weight'),
      dailyCap: +form.getFieldValue('dailyCap'),
      sellPrice: +form.getFieldValue('sellPrice'),
      buyPrice: +form.getFieldValue('buyPrice'),
    })
      .then(() => {
        message.success(f({ id: 'box_added' }));
        setIsModalOpen(false);
        form.resetFields();
        getBoxOrders();
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  return (
    <div>
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
      {!isBoxes && (
        <div className={styles.ordersTableButtonContainer}>
          <Button
            disabled={!boxId}
            className={styles.addButton}
            type="primary"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <PlusOutlined />
          </Button>
        </div>
      )}
        <Button
        className={styles.addButton}
        type="primary"
        onClick={() => {
            getBoxOrders();
        }}>
            <ReloadOutlined />
        </Button>
      <Table
        loading={isLoading}
        dataSource={boxOrders}
        columns={columns}
        pagination={
            !boxOrders || {
                pageSize: 20
            }
        }
      />
      <Modal
        title={ f({ id: 'add_order_to_box' }) }
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 7, offset: 1 }}
          form={form}
          onFinish={onItemAdd}
        >
          <Form.Item
            name="order"
            label={f({ id: 'order'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutocompleteSelect
                style={{width: '84%'}}
              className={styles.input}
              value={selectedOrders}
              placeholder={f({ id: 'order'})}
              initialFetch={initialFetchOrders}
              fetch={fetchOrders}
              onChange={setSelectedOrders}
            />
              {selectedOrders?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.ORDERS_EDIT} isShow={!!selectedOrders?.value}
                                         onClick={() => {
                                             setIsOpenDrawer(true)
                                             setDataDrawer({id: selectedOrders?.value, type: 'orders'})
                                         }}/>}
          </Form.Item>
          <Form.Item
            name="priority"
            label={f({ id: 'priority' })}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="buyPrice"
            label={f({ id: 'buy_price' })}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              min="0"
              step="0.01"
              type="number"
            />
          </Form.Item>
          <Form.Item
            name="sellPrice"
            label={f({ id: 'sell_price' })}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              min="1"
              step="0.01"
              type="number"
            />
          </Form.Item>
          <Form.Item
            name="weight"
            label={f({ id: 'weight' })}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Tooltip title={f({ id: 'tooltip_in_box_orders' })}>
            <Form.Item name="dailyCap" label={f({ id: 'daily_cap' })}>
              <Input
                placeholder={f({ id: 'daily_cap' })}
                type="number"
                rootClassName={styles.input}
              />
            </Form.Item>
          </Tooltip>
          <Form.Item wrapperCol={{ offset: 11 }}>
            <Button
              disabled={isDisabled}
              loading={isDisabled}
              type="primary"
              htmlType="submit"
            >
                {f({ id: 'save' })}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
