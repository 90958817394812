import { Button, Form, Input } from 'antd';
import styles from './CloudflareForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import {
  hasPermissions,
} from '../../../hooks/usePermissionsAllowed';
import { cloudflareApi } from '../cloudflareApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const CloudflareForm = ({ cf, isDisabled, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(cf ? cf.isActive : true);
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();

  const { user } = useSelector((state) => state.globalSlice);
  useEffect(() => {
    if (!cf) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (cf) {
      form.setFieldsValue(cf);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(cf.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(cf.updatedAt, user.timezone)
      );
      setIsActive(cf.isActive);
    }
  }, [cf]);

  return (
    <>
      {hasPermissions(user, PERMISSIONS.DOMAINS_EDIT) && (
        <DeleteButton entity={cf} onFinish={onFinish} />
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(cloudflareApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            name: form.getFieldValue('name')?.trim(),
            login: form.getFieldValue('login')?.trim(),
            password: form.getFieldValue('password')?.trim(),
            apiKey: form.getFieldValue('apiKey')?.trim(),
            apiEmail: form.getFieldValue('apiEmail')?.trim(),
            idAccount: form.getFieldValue('idAccount')?.trim(),
            userId: cf ? cf?.userId : user?.id,
            createdAt: undefined,
            updatedAt: undefined,
            isActive,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="login"
          label={f({id: 'login'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'login'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="password"
          label={f({id: 'password'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'password'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="apiKey"
          label="API Key"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="API Key" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="apiEmail"
          label="API Email"
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input placeholder="API Email" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="idAccount"
          label="Account ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Account ID" rootClassName={styles.input} />
        </Form.Item>

        {cf && (
          <Form.Item name="createdAt" label={f({id: 'created'})}>
            <Input
              disabled
              placeholder={f({id: 'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {cf && (
          <Form.Item name="updatedAt" label={f({id: 'updated'})}>
            <Input
              disabled
              placeholder={f({id: 'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button disabled={isDisabled} type="primary" htmlType="submit">
            { f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
