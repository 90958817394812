import { Button, Form, message, Modal } from 'antd';
import React, { useState } from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../AutocompleteSelect/AutocompleteSelect';
import {FormattedMessage, useIntl} from "react-intl";

export const ChangeDomainsServerModal = ({
                                           rows,
                                           isModalOpen,
                                           handleCancel,
                                           onSuccess,
                                           changeServer,
                                           dispatch,
                                       }) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedServers, setSelectedServers] = useState();
    const { formatMessage: f } = useIntl();
    const onServersUpdate = () => {
        setIsLoading(true);
            changeServer(
                {
                    ids: rows.map((d) => d.id),
                    newServerId:selectedServers.value
                }
            )
                .then(() => {
                    message.success(f({ id: 'changed_dns' }));
                    onSuccess();
                })
                .catch((e) => message.error(e.response.data?.message))
                .finally(() => setIsLoading(false))
    };
    const fetchServers = async (ip) => {
        const idFilter =
            !isNaN(ip) && ip?.length
                ? `&filter.id=$or:$eq:${ip}`
                : '';
        const res = await api.get(
            `domain-purchase/server?filter.ip=$ilike:${ip}${idFilter}`
        );
        return res.data.data.map((c) => ({
            label: `#${c.id} || ${c.ip} ${c.isActive ? '' : `(${f({ id: 'inactive' })})`}`,
            value: c.id,
            active: c.isActive,
        }));
    };

    const initialFetchServers = async () => {
        const res = await api.get(`domain-purchase/server?limit=10&filter.isActive=$eq:true`);
        return res.data.data.map((c) => ({
            label: `#${c.id} || ${c.ip} ${c.isActive ? '' : `(${f({ id: 'inactive' })})`}`,
            value: c.id,
            active: c.isActive,
        }));
    };
    return (
        <Modal
            title={f({ id: 'changeDns' })}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
        >
            <Form
                wrapperCol={{ span: 12, offset: 1 }}
                labelCol={{ span: 7, offset: 1 }}
                form={form}
                onFinish={onServersUpdate}
            >
                <Form.Item
                    name="server"
                    label={f({ id: 'server' })}
                    style={{ marginTop: 15 }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <AutocompleteSelect
                        value={selectedServers}
                        placeholder={f({ id: 'server' })}
                        initialFetch={initialFetchServers}
                        fetch={fetchServers}
                        onChange={setSelectedServers}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 11 }}>
                    <Button
                        loading={isLoading}
                        disabled={isLoading}
                        type="primary"
                        htmlType="submit"
                    >
                        {f({ id: 'change' })}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
