import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from '../../api/prepareHeaders';
import { inputToFilter, sorterValue } from '../../utils/inputToFilter';

export const brandsApi = createApi({
  reducerPath: 'brandApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.REACT_APP_API_HOST}:${window.REACT_APP_API_PORT}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getBrands: builder.query({
      query: ({ filters, currentPage, pageSize, sorter }) => {
        const sort = sorterValue(sorter);
        const url = inputToFilter(filters, currentPage, pageSize, true);
        return `brand?${url}${sort}`;
      },
    }),
    getBrand: builder.query({
      query: (id) => `brand/${id}`,
    }),
    updateBrand: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `brand/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
    getSharedByBrand: builder.query({
      query: (id) => `/sharing/entity/${id}?entity=brand_id`,
    }),
    shareBrandBatch: builder.mutation({
      query: (patch) => ({
        url: `/sharing/batch?entity=brand_id`,
        method: 'PUT',
        body: patch,
      }),
    }),
    updateResponsible: builder.mutation({
      query: (patch) => ({
        url: `brand/responsible`,
        method: 'PUT',
        body: patch,
      }),
    }),
  }),
  tagTypes: ['brand'],
});

export const {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useUpdateBrandMutation,
  useUpdateResponsibleMutation,
  useLazyGetBrandsQuery,
  useLazyGetSharedByBrandQuery,
  useShareBrandBatchMutation
} = brandsApi;
