import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import api from '../../../api/api';
import styles from './CallStatusCategories.module.css';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { OneColumn } from '../OneColumn/OneColumn';
import { ScreenBlock } from '../../../shared/Modals/ScreenBlock';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';
import { useLazyGetCallStatusCategoriesQuery } from '../callStatusCategoriesApi';
import {useIntl} from "react-intl";

export const CallStatusCategories = () => {
  usePermissionsAllowed(PERMISSIONS.CALL_STATUSES_READ);
  const { formatMessage: f } = useIntl();
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  const [trigger] = useLazyGetCallStatusCategoriesQuery();

  const [isScreenBlocked, setIsScreenBlocked] = useState(false);
  const onClickLazyQuery = () => {
    setIsFetching(true);
    trigger({})
      .then((e) => {
        setData(e.data || []);
        setIsFetching(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    onClickLazyQuery();
  }, []);
  useEffect(() => {
    const categories = {};
    data.forEach((category) => {
      let childrenCallStatuses = [];
      category.childrenCategories.forEach((childrenCategory) => {
        childrenCategory.callStatuses.forEach((s) => {
          childrenCallStatuses.push({
            ...s,
            id: s.id.toString(),
          });
        });
      });
      categories[category.id] = {
        name: category.name === 'Нерозподілені' ? f({id: 'not_distributed'}) : category.name ,
        items: childrenCallStatuses.length
          ? category.callStatuses
              .map((s) => ({
                ...s,
                id: s.id.toString(),
              }))
              .concat(childrenCallStatuses)
          : category.callStatuses.map((s) => ({
              ...s,
              id: s.id.toString(),
            })),
        color: category.color,
        isChildrenCategoriesExist: !!category.childrenCategories.length,
        isDefault: category.isDefault,
      };
    });
    setColumns(categories);
  }, [data]);

  const onDelete = (id) => {
    api.delete(`/call-status-category/${id}`).then(() => {
      onClickLazyQuery();
    });
  };

  const updateStatus = (id, callSatusCategoryId) => {
    return new Promise((resolve, reject) => {
      api
        .put(`/call-status/${id}`, {
          call_status_category_id: callSatusCategoryId,
        })
        .then((_) => {
          message.success(f({ id: 'call_status_updated'}));
          resolve();
        })
        .catch((e) => {
          message.error(e.response.data.message);
          reject();
        });
    });
  };

  const onDragEnd = async (result, columns, setColumns) => {
    setIsScreenBlocked(true);
    try {
      if (!result.destination) return;
      const { source, destination } = result;
      if (source.droppableId !== destination.droppableId) {
        try {
          await updateStatus(
            result.draggableId,
            result.destination.droppableId
          );
          onClickLazyQuery();
          const sourceColumn = columns[source.droppableId];
          const destColumn = columns[destination.droppableId];
          const sourceItems = [...sourceColumn.items];
          const destItems = [...destColumn.items];
          const [removed] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, removed);
          setColumns({
            ...columns,
            [source.droppableId]: {
              ...sourceColumn,
              items: sourceItems,
            },
            [destination.droppableId]: {
              ...destColumn,
              items: destItems,
            },
          });
        } catch (e) {}
      } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        });
      }
    } finally {
      setIsScreenBlocked(false);
    }
  };
  return (
    <div>
      <Button
        className={styles.addBtn}
        type="primary"
        onClick={() => navigate('/call-status-categories/create')}
      >
        <PlusOutlined />
      </Button>
      <div className={styles.content}>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.keys(columns).length && (
            <span className={styles.oneColumnWraper}>
              <OneColumn columnId={1} column={columns['1']} />
            </span>
          )}
          <div className={styles.scrollContent}>
            {Object.entries(columns).map(
              ([columnId, column], index) =>
                columnId != 1 && (
                  <OneColumn
                    columnId={columnId}
                    column={column}
                    onDelete={onDelete}
                  />
                )
            )}
          </div>
        </DragDropContext>
      </div>
      <ScreenBlock isOpen={isScreenBlocked} />
    </div>
  );
};
