import React from 'react';
import { Select } from 'antd';

export const PaymentTypeInput = ({ defaultValue, onChange }) => {
  return (
    <Select
      options={[
        { value: 'CPA', label: 'CPA' },
        { value: 'CPL', label: 'CPL' },
      ]}
      onChange={(e) => onChange(e)}
      defaultValue={{ value: defaultValue, label: defaultValue }}
    />
  );
};
