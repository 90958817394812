import { Checkbox, Form } from 'antd';
import styles from '../Table/AppTable.module.css';
import { updateFilters } from '../../store/sharedSlices/defaultPageSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFilterContext } from '../../hooks/useFilterContext';

export const DeletedFilters = ({
  label,
  name,
  type,
  updateTypeFilters = updateFilters,
}) => {
  const dispatch = useDispatch();
  const [querystr, setSearchParams] = useFilterContext();
  return (
    <>
      <Form.Item key={name} label={label} className={styles.filter} name={name}>
        <Checkbox
          onChange={(e) => {
            dispatch(
              updateTypeFilters({
                name: type,
                value: `$eq:${!e.target.checked}`,
              })
            );
            setSearchParams({
              filters: JSON.stringify({
                ...querystr,
                [type]: [e.target.checked, type, `$eq:${!e.target.checked}`],
              }),
            });
          }}
          checked={querystr?.[type] ? querystr?.[type][0] : false}
          defaultChecked={
            querystr?.hasOwnProperty(type) ? querystr?.[type][0] : false
          }
        />
      </Form.Item>
    </>
  );
};
