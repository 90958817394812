import { useSearchParams } from 'react-router-dom';

export const useFilterContext = () => {
  const [searchParams] = useSearchParams();
  let url = searchParams.get('filters');
  const querystr = JSON.parse(url) ? JSON.parse(url) : null;

  const [, setSearchParams] = useSearchParams();
  return [querystr, setSearchParams];
};
