import {Modal, Input, Form, Button, App, Select, DatePicker} from 'antd';
import React, {useState} from 'react';
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useUpdateCallStatusLeadsMutation} from "../../leadsApi";
import styles from "../../LeadDetails/LeadDetails.module.css";
import dayjs from "dayjs";

const { TextArea } = Input;

export const ChangeCallStatusAndDepositModal = ({ leads, isModalOpen, handleCancel, onSuccess }) => {
  const { formatMessage: f } = useIntl();
    const [form] = Form.useForm();
  const {user} = useSelector((state) => state.globalSlice);
  const [isLoading, setIsLoading] = useState(false);
  const {message} = App.useApp();
  const [deposit, setDeposit] = useState(false);
  const [depositDate, setDepositDate] = useState(Date.now());
  const [callStatus, setCallStatus] = useState(null);
  const [updateCallStatusLeads] = useUpdateCallStatusLeadsMutation();

  const onUpdate = () => {
    setIsLoading(true);
    updateCallStatusLeads(
            leads.map((l) => ({
                  id: l.id,
                  isDeposit: form.getFieldValue('isDeposit'),
                  depositDate: form.getFieldValue('depositDate'),
                  callStatus: form.getFieldValue('callStatus'),
                }))
        )
            .then((e) => {
              if (e?.error) {
                message.error(e.error?.data?.message);
              } else {
                message.success(f({id: 'lead_updated'}));
              }
            })
            .finally(() => {
              setIsLoading(false);
              form.resetFields()
              setDeposit(false)
              onSuccess();
            })
  }
  return (
      <Modal
          title={f({id: 'details'})}
          open={isModalOpen}
          footer={null}
          onCancel={() => {
              form.resetFields()
              setDeposit(false)
              handleCancel()
          }}
      >
        <Form
            wrapperCol={{span: 12, offset: 1}}
            labelCol={{span: 7, offset: 1}}
            form={form}
            onFinish={onUpdate}
        >
            <Form.Item
                name="callStatus"
                label={f({id: 'call_status'})}
            >
                <Input
                    style={{width: 250}}
                    className={styles.formItem}
                />
            </Form.Item>
            <Form.Item
                name="isDeposit"
                label={f({id: 'deposit'})}
            >
                <Select
                    onChange={(value) => {
                        if (!value) setDeposit(null)
                        setDeposit(value)
                    }}
                    options={[
                        {value: false, label: 'Немає'},
                        {value: true, label: 'Наявний',}
                    ]}
                    style={{minWidth: '250px'}}
                    className={styles.formItem}
                />
            </Form.Item>
            {deposit && (
                    <Form.Item name="depositDate"
                               label={f({id: 'deposit_date'})}
                               rules={[
                                   {
                                       required: true,
                                   },
                               ]}>
                        <DatePicker
                            value={depositDate}
                            showTime
                            className={styles.formItem}
                            onChange={date => {
                                setDepositDate(date)
                            }}
                        />
                    </Form.Item>)}
          <Form.Item wrapperCol={{offset: 11}}>
            <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                htmlType="submit"
            >
              {f({id: 'save'})}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
  );
};
