import {
  useDeleteSpecificSessionMutation,
  useGetAllSessionsQuery,
  useGetSessionQuery,
} from '../sessionsApi';
import { faUserAgent } from 'fontawesome-user-agent';
import { Button, List, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { DeleteOutlined } from '@ant-design/icons';
import { useHandleLogout } from '../../../../hooks/useHandleLogout';
import { useIntl } from "react-intl";
import React from "react";

export const SessionSection = () => {
  const { data, refetch } = useGetAllSessionsQuery();
  const [deleteSpecificSession] = useDeleteSpecificSessionMutation();
  const [handleLogout] = useHandleLogout();
  const token = localStorage.getItem('access_token');
  const { formatMessage: f } = useIntl();

  const res = data
    ? data.map((d) => {
        const regex = /class="([^"]+)"/;
        const matchOs = faUserAgent(d.value).os.html.match(regex);
        const matchPlat = faUserAgent(d.value).platform.html.match(regex);
        const matchBrowser = faUserAgent(d.value).browser.html.match(regex);

        let classValueOs = matchOs[1] || '';
        let classValuePlatform = matchPlat[1] || '';
        let classValueBrowser = matchBrowser[1] || '';

        return {
          title: d.value,
          classOs: classValueOs,
          classPlatform: classValuePlatform,
          classBrowser: classValueBrowser,
          token: d.key.split(':')[5],
          useAs: d.nickname || '',
        };
      })
    : [];

  return (
    <List
      itemLayout="horizontal"
      dataSource={res}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            title={
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={item?.classOs?.split(' ')}
                    size="3x"
                    className="mr-2"
                  />
                  <FontAwesomeIcon
                    icon={item?.classPlatform?.split(' ')}
                    size="3x"
                    className="mr-2"
                  />
                  <FontAwesomeIcon
                    icon={item?.classBrowser?.split(' ')}
                    size="3x"
                    className="mr-2"
                  />
                  <div>
                    <span>{item.title}</span>
                    { item.useAs && (
                      <div>[{f({ id: 'session_using_by' })} {item.useAs}]</div>
                    )}
                  </div>                  
                </div>
                <div className="w-8 ml-1">
                  <Popconfirm
                    title={f({ id: 'delete_sessions' })}
                    onConfirm={() =>
                      deleteSpecificSession(item.token).then(() => {
                        if (token == item.token) {
                          handleLogout();
                        }
                        refetch();
                      })
                    }
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      type="primary"
                      danger
                      disabled={item.token == token}
                    />
                  </Popconfirm>
                </div>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};
