import { BoxOrders } from '../../Boxes/BoxOrders/BoxOrders';
import React, { useState } from 'react';
import {
  buyersApi,
  useCreateOrderBuyerMutation,
  useDeleteOrderBuyerMutation,
  useLazyGetBuyerOrderQuery,
  useUpdateOrderBuyerMutation
} from '../buyersApi';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, Divider, Form } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styles from '../../Boxes/BoxForm/BoxForm.module.css';

export const OrdersTab = ({ buyer, onFinish, isDisabled, drawerId }) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();

  const [updateOrderBuyer] = useUpdateOrderBuyerMutation();
  const [createOrderBuyer] = useCreateOrderBuyerMutation();
  const [deleteOrderBuyer] = useDeleteOrderBuyerMutation();
  const [triggerBuyerOrder] = useLazyGetBuyerOrderQuery();
  const { id } = useParams();
  const [ reqId ] = useState(drawerId ? drawerId : id)
  const [form] = Form.useForm();
  const [isRedirectDuplicates, setIsRedirectAllowedDuplicate] = useState(
    buyer ? buyer.isRedirectDuplicates : true
  );
  const [isRedirectError, setIsRedirectAllowedError] = useState(
    buyer ? buyer.isRedirectError : true
  );
  const [isAllowDuplicates, setIsAllowDuplicates] = useState(
    buyer ? buyer.isAllowDuplicates : true
  );
  const [isHoldStatus, setIsHoldStatus] = useState(
      buyer ? buyer.isHoldStatus : false
  );


  return <div>
    <Form
      form={form}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      onFinish={() => {
        dispatch(buyersApi.util.resetApiState());
        onFinish({
          isAllowDuplicates,
          isRedirectDuplicates,
          isRedirectError,
          isHoldStatus,
         }, true)
      }}
    >
      <Form.Item name="isAllowDuplicate" label={f({ id: 'allow_duplicates' })}>
        <Checkbox
          onClick={(e) => setIsAllowDuplicates(e.target.checked)}
          defaultChecked={buyer ? buyer.isAllowDuplicates : true}
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item
        name="isRedirectDuplicates"
        label={f({ id: 'redirect_leads_in_duplication' })}
      >
        <Checkbox
          onClick={(e) => setIsRedirectAllowedDuplicate(e.target.checked)}
          defaultChecked={buyer ? buyer.isRedirectDuplicates : true}
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item
        name="isRedirectError"
        label={f({ id: 'redirect_leads_in_error' })}
      >
        <Checkbox
          onClick={(e) => setIsRedirectAllowedError(e.target.checked)}
          defaultChecked={buyer ? buyer.isRedirectError : true}
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item
          name="isHoldStatus"
          label={f({id: "use_hold_status"})}
      >
        <Checkbox
            onClick={(e) => setIsHoldStatus(e.target.checked)}
            defaultChecked={buyer ? buyer.isHoldStatus : false}
            rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item className="flex justify-center">
        <Button
          type="primary"
          htmlType="submit"
          loading={isDisabled}
          disabled={isDisabled}
        >
          {f({ id: 'save' })}
        </Button>
      </Form.Item>
    </Form>
    <Divider orientation="center">{f({ id: 'orders' })}</Divider>
    <BoxOrders
      boxId={reqId}
      updateOrderBox={updateOrderBuyer}
      createOrderBox={createOrderBuyer}
      deleteOrderBox={deleteOrderBuyer}
      fetchBoxOrders={triggerBuyerOrder}
    />
  </div>
}