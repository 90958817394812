import { App, Button, FloatButton, Form, Input, message, Tooltip } from 'antd';
import styles from './CallStatusGroupForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import api from '../../../api/api';
import { CopyOutlined } from '@ant-design/icons';
import { CloneCallStatusGroupModal } from './CloneCallStatusGroupModal';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { ThemeContext } from '../../../providers/ThemeProvider';
import {
  callStatusGroupApi,
  useOnDeleteCallStatusMutation,
} from '../callStatusGroupsApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import { ScreenBlock } from '../../../shared/Modals/ScreenBlock';
import {useIntl} from "react-intl";

export const CallStatusGroupForm = ({
  isDisabled,
  callStatusGroup,
  onFinish,
  onRefresh,
  isDefault,
}) => {
  const [form] = Form.useForm();
  const { formatMessage: f } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useSelector((state) => state.globalSlice);

  const [isActive, setIsActive] = useState(
    callStatusGroup ? callStatusGroup.isActive : true
  );
  const dispatch = useDispatch();
  const [onDeleteStatus] = useOnDeleteCallStatusMutation();
  useEffect(() => {
    if (!callStatusGroup) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (callStatusGroup) {
      form.setFieldsValue(callStatusGroup);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(callStatusGroup.createdAt, user?.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(callStatusGroup.updatedAt, user?.timezone)
      );
      setIsActive(callStatusGroup.isActive);
    }
  }, [callStatusGroup]);


  const onDelete = async (id) => {
    await onDeleteStatus(id).then((e) => {
      if (e?.error) {
        message.error(e.error?.data?.message);
      } else {
        message.success(f({id: 'call_status_group_deleted'}));
        onRefresh();
      }
    });
  };
  return (
    <div>
      {callStatusGroup?.isDefault && (
          <Tooltip title={f({ id: 'copy_group_call_statuses'})}>
          <FloatButton
            shape="circle"
            type="primary"
            style={{ right: 94 }}
            icon={<CopyOutlined />}
            onClick={() => setIsModalOpen(true)}
          />
        </Tooltip>
      )}
      <CloneCallStatusGroupModal
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
      {hasPermissions(user, PERMISSIONS.CALL_STATUSES_EDIT) && (
        <DeleteButton entity={callStatusGroup} onFinish={onFinish} />
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(callStatusGroupApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            isActive,
            createdAt: undefined,
            updatedAt: undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id:'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id:'title'})} rootClassName={styles.input} />
        </Form.Item>

        {callStatusGroup && (
          <Form.Item name="createdAt" label={f({id:'created'})}>
            <Input
              disabled
              placeholder={f({id:'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {callStatusGroup && (
          <Form.Item name="updatedAt" label={f({id:'updated'})}>
            <Input
              disabled
              placeholder={f({id:'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isDisabled}
            disabled={isDisabled}
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>

      {callStatusGroup && (
        <StatusBoard
          onDelete={(id) => onDelete(id)}
          isDefault={isDefault}
          onRefresh={onRefresh}
          categories={callStatusGroup.callStatusGroupCategories}
          callStatusGroupId={callStatusGroup.id}
        />
      )}
    </div>
  );
};

const StatusBoard = ({
  categories,
  onRefresh,
  callStatusGroupId,
  isDefault,
}) => {
  const { message } = App.useApp();
  const { formatMessage: f } = useIntl();
  const { isDarkTheme } = useContext(ThemeContext);
  const [isScreenBlocked, setIsScreenBlocked] = useState(false);
  const [columns, setColumns] = useState({
    notDistributed: {
      name: f({id: 'not_distributed'}),
      items: categories
        .filter((c) => c.statusName === 'notDistributed')
        .map((c) => ({ ...c, id: c.callStatusCategory.id.toString() })),
      color: 'rgba(211, 211, 211, 0.5)',
    },
    pending: {
        name: f({id: 'not_confirmed'}),
      items: categories
        .filter((c) => c.statusName === 'pending')
        .map((c) => ({ ...c, id: c.callStatusCategory.id.toString() })),
      color: 'rgba(253, 253, 150, 0.5)',
    },
    approved: {
      name: f({id: 'confirmed'}),
      items: categories
        .filter((c) => c.statusName === 'approved')
        .map((c) => ({ ...c, id: c.callStatusCategory.id.toString() })),
      color: 'rgba(119, 221, 119, 0.5)',
    },
    rejected: {
      name: f({id: 'declined'}),
      items: categories
        .filter((c) => c.statusName === 'rejected')
        .map((c) => ({ ...c, id: c.callStatusCategory.id.toString() })),
      color: 'rgba(255, 105, 97, 0.5)',
    },
  });

  useEffect(() => {
    setColumns({
      notDistributed: {
        name: f({id: 'not_distributed'}),
        items: categories
          .filter((c) => c.statusName === 'notDistributed')
          .map((c) => ({ ...c, id: c.callStatusCategory.id.toString() })),
        color: 'rgba(211, 211, 211, 0.5)',
        isDefault: true,
      },
      pending: {
        name: f({id: 'not_confirmed'}),
        items: categories
          .filter((c) => c.statusName === 'pending')
          .map((c) => ({ ...c, id: c.callStatusCategory.id.toString() })),
        color: 'rgba(253, 253, 150, 0.5)',
      },
      approved: {
        name: f({id: 'confirmed'}),
        items: categories
          .filter((c) => c.statusName === 'approved')
          .map((c) => ({ ...c, id: c.callStatusCategory.id.toString() })),
        color: 'rgba(119, 221, 119, 0.5)',
      },
      rejected: {
        name: f({id: 'declined'}),
        items: categories
          .filter((c) => c.statusName === 'rejected')
          .map((c) => ({ ...c, id: c.callStatusCategory.id.toString() })),
        color: 'rgba(255, 105, 97, 0.5)',
      },
    });
  }, [categories]);

  const updateStatus = (callStatusGroupId, id, newStatus) => {
    setIsScreenBlocked(true);
    return new Promise((resolve, reject) => {
      api
        .put(`/call-status-group-category/${id}`, {
          statusName: newStatus,
          groupId: callStatusGroupId,
        })
        .then((_) => {
          message.success(f({id: 'updated_lead_by_status'}));
          resolve();
        })
        .catch((e) => {
          message.error(e.response.data.message);
          reject();
        });
    }).finally(() => {
      setIsScreenBlocked(false);
    });
  };

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      try {
        await updateStatus(
          callStatusGroupId,
          result.draggableId,
          result.destination.droppableId
        );
        onRefresh();
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        });
      } catch (e) {}
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              key={columnId}
            >
              <h2 style={{ color: isDarkTheme ? 'white' : 'black' }}>
                {column.name}
              </h2>
              <div style={{ margin: 8 }}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? 'lightblue'
                            : column.color,
                          padding: 4,
                          width: 250,
                          minHeight: 500,
                          border: column.isDefault ? 'dashed #b9b9b9' : '0',
                          borderRadius: 20,
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: 'none',
                                      padding: 10,
                                      margin: 15,
                                      minHeight: '40px',
                                      background: snapshot.isDragging
                                        ? 'rgba(26, 26, 26, 0.71)'
                                        : item.isModified || isDefault
                                        ? 'rgba(26, 26, 26, 0.51)'
                                        : 'rgba(26, 26, 26, 0.31)',
                                      color: 'white',
                                      ...provided.draggableProps.style,
                                      opacity: 1,
                                      borderRadius: 20,
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {item.callStatusCategory.parentCategory
                                      ? `${item.callStatusCategory.parentCategory.name} - ${item.callStatusCategory.name}`
                                      : item.callStatusCategory
                                          .childrenCategories?.length
                                      ? `${item.callStatusCategory.name} (${f({id:'without_subcategory'})})`
                                      : item.callStatusCategory.name}
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
      <ScreenBlock isOpen={isScreenBlocked} />
    </div>
  );
};
