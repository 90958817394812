import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageSize:
    Number(JSON.parse(localStorage.getItem('pageSize'))?.integration) || 10,
};

export const templateIntegrationsSlice = createSlice({
  name: 'template_integrations',
  initialState,
  reducers: {
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
      const storageItemsCount = JSON.parse(localStorage.getItem('pageSize'));
      localStorage.setItem(
        'pageSize',
        JSON.stringify({ ...storageItemsCount, integration: action.payload })
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageSize } = templateIntegrationsSlice.actions;

export default templateIntegrationsSlice.reducer;
