import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useIntl } from "react-intl";

const DeleteButton = ({ entity, onFinish, disabled = false, textDisabled }) => {
  const { formatMessage: f } = useIntl();
  const onAction = (stan) => {
    onFinish({
      isActive: stan,
    });
  };
  return (
    <>
      {entity?.isActive === true && (
          <div>
              <Popconfirm
                  okText="ОК"
                  cancelText={f({id: 'cancel'})}
                  title={`${f({id: 'delete_button'})} ?`}
                  onConfirm={() => onAction(false)}
              >
                <Tooltip placement="bottom" title={disabled ? textDisabled : f({id: 'delete_button'})}>
                  <Button disabled={disabled} style={{minWidth: '140px'}} type="primary" danger>
                    {f({id: 'delete_button'})}
                    <DeleteOutlined className="text-base"/>
                  </Button>
                </Tooltip>
              </Popconfirm>
          </div>
      )}

      {entity?.isActive === false && (
        <div>
          <Tooltip placement="bottom" title={f({ id: 'restore' })}>
            <Popconfirm
              okText="ОК"
              cancelText={f({ id: 'restore' })}
              title={`${f({ id: 'restore' })} ?`}
              onConfirm={() => onAction(true)}
            >
              <Button type="primary" success>
                {f({ id: 'restore' })}
                <PlayCircleOutlined className="text-base" />
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
      )}
    </>
  );
};
export default DeleteButton;
