import { Select } from 'antd';
import CountryList from 'country-list-with-dial-code-and-flag';
import {FormattedMessage} from "react-intl";

const { Option } = Select;

export const CountrySelect = ({ values, onChange, mode, onlyCountry, ...props }) => {
  let countries = CountryList.getAll()

  if(onlyCountry?.length){
    countries = countries.filter(country => onlyCountry.includes(country.code));
  }

  return (
    <Select
      mode={mode}
      placeholder={<FormattedMessage id="country" />}
      onChange={onChange}
      optionLabelProp="label"
      defaultValue={values}
      showSearch
      filterOption={(inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.value.toLowerCase().includes(inputValue.toLowerCase())
      }
      {...props}
    >
      {countries.map((c, index) => (
        <Option key={index} value={c.code} label={c.name}>
          <div>
            <span role="img" aria-label={c.name}>
              {c.flag}&nbsp;
            </span>
            {c.name}&nbsp;({c.code.trim()})
          </div>
        </Option>
      ))}
    </Select>
  );
};
