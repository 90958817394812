import { Button, Form, Input } from 'antd';
import styles from './CountryBoxForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { CountrySelect } from '../../../shared/CountryMultiselect/CountrySelect';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { useDispatch, useSelector } from 'react-redux';
import { countryBoxApi } from '../countryBoxApi';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import {LogsInEntity} from "../../../shared/LogsInEntity/LogsInEntity";

export const CountryBoxForm = ({ countryBox, isDisabled, onFinish }) => {
  const { formatMessage: f } = useIntl();
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(
    countryBox ? countryBox.isActive : true
  );
  const [countries, setCountries] = useState();
  const { user } = useSelector((state) => state.globalSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!countryBox) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (countryBox) {
      form.setFieldsValue(countryBox);
      form.setFieldValue('countries', JSON.parse(countryBox.countries));
      form.setFieldValue(
        'createdAt',
        fromApiToClient(countryBox.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(countryBox.updatedAt, user.timezone)
      );
      setIsActive(countryBox.isActive);
    }
  }, [countryBox]);

  const onCountryChange = (value) => {
    setCountries(value);
    form.setFieldValue('countries', value);
  };

  return (
    <>
      {hasPermissions(user, PERMISSIONS.COUNTRY_BOXES_EDIT) && (
        <div className="flex justify-between">
          <DeleteButton entity={countryBox} onFinish={onFinish} />
          {countryBox && <LogsInEntity drawerId={countryBox.id} entity='CountryBox'/>}
        </div>
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(countryBoxApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            countries: JSON.stringify(countries),
            isActive,
            team: undefined,
            createdAt: undefined,
            updatedAt: undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="countries"
          label={f({id: 'country'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CountrySelect
            mode="multiple"
            onChange={onCountryChange}
            className={styles.input}
          />
        </Form.Item>

        {countryBox && (
          <Form.Item name="createdAt" label={f({id: 'created'})}>
            <Input
              disabled
              placeholder={f({id: 'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {countryBox && (
          <Form.Item name="updatedAt" label={f({id: 'updated'})}>
            <Input
              disabled
              placeholder={f({id: 'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isDisabled}
            disabled={isDisabled}
            type="primary"
            htmlType="submit"
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
