// store/slice.js
import { createSlice } from '@reduxjs/toolkit';
import { Form } from 'antd';

const initialState = {
  item: {},
};

const currentEditItemSlice = createSlice({
  name: 'currentEditItem',
  initialState,
  reducers: {
    setItem: (state, action) => {
      state.item = action.payload;
    },
    updateItem: (state, action) => {
      state.item[action.payload.key] = action.payload.value;
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const { setItem, updateItem } = currentEditItemSlice.actions;
export default currentEditItemSlice.reducer;
