export const roles = [
  'SUPER_ADMIN',
  'AFFILIATE',
  'SENIOR_AFFILIATE',
  'MEDIA_BUYER',
  'TEAM_LEAD',
  'LEAD_MANAGER',
  'SUPPORT_JUNIOR',
  'SUPPORT_SENIOR',
  'SUPPORT_ADMIN',
  'ANALYST',
  'CEO_ADMIN',
];
