import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageSize:
    Number(JSON.parse(localStorage.getItem('pageSize'))?.campaign) || 10,
};

export const campaignSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
      const storageItemsCount = JSON.parse(localStorage.getItem('pageSize'));
      localStorage.setItem(
        'pageSize',
        JSON.stringify({ ...storageItemsCount, campaign: action.payload })
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageSize } = campaignSlice.actions;

export default campaignSlice.reducer;
