import { theme } from 'antd';

export const themes = {
  1: {
    algorithm: theme.darkAlgorithm,
    token: {
      colorBgBase: '#323743',
      colorBgContainer: '#272b34',
      colorPrimary: '#21c1d6',
    },
    components: {
      Table: {
        colorBgContainer: '#272b34',
      },
    },
  },
  2: {
    algorithm: theme.defaultAlgorithm,
    token: {
      colorBgBase: '#fafafa',
      colorBgContainer: '#fafafa',
      colorPrimary: '#1677ff',
    },
    components: {
      Table: {
        colorBgContainer: '#fafafa',
      },
    },
  },
  3: {
    algorithm: theme.darkAlgorithm,
    token: {
      colorBgBase: '#2779a7',
      colorBgContainer: '#49c5b6',
      colorPrimary: '#49c5b6',
    },
    components: {
      Table: {
        colorBgContainer: '#2779a7',
      },
    },
  },
  4: {
    algorithm: theme.darkAlgorithm,
    token: {
      colorBgBase: '#5cdb94',
      colorBgContainer: '#05396b',
      colorPrimary: '#05396b',
    },
    components: {
      Table: {
        colorBgContainer: '#389583',
      },
    },
  },
  5: {
    algorithm: theme.darkAlgorithm,
    token: {
      colorBgBase: '#181818',
      colorBgContainer: '#a33327',
      colorPrimary: '#a33327',
    },
    components: {
      Table: {
        colorBgContainer: '#917164',
      },
    },
  },
  6: {
    algorithm: theme.darkAlgorithm,
    token: {
      colorBgBase: '#000000',
      colorBgContainer: '#230090',
      colorPrimary: '#230090',
    },
    components: {
      Table: {
        colorBgContainer: '#1a0061',
      },
    },
  },
  7: {
    algorithm: theme.darkAlgorithm,
    token: {
      colorBgBase: '#000000',
      colorBgContainer: '#940741',
      colorPrimary: '#940741',
    },
    components: {
      Table: {
        colorBgContainer: '#6f2232',
      },
    },
  },
  8: {
    algorithm: theme.defaultAlgorithm,
    token: {
      colorBgBase: '#eed8cd',
      colorBgContainer: '#bbb2b5',
      colorPrimary: '#bbb2b5',
    },
    components: {
      Table: {
        colorBgContainer: '#ede2dc',
      },
    },
  },
  9: {
    algorithm: theme.defaultAlgorithm,
    token: {
      colorBgBase: '#eae8dc',
      colorBgContainer: '#e88073',
      colorPrimary: '#e88073',
    },
    components: {
      Table: {
        colorBgContainer: '#d8c3a4',
      },
    },
  },
  10: {
    algorithm: theme.darkAlgorithm,
    token: {
      colorBgBase: '#93bfbe',
      colorBgContainer: '#264e58',
      colorPrimary: '#264e58',
    },
    components: {
      Table: {
        colorBgContainer: '#93bfbe',
      },
    },
  },
};
