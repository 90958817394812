import React from 'react';
import { useSelector } from 'react-redux';
import { CountriesInput } from '../../../../shared/EditableCell/inputFields/CountriesInput';

export const CountriesOrderInput = ({ defaultValue, onChange }) => {
  const countryBox = useSelector(
    (state) => state.currentEditItem.item.countryBox
  );

  return (
    <CountriesInput
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={countryBox}
    />
  );
};
