import { Button, Form, Input } from 'antd';
import styles from './BlacklistsForm.module.css';
import React, { useEffect, useState } from 'react';
import { blacklistsApi } from '../blacklistsApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import {fromApiToClient} from "../../../utils/dateFilters";
import validator from 'validator';
import {useIntl} from "react-intl";

export const BlacklistsForm = ({ blacklist, isDisabled, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalSlice);
  const { formatMessage: f } = useIntl();
  useEffect(() => {
    if (blacklist && user) {
      form.setFieldsValue(blacklist);
        form.setFieldValue(
            'createdAt',
            fromApiToClient(blacklist.createdAt, user.timezone)
        );
        form.setFieldValue(
            'updatedAt',
            fromApiToClient(blacklist.updatedAt, user.timezone)
        );
      setIsActive(blacklist.isActive);
    }
  }, [blacklist]);
    const validateIP = (rule, value, callback) => {
        if (validator.isIP(value)) {
            callback();
        } else {
            callback(f({ id: 'incorrect_ip_address' }));
        }
    };
  return (
    <>
      <DeleteButton entity={blacklist} onFinish={onFinish} />
      <Form
        form={form}
        onFinish={() => {
          dispatch(blacklistsApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            isActive,
            createdAt: undefined,
            updatedAt: undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="ip"
          label="IP"
          rules={[
            {
              required: true,
              validator: validateIP,
            },
          ]}
        >
          <Input placeholder="IP" rootClassName={styles.input} />
        </Form.Item>
        {blacklist && (
          <Form.Item name="createdAt" label={f({ id: 'created' })}>
            <Input
              disabled
              placeholder={f({ id: 'created' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {blacklist && (
          <Form.Item name="updatedAt" label={f({ id: 'updated' })}>
            <Input
              disabled
              placeholder={f({ id: 'updated' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit" disabled={isDisabled}>
              {f({ id: 'save' })}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
