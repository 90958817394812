import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import api from '../../api/api';

export const Autologin = () => {
  const { id } = useParams();

  useEffect(() => {
    api.get(`/lead/autologin/${id}`).then((res) => {
      window.location.replace(res.data);
    });
  }, []);

  return <>Redirecting...</>;
};
