export const languages = [
  {
    name: 'Afar',
    code: 'aa',
  },
  {
    name: 'Abkhazian',
    code: 'ab',
  },
  {
    name: 'Avestan',
    code: 'ae',
  },
  {
    name: 'Afrikaans',
    code: 'af',
  },
  {
    name: 'Akan',
    code: 'ak',
  },
  {
    name: 'Amharic',
    code: 'am',
  },
  {
    name: 'Aragonese',
    code: 'an',
  },
  {
    name: 'Arabic',
    code: 'ar',
  },
  {
    name: 'Assamese',
    code: 'as',
  },
  {
    name: 'Avaric',
    code: 'av',
  },
  {
    name: 'Aymara',
    code: 'ay',
  },
  {
    name: 'Azerbaijani',
    code: 'az',
  },
  {
    name: 'Bashkir',
    code: 'ba',
  },
  {
    name: 'Belarusian',
    code: 'be',
  },
  {
    name: 'Bulgarian',
    code: 'bg',
  },
  {
    name: 'Bihari languages',
    code: 'bh',
  },
  {
    name: 'Bislama',
    code: 'bi',
  },
  {
    name: 'Bambara',
    code: 'bm',
  },
  {
    name: 'Bengali',
    code: 'bn',
  },
  {
    name: 'Tibetan',
    code: 'bo',
  },
  {
    name: 'Breton',
    code: 'br',
  },
  {
    name: 'Bosnian',
    code: 'bs',
  },
  {
    name: 'Catalan; Valencian',
    code: 'ca',
  },
  {
    name: 'Chechen',
    code: 'ce',
  },
  {
    name: 'Chamorro',
    code: 'ch',
  },
  {
    name: 'Corsican',
    code: 'co',
  },
  {
    name: 'Cree',
    code: 'cr',
  },
  {
    name: 'Czech',
    code: 'cs',
  },
  {
    name: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
    code: 'cu',
  },
  {
    name: 'Chuvash',
    code: 'cv',
  },
  {
    name: 'Welsh',
    code: 'cy',
  },
  {
    name: 'Danish',
    code: 'da',
  },
  {
    name: 'German',
    code: 'de',
  },
  {
    name: 'Divehi; Dhivehi; Maldivian',
    code: 'dv',
  },
  {
    name: 'Dzongkha',
    code: 'dz',
  },
  {
    name: 'Ewe',
    code: 'ee',
  },
  {
    name: 'Greek, Modern (1453-)',
    code: 'el',
  },
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Esperanto',
    code: 'eo',
  },
  {
    name: 'Spanish; Castilian',
    code: 'es',
  },
  {
    name: 'Estonian',
    code: 'et',
  },
  {
    name: 'Basque',
    code: 'eu',
  },
  {
    name: 'Persian',
    code: 'fa',
  },
  {
    name: 'Fulah',
    code: 'ff',
  },
  {
    name: 'Finnish',
    code: 'fi',
  },
  {
    name: 'Fijian',
    code: 'fj',
  },
  {
    name: 'Faroese',
    code: 'fo',
  },
  {
    name: 'French',
    code: 'fr',
  },
  {
    name: 'Western Frisian',
    code: 'fy',
  },
  {
    name: 'Irish',
    code: 'ga',
  },
  {
    name: 'Gaelic; Scottish Gaelic',
    code: 'gd',
  },
  {
    name: 'Galician',
    code: 'gl',
  },
  {
    name: 'Guarani',
    code: 'gn',
  },
  {
    name: 'Gujarati',
    code: 'gu',
  },
  {
    name: 'Manx',
    code: 'gv',
  },
  {
    name: 'Hausa',
    code: 'ha',
  },
  {
    name: 'Hebrew',
    code: 'he',
  },
  {
    name: 'Hindi',
    code: 'hi',
  },
  {
    name: 'Hiri Motu',
    code: 'ho',
  },
  {
    name: 'Croatian',
    code: 'hr',
  },
  {
    name: 'Haitian; Haitian Creole',
    code: 'ht',
  },
  {
    name: 'Hungarian',
    code: 'hu',
  },
  {
    name: 'Armenian',
    code: 'hy',
  },
  {
    name: 'Herero',
    code: 'hz',
  },
  {
    name: 'Interlingua (International Auxiliary Language Association)',
    code: 'ia',
  },
  {
    name: 'Indonesian',
    code: 'id',
  },
  {
    name: 'Interlingue; Occidental',
    code: 'ie',
  },
  {
    name: 'Igbo',
    code: 'ig',
  },
  {
    name: 'Sichuan Yi; Nuosu',
    code: 'ii',
  },
  {
    name: 'Inupiaq',
    code: 'ik',
  },
  {
    name: 'Ido',
    code: 'io',
  },
  {
    name: 'Icelandic',
    code: 'is',
  },
  {
    name: 'Italian',
    code: 'it',
  },
  {
    name: 'Inuktitut',
    code: 'iu',
  },
  {
    name: 'Japanese',
    code: 'ja',
  },
  {
    name: 'Javanese',
    code: 'jv',
  },
  {
    name: 'Georgian',
    code: 'ka',
  },
  {
    name: 'Kongo',
    code: 'kg',
  },
  {
    name: 'Kikuyu; Gikuyu',
    code: 'ki',
  },
  {
    name: 'Kuanyama; Kwanyama',
    code: 'kj',
  },
  {
    name: 'Kazakh',
    code: 'kk',
  },
  {
    name: 'Kalaallisut; Greenlandic',
    code: 'kl',
  },
  {
    name: 'Central Khmer',
    code: 'km',
  },
  {
    name: 'Kannada',
    code: 'kn',
  },
  {
    name: 'Korean',
    code: 'ko',
  },
  {
    name: 'Kanuri',
    code: 'kr',
  },
  {
    name: 'Kashmiri',
    code: 'ks',
  },
  {
    name: 'Kurdish',
    code: 'ku',
  },
  {
    name: 'Komi',
    code: 'kv',
  },
  {
    name: 'Cornish',
    code: 'kw',
  },
  {
    name: 'Kirghiz; Kyrgyz',
    code: 'ky',
  },
  {
    name: 'Latin',
    code: 'la',
  },
  {
    name: 'Luxembourgish; Letzeburgesch',
    code: 'lb',
  },
  {
    name: 'Ganda',
    code: 'lg',
  },
  {
    name: 'Limburgan; Limburger; Limburgish',
    code: 'li',
  },
  {
    name: 'Lingala',
    code: 'ln',
  },
  {
    name: 'Lao',
    code: 'lo',
  },
  {
    name: 'Lithuanian',
    code: 'lt',
  },
  {
    name: 'Luba-Katanga',
    code: 'lu',
  },
  {
    name: 'Latvian',
    code: 'lv',
  },
  {
    name: 'Malagasy',
    code: 'mg',
  },
  {
    name: 'Marshallese',
    code: 'mh',
  },
  {
    name: 'Maori',
    code: 'mi',
  },
  {
    name: 'Macedonian',
    code: 'mk',
  },
  {
    name: 'Malayalam',
    code: 'ml',
  },
  {
    name: 'Mongolian',
    code: 'mn',
  },
  {
    name: 'Marathi',
    code: 'mr',
  },
  {
    name: 'Malay',
    code: 'ms',
  },
  {
    name: 'Maltese',
    code: 'mt',
  },
  {
    name: 'Burmese',
    code: 'my',
  },
  {
    name: 'Nauru',
    code: 'na',
  },
  {
    name: 'Bokmål, Norwegian; Norwegian Bokmål',
    code: 'nb',
  },
  {
    name: 'Ndebele, North; North Ndebele',
    code: 'nd',
  },
  {
    name: 'Nepali',
    code: 'ne',
  },
  {
    name: 'Ndonga',
    code: 'ng',
  },
  {
    name: 'Dutch; Flemish',
    code: 'nl',
  },
  {
    name: 'Norwegian Nynorsk; Nynorsk, Norwegian',
    code: 'nn',
  },
  {
    name: 'Norwegian',
    code: 'no',
  },
  {
    name: 'Ndebele, South; South Ndebele',
    code: 'nr',
  },
  {
    name: 'Navajo; Navaho',
    code: 'nv',
  },
  {
    name: 'Chichewa; Chewa; Nyanja',
    code: 'ny',
  },
  {
    name: 'Occitan (post 1500)',
    code: 'oc',
  },
  {
    name: 'Ojibwa',
    code: 'oj',
  },
  {
    name: 'Oromo',
    code: 'om',
  },
  {
    name: 'Oriya',
    code: 'or',
  },
  {
    name: 'Ossetian; Ossetic',
    code: 'os',
  },
  {
    name: 'Panjabi; Punjabi',
    code: 'pa',
  },
  {
    name: 'Pali',
    code: 'pi',
  },
  {
    name: 'Polish',
    code: 'pl',
  },
  {
    name: 'Pushto; Pashto',
    code: 'ps',
  },
  {
    name: 'Portuguese',
    code: 'pt',
  },
  {
    name: 'Quechua',
    code: 'qu',
  },
  {
    name: 'Romansh',
    code: 'rm',
  },
  {
    name: 'Rundi',
    code: 'rn',
  },
  {
    name: 'Romanian; Moldavian; Moldovan',
    code: 'ro',
  },
  {
    name: 'Russian',
    code: 'ru',
  },
  {
    name: 'Kinyarwanda',
    code: 'rw',
  },
  {
    name: 'Sanskrit',
    code: 'sa',
  },
  {
    name: 'Sardinian',
    code: 'sc',
  },
  {
    name: 'Sindhi',
    code: 'sd',
  },
  {
    name: 'Northern Sami',
    code: 'se',
  },
  {
    name: 'Sango',
    code: 'sg',
  },
  {
    name: 'Sinhala; Sinhalese',
    code: 'si',
  },
  {
    name: 'Slovak',
    code: 'sk',
  },
  {
    name: 'Slovenian',
    code: 'sl',
  },
  {
    name: 'Samoan',
    code: 'sm',
  },
  {
    name: 'Shona',
    code: 'sn',
  },
  {
    name: 'Somali',
    code: 'so',
  },
  {
    name: 'Albanian',
    code: 'sq',
  },
  {
    name: 'Serbian',
    code: 'sr',
  },
  {
    name: 'Swati',
    code: 'ss',
  },
  {
    name: 'Sotho, Southern',
    code: 'st',
  },
  {
    name: 'Sundanese',
    code: 'su',
  },
  {
    name: 'Swedish',
    code: 'sv',
  },
  {
    name: 'Swahili',
    code: 'sw',
  },
  {
    name: 'Tamil',
    code: 'ta',
  },
  {
    name: 'Telugu',
    code: 'te',
  },
  {
    name: 'Tajik',
    code: 'tg',
  },
  {
    name: 'Thai',
    code: 'th',
  },
  {
    name: 'Tigrinya',
    code: 'ti',
  },
  {
    name: 'Turkmen',
    code: 'tk',
  },
  {
    name: 'Tagalog',
    code: 'tl',
  },
  {
    name: 'Tswana',
    code: 'tn',
  },
  {
    name: 'Tonga (Tonga Islands)',
    code: 'to',
  },
  {
    name: 'Turkish',
    code: 'tr',
  },
  {
    name: 'Tsonga',
    code: 'ts',
  },
  {
    name: 'Tatar',
    code: 'tt',
  },
  {
    name: 'Twi',
    code: 'tw',
  },
  {
    name: 'Tahitian',
    code: 'ty',
  },
  {
    name: 'Uighur; Uyghur',
    code: 'ug',
  },
  {
    name: 'Ukrainian',
    code: 'uk',
  },
  {
    name: 'Urdu',
    code: 'ur',
  },
  {
    name: 'Uzbek',
    code: 'uz',
  },
  {
    name: 'Venda',
    code: 've',
  },
  {
    name: 'Vietnamese',
    code: 'vi',
  },
  {
    name: 'Volapük',
    code: 'vo',
  },
  {
    name: 'Walloon',
    code: 'wa',
  },
  {
    name: 'Wolof',
    code: 'wo',
  },
  {
    name: 'Xhosa',
    code: 'xh',
  },
  {
    name: 'Yiddish',
    code: 'yi',
  },
  {
    name: 'Yoruba',
    code: 'yo',
  },
  {
    name: 'Zhuang; Chuang',
    code: 'za',
  },
  {
    name: 'Chinese',
    code: 'zh',
  },
  {
    name: 'Zulu',
    code: 'zu',
  },
];
