import {Button, Checkbox, Form, Input, Select, Tooltip} from 'antd';
import styles from './BoxForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import api from '../../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { boxesApi } from '../boxesApi';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import {PermissionalButtonDrawer} from "../../../shared/PermissionalLink/PermissionalLink";
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {LogsInEntity} from "../../../shared/LogsInEntity/LogsInEntity";

export const BoxForm = ({ box, isDisabled, onFinish }) => {
  const { formatMessage: f } = useIntl();
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState();
  const [isActive, setIsActive] = useState(box ? box.isActive : true);
  const [isRedirectDuplicates, setIsRedirectAllowedDuplicate] = useState(
    box ? box.isRedirectDuplicates : true
  );
  const [isRedirectError, setIsRedirectAllowedError] = useState(
    box ? box.isRedirectError : true
  );
  const [isAllowDuplicates, setIsAllowDuplicates] = useState(
    box ? box.isAllowDuplicates : true
  );
  const [isHoldStatus, setIsHoldStatus] = useState(
      box ? box.isHoldStatus : false
  );
  const dispatch = useDispatch();
  const [selectedBuyers, setSelectedBuyers] = useState();
  const [countrySourceType, setCountrySourceType] = useState(
      box ? box.countrySourceType : 'received'
  );
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});

  const { user } = useSelector((state) => state.globalSlice);
  useEffect(() => {
    if (!box) form.setFieldValue('isActive', true);
    if (!box) form.setFieldValue('countrySourceType', 'received');
  }, []);

  useEffect(() => {
    if (box && user) {
      form.setFieldsValue(box);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(box.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(box.updatedAt, user.timezone)
      );
      form.setFieldValue(
        'buyers',
        box?.buyers.map((u) => ({
          label: `#${u.id} ${u.nickname}`,
          value: u.id,
          key: u.id,
        }))
      );
      setIsActive(box.isActive);
      setIsRedirectAllowedDuplicate(box.isRedirectDuplicates);
      setIsRedirectAllowedError(box.isRedirectError);
      setCountrySourceType(box.countrySourceType)
      setIsHoldStatus(box.isHoldStatus)
      setSelectedBuyers(
        box?.buyers.map((u) => ({
          label: `#${u.id} ${u.nickname}`,
          value: u.id,
          key: u.id,
        }))
      );
      if (box?.user?.id) {
        setSelectedUser({
          label: `#${box.user.id} ${box.user.nickname}`,
          value: box.user.id,
          key: box.user.id,
        });
        form.setFieldValue('user', {
          label: `#${box.user.id} ${box.user.nickname}`,
          value: box.user.id,
          key: box.user.id,
        });
      }
    } else if (user) {
      setSelectedUser({
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
      form.setFieldValue('user', {
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
    }
  }, [box]);

  const fetchBuyers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/buyer?filter.nickname=$ilike:${name}&show=true&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const initialFetchBuyers= async () => {
    const res = await api.get(
      `/buyer?limit=10&show=true&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const fetchUsers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?filter.nickname=$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const initialFetchUsers = async () => {
    const res = await api.get(`/user?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const onAllowDuplicatesChange = (e) => {
    setIsAllowDuplicates(e.target.checked);
  };

  const onRedirectAllowedErrorChange = (e) => {
    setIsRedirectAllowedError(e.target.checked);
  };

  const onRedirectAllowedDuplicateChange = (e) => {
    setIsRedirectAllowedDuplicate(e.target.checked);
  };

  const onIsHoldStatus = (e) => {
    setIsHoldStatus(e.target.checked);
  };

  return (
    <>
      {hasPermissions(user, PERMISSIONS.BOXES_EDIT) && (
          <div className="flex justify-between">
          <DeleteButton entity={box} onFinish={onFinish} />
          {box && <LogsInEntity drawerId={box.id} entity='Box,BoxOrder'/>}
          </div>
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(dispatch(boxesApi.util.resetApiState()));
          onFinish({
            ...form.getFieldsValue(),
            createdBy: user?.id,
            isAllowDuplicates,
            isRedirectDuplicates,
            isRedirectError,
            isActive,
            countrySourceType,
            isHoldStatus,
            buyers: form.getFieldValue('buyers')?.map((u) => u.value) || [],
            createdAt: undefined,
            updatedAt: undefined,
            userId: selectedUser?.value,
          });
        }}
        labelCol={{ span: 11 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({ id: 'title' })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({ id: 'title' })} rootClassName={styles.input} />
        </Form.Item>

        <Form.Item
            name="countrySourceType"
            label={f({id: 'method_determining_country'})}
            rules={[
              {
                required: true,
              },
            ]}
        >
          <Select
              options={[
                { value: 'phone', label: f({id: 'phone'}) },
                { value: 'ip', label: 'Ip' },
                { value: 'received', label: f({id: 'received_country'}) },
              ]}
              onChange={(v) => setCountrySourceType(v)}
              defaultValue={countrySourceType}
              className={styles.input}
          />
        </Form.Item>
        <Tooltip name="buyers" title={f({ id: 'leave_to_remove_restrictions' })}>
          <Form.Item name="buyers" label={f({ id: 'executors' })}>
            <AutocompleteSelect
              className={styles.input}
              value={selectedBuyers}
              placeholder={f({ id: 'executors' })}
              mode="multiple"
              initialFetch={initialFetchBuyers}
              fetch={fetchBuyers}
              onChange={setSelectedBuyers}
            />
          </Form.Item>
        </Tooltip>
        {hasPermissions(user, PERMISSIONS.USERS_READ) && (
          <Form.Item
            name="user"
            label={f({ id: 'responsible'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutocompleteSelect
              className={styles.input}
              value={selectedUser}
              placeholder={f({ id: 'user' })}
              initialFetch={initialFetchUsers}
              fetch={fetchUsers}
              onChange={setSelectedUser}
            />
            {selectedUser?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.USERS_EDIT} isShow={!!selectedUser?.value}
                                       onClick={() => {
                                         setIsOpenDrawer(true)
                                         setDataDrawer({id: selectedUser?.value, type: 'users'})
                                       }}/>}
          </Form.Item>
        )}
        {box && (
          <Form.Item name="createdAt" label={f({ id: 'created' })}>
            <Input
              disabled
              placeholder={f({ id: 'created' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {box && (
          <Form.Item name="updatedAt" label={f({ id: 'updated' })}>
            <Input
              disabled
              placeholder={f({ id: 'updated' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}

        <Form.Item name="isAllowDuplicate" label={f({ id: 'allow_duplicates' })}>
          <Checkbox
            onClick={onAllowDuplicatesChange}
            defaultChecked={box ? box.isAllowDuplicates : true}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item
          name="isRedirectDuplicates"
          label={f({ id: 'redirect_leads_in_duplication' })}
        >
          <Checkbox
            onClick={onRedirectAllowedDuplicateChange}
            defaultChecked={box ? box.isRedirectDuplicates : false}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item
          name="isRedirectError"
          label={f({ id: 'redirect_leads_in_error' })}
        >
          <Checkbox
            onClick={onRedirectAllowedErrorChange}
            defaultChecked={box ? box.isRedirectError : false}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item
            name="isHoldStatus"
            label={f({id: "use_hold_status"})}
        >
          <Checkbox
              onClick={onIsHoldStatus}
              defaultChecked={box ? box.isHoldStatus : false}
              rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item className="flex justify-center">
          <Button
            type="primary"
            htmlType="submit"
            loading={isDisabled}
            disabled={isDisabled}
          >
            {f({ id: 'save' })}
          </Button>
        </Form.Item>
      </Form>
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
    </>
  );
};
