import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageSize:
    Number(JSON.parse(localStorage.getItem('pageSize'))?.callStatusGroup) || 10,
};

export const callStatusGroupsSlice = createSlice({
  name: 'callStatusGroups',
  initialState,
  reducers: {
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
      const storageItemsCount = JSON.parse(localStorage.getItem('pageSize'));
      localStorage.setItem(
        'pageSize',
        JSON.stringify({
          ...storageItemsCount,
          callStatusGroup: action.payload,
        })
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageSize } = callStatusGroupsSlice.actions;

export default callStatusGroupsSlice.reducer;
