import { Button, ColorPicker, Form, Input } from 'antd';
import styles from './CallStatusCategoryForm.module.css';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import api from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ThemeContext } from '../../../providers/ThemeProvider';
import {useIntl} from "react-intl";

export const CallStatusCategoryForm = ({
  callStatusCategory,
  onFinish,
  parentId,
}) => {
  const [form] = Form.useForm();
  const { formatMessage: f } = useIntl();
  const [colorHex, setColorHex] = useState('#e9e9e9');
  const [parentCategory, setParentCategory] = useState();
  const navigate = useNavigate();
  const { isDarkTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (callStatusCategory) {
      form.setFieldsValue(callStatusCategory);
      setColorHex(callStatusCategory.color);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(callStatusCategory.createdAt)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(callStatusCategory.updatedAt)
      );
      if (callStatusCategory.parentCategory)
        setParentCategory(callStatusCategory.parentCategory);
    }
  }, [callStatusCategory]);

  useEffect(() => {
    if (parentId)
      api.get(`/call-status-category/${parentId}`).then((res) => {
        setParentCategory(res.data);
      });
  }, [parentId]);

  const color = useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex.toHexString()),
    [colorHex]
  );

  return (
    <div>
      <div className={styles.categoryTitleBox}>
        {parentCategory && (
          <h1
            style={{ color: isDarkTheme ? 'white' : 'black' }}
            className={styles.categoryTitle}
          >
            {parentCategory.name}
          </h1>
        )}
        <Button
          className={styles.backArrow}
          type="primary"
          onClick={() =>
            parentCategory
              ? navigate(
                  `/call-status-categories/children/${parentCategory.id}`
                )
              : navigate('/call-status-categories')
          }
        >
          <ArrowLeftOutlined />
        </Button>
      </div>

      <Form
        form={form}
        onFinish={() => {
          onFinish({
            name: form.getFieldValue('name'),
            color: color || '#e9e9e9',
            createdAt: undefined,
            updatedAt: undefined,
            parentCategoryId: parentCategory?.id || undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({ id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({ id: 'title'})} rootClassName={styles.input} />
        </Form.Item>

        <Form.Item name="color" label={f({ id: 'color' })}>
          <ColorPicker
            format="hex"
            value={colorHex}
            defaultValue="#e9e9e9"
            onChange={setColorHex}
            onFormatChange={'hex'}
            size="small"
            showText
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
