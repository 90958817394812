import React from 'react';
import { CountrySelect } from '../../CountryMultiselect/CountrySelect';

export const CountryInput = ({ defaultValue, onChange, disabled }) => {
  return (
    <CountrySelect
      style={{
        width: '130px',
      }}
      onChange={(e) => onChange(e)}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
};
