import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { CallStatusCategoryForm } from '../CallStatusCategoryForm/CallStatusCategoryForm';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const CreateCallStatusCategory = () => {
  usePermissionsAllowed(PERMISSIONS.CALL_STATUSES_EDIT);
  const { formatMessage: f } = useIntl();
  const { message } = App.useApp();
  const { parentId } = useParams();
  const navigate = useNavigate();
  const onFinish = (callStatusCategory) => {
    api
      .post('/call-status-category', {
        ...callStatusCategory,
      })
      .then((res) => {
        message.success(f({ id: 'category_call_status_added'})); //
        parentId
          ? navigate(`/call-status-categories/children/${parentId}`)
          : navigate(`/call-status-categories`);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return <CallStatusCategoryForm onFinish={onFinish} parentId={parentId} />;
};
