import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import {useHandleLogout} from "../../hooks/useHandleLogout";

const ErrorPage = ({ error, code, description }) => {
    const [handleLogout] = useHandleLogout();
    const navigate = useNavigate()

    const handleLogoutMethod = () => {
        handleLogout()
    };

    const handleHome = () => {
        navigate('/')
    };

    return (
        <Result
            status={code}
            title={code}
            subTitle={description}
            extra={[
                <Button type="primary" onClick={handleHome} key="home">
                    Back Home
                </Button>,
                <Button type="default" onClick={handleLogoutMethod} key="logout">
                    Logout
                </Button>
            ]}/>
    );
};

export default ErrorPage;
