import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { RegwayForm } from '../RegwayForm/RegwayForm';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const EditRegway = ({ drawerId }) => {
  const { message } = App.useApp();
  const [regway, setRegway] = useState();
  const { id } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);
  const { formatMessage: f } = useIntl();
  usePermissionsAllowed(PERMISSIONS.DOMAINS_READ);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    api.get(`/domain-purchase/regway/${reqId}`).then((res) => {
      setRegway({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedRegway) => {
    setIsDisabled(true);
    delete updatedRegway.updatedAt;
    delete updatedRegway.createdAt;
    api
      .put(`/domain-purchase/regway/${reqId}`, {
        ...updatedRegway,
      })
      .then((_) => {
        if(!drawerId) {
          navigate(-1);
        }
        message.success(f({id: 'regway_added'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  return (
    <RegwayForm isDisabled={isDisabled} onFinish={onFinish} regway={regway} />
  );
};
